import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import React, { useState } from "react";
import CustomLabel from "../CustomLabel";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import { CLR_LIGHT_WHITE } from "../../utils/colors";
import CustomSaveButton from "../CustomSaveButton";
import CustomCancelButton from "../CustomCancelButton";
import axios from "axios";
import {
  deleteBookingData,
  getMonthRecords,
} from "../../services/bookingServices";
import { trackPromise } from "react-promise-tracker";
import ResultDiologueBox from "../ResultDiologueBox";
import { CancelRounded } from "@mui/icons-material";
import moment from "moment";

function DeleteTableDataDlg(props) {
  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [resultLabel, setResultLabel] = useState(null);
  const StyledPaper = styled(Paper)(() => ({
    "& .MuiPaper-root": {
      background: "#white!important",
      borderRadius: "20px",
      //   minWidth:"800px"
    },
  }));

  const handleClickDelete = () => {
    let deleteArr = [];
    props?.data?.map((item, index) => {
      deleteArr.push({
        booking_id: item?.booking_id,
        room_no: item?.room_no,
        room_type_id: item?.room_type_id,
        serial_number: item?.serial_number,
        room_line_id: item?.room_line_id,
      });
    });
    deleteBooking(deleteArr);
  };

  const deleteBooking = async (bookingLines) => {
    await trackPromise(
      deleteBookingData({
        bookings_lines: bookingLines,
      })
        .then((res) => {
          setResDlgOpn(true);
          setTimeout(() => {
            setResDlgOpn(false);
          }, 3000);
          props?.setOpen(false);
          props?.setDeleteData([]);
          props.setIsdeleted(true);
          setTimeout(() => {
            props.setIsdeleted(false);
          }, 2000);
          if (props?.setToggleVal !== undefined) {
            props?.setToggleVal(!props?.toggleVal);
          }
          if (props?.getGuestInfo !== undefined) {
            props?.getGuestInfo(props?.currentTbl);
          }
        })
        .catch((err) => {})
    );
  };

  return (
    <>
      <Dialog
        //   onClose={handleClose}

        open={props?.open}
        sx={{
          "& .MuiDialogContent-root": {
            border: "none",
          },
          "& .MuiDialog-paper": {
            maxWidth: "830px",
          },
          "& .MuiPaper-root": {
            background: CLR_LIGHT_WHITE,
            borderRadius: "20px",
            //   minWidth:"800px"
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomLabel color="black" label={props?.label} />
            <Tooltip title="Close">
              <CancelRounded
                style={{ width: "20px", height: "24px", cursor: "pointer" }}
                onClick={(item) => {
                  props?.setOpen(false);
                }}
              />
            </Tooltip>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "20px",
              marginRight: "20px",
              background: "white",
              borderRadius: "20px",
            }}
          >
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>No</TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Name
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Room Number
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Mobile
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Source
                    </TableCell>
                    {props?.data?.map((row, index) => (
                      <React.Fragment key={index}>
                        {row.num_of_days ? (
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            No of days
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {row.checked_in ? (
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            Arrival Date
                          </TableCell>
                        ) : (
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            Departure Date
                          </TableCell>
                        )}
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.data?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{row?.name}</TableCell>
                      <TableCell align="center">{row?.room_no}</TableCell>
                      <TableCell align="center">{row?.mobile}</TableCell>
                      <TableCell align="center">
                        {row?.booking_source}
                      </TableCell>
                      {row.num_of_days ? (
                        <TableCell align="center">{row?.num_of_days}</TableCell>
                      ) : (
                        ""
                      )}
                      {row.checked_in ? (
                        <TableCell align="center">
                          {new Date(row?.checked_in)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : moment(row?.checked_in).format("DD-MM-YYYY")}
                        </TableCell>
                      ) : (
                        <TableCell align="center">
                          {new Date(row?.checkout_date)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : moment(row?.checkout_date).format("DD-MM-YYYY")}
                        </TableCell>
                      )}

                      <TableCell
                        align="center"
                        style={{
                          display: "none",
                          "&.MuiTableCellRoot MuiTableCellBody": {
                            lineHeight: "0px!important",
                          },
                        }}
                      >
                        {row?.date}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={2}
          sx={{
            paddingBottom: "25px",
            marginTop: "10px",
          }}
        >
          <CustomCancelButton
            label="CANCEL"
            width="130px"
            handleChange={() => {
              props?.setOpen(false);
            }}
          />
          <CustomSaveButton
            width="130px"
            label="Delete"
            handleChange={() => {
              handleClickDelete();
            }}
          />
        </Stack>
      </Dialog>
      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label="Deleted successfully"
        width="400px"
        height="150px"
      />
    </>
  );
}

export default DeleteTableDataDlg;
