import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Menu,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import styles from "./dashboard.module.css";
import { useNavigate } from "react-router-dom";
import ProfileSettings from "../ProfileSettings/ProfileSettings";
import Appbar from "../../components/AppBar/Appbar";
import { getDashBoardData } from "../../redux/action/dashboard.action";
import { loadUsers } from "../../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import {
  CLR_DARK_GREY,
  CLR_LIGHT_ORANGE,
  CLR_LIGHT_WHITE,
  CLR_ORANGE,
} from "../../utils/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import BedIcon from "@mui/icons-material/Bed";
import io from "socket.io-client";
import { getGuestInfoData } from "../../services/bookingServices";
import GuestInfo from "../GuestInfo/GuestInfo";
import { trackPromise } from "react-promise-tracker";
import { CancelRounded, Print } from "@mui/icons-material";
import { ArrivalCells } from "../../utils/models/tablemodels";
import moment from "moment";
import TableUtils from "../../components/TableUtils";
import { addReportData } from "../../services/utilsServices";
import staticTexts from "../../utils/static-texts";

function Dashboard() {
  const dispatch = useDispatch();
  let newData;
  let smData;
  let { data, loading } = useSelector((state) => state.dashBoardReducer);

  const [reData, setReData] = useState({});
  const [records, setRecords] = useState([]);
  const [printRecords, setPrintRecords] = useState([]);
  const [tstContent, setTstContent] = useState("");
  const [tstOpn, setTstOpn] = useState(false);
  const [dlgCont, setDlgCont] = useState("");
  const [headCells, setHeadCells] = useState(ArrivalCells);
  const [selected, setSelected] = useState([]);
  const [userData, setUserData] = useState(null);

  const [filterFn, setfilterFn] = useState({
    fn: (items) => {
      // setFilterRecords(items);
      return items;
    },
  });

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    setUserData(data);
    // dispatch(loadDashBoardData());

    return () => {
      // socket.disconnect();
    };
  }, []);

  const dashboardData = async () => {
    await trackPromise(getDashBoardData()).then((res) => {
      setReData(res.data.data);
    });
  };

  useEffect(() => {
    dashboardData();
    // getGuestInfo();
  }, []);

  const getGuestInfo = async () => {
    await trackPromise(
      getGuestInfoData(
        {
          table_type: "All",
          filterValue: [],
        },
        null
      )
        .then((res) => {
          try {
            let newData = res.data.data;

            data.availability_stats.forEach((r, rindex) => {
              data["availability_stats"][rindex]["isLesserPersons"] = false;
              newData.forEach((n) => {
                if (r?.room_type_name == n?.room_type_name) {
                  if (
                    r?.room_type_name.toLowerCase() == "triple" &&
                    n.no_of_persons < 3
                  ) {
                    r["isLesserPersons"] = true;
                  }
                  if (
                    r?.room_type_name.toLowerCase() == "double" &&
                    n.no_of_persons < 2
                  ) {
                    r["isLesserPersons"] = true;
                  }
                  if (
                    (r?.room_type_name.toLowerCase() == "fourbed" ||
                      r?.room_type_name.toLowerCase() == "fourbed") &&
                    n.no_of_persons < 4
                  ) {
                    r["isLesserPersons"] = true;
                  }
                }
              });
            });
            setReData(data);
            smData = { ...data };
          } catch (err) {
            // alert(err);
          }
        })
        .catch((err) => { })
    );
  };

  const navigate = useNavigate();

  const [vacantRooms, setVacantRooms] = useState([]);
  const [open, setOpen] = useState(false);
  const [roomType, setRoomType] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const getVacantRoom = (room_type, rooms) => {
    setDlgCont("Vacant");
    setVacantRooms(rooms);
    setRoomType(room_type);
    setOpen(true);
  };

  const getOccupiedRooms = async (room_type) => {
    setDlgCont("Occupied");
    setRoomType(room_type);
    setOpen(true);
    await trackPromise(
      getGuestInfoData(
        {
          table_type: "occupied",
        },
        room_type
      )
        .then((res) => {
          try {
            let { data } = res?.data;
            let finalObj = [...data];
            if (data && data?.length > 0) {
              finalObj.sort((a, b) => {
                const roomNoA = a.room_no;
                const roomNoB = b.room_no;
                const isAlphaA = isNaN(roomNoA);
                const isAlphaB = isNaN(roomNoB);

                if (isAlphaA && !isAlphaB) {
                  return -1;
                }
                if (!isAlphaA && isAlphaB) {
                  return 1;
                }
                return roomNoA.localeCompare(roomNoB);
              });

              data?.map((main_data, index) => {
                finalObj[index].checked_in = main_data?.checkin_date
                  ? main_data?.checkin_date.slice(0, 10)
                  : null;
                finalObj[index].checkout_in = main_data?.checkout_date
                  ? main_data?.checkout_date.slice(0, 10)
                  : null;
                finalObj[index].sl_no = index + 1;
                finalObj[
                  index
                ].download_address = `${main_data?.address},${main_data?.country_name},${main_data?.state_name},${main_data?.city_name}`;
              });
              setRecords(finalObj);
            } else {
              setRecords([]);
              setTstContent("No Data Found");
              setTstOpn(true);
            }
          } catch (err) {
            setTstContent("An Error Occured");
            setTstOpn(true);
          }
        })
        .catch((err) => {
          setTstContent("An Error Occured");
          setTstOpn(true);
        }),
      getGuestInfoData(
        {
          table_type: "occupied",
        },
        null
      )
        .then((res) => {
          try {
            let { data } = res?.data;
            let finalObj = [...data];
            if (data && data?.length > 0) {
              finalObj.sort((a, b) => {
                const roomNoA = a.room_no;
                const roomNoB = b.room_no;
                const isAlphaA = isNaN(roomNoA);
                const isAlphaB = isNaN(roomNoB);

                if (isAlphaA && !isAlphaB) {
                  return -1;
                }
                if (!isAlphaA && isAlphaB) {
                  return 1;
                }
                return roomNoA.localeCompare(roomNoB);
              });

              data?.map((main_data, index) => {
                finalObj[index].checked_in = main_data?.checkin_date
                  ? main_data?.checkin_date.slice(0, 10)
                  : null;
                finalObj[index].checkout_in = main_data?.checkout_date
                  ? main_data?.checkout_date.slice(0, 10)
                  : null;
                finalObj[index].sl_no = index + 1;
                finalObj[
                  index
                ].download_address = `${main_data?.address},${main_data?.country_name},${main_data?.state_name},${main_data?.city_name}`;
              });
              setPrintRecords(finalObj);
            } else {
              setPrintRecords([]);
            }
          } catch (err) {
            setTstContent("An Error Occured");
            setTstOpn(true);
          }
        })
        .catch((err) => {
          setTstContent("An Error Occured");
          setTstOpn(true);
        })
    );
  };

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    TableUtils(records, headCells, filterFn, selected, "", "", "occupied");

  const addRows = () => {
    let trs = "";
    printRecords.length > 0 &&
      printRecords.forEach((element, index) => {
        trs += `
            <tr>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element?.sl_no}
           </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 4px;page-break-inside:avoid; page-break-after:auto">
             ${element?.room_no}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element?.name}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element.mobile ? element?.mobile : ""}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element?.occupation}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element?.city_name || "-"}
            </td>
            <td style="border: 1px solid #dddddd; text-align: center; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${element?.no_of_persons}
            </td>
            <td style="border: 1px solid #dddddd; text-align: left; padding: 4px;page-break-inside:avoid; page-break-after:auto">
            ${moment(element?.checkin_date).format("DD-MM-YYYY")}${" "}
            ${moment(element?.checkin_date).format("hh:mm A")}
            </td>
          </tr>`;
      });
    return trs;
  };

  const onPrint = async () => {
    var printContent = `
        <div style="width: 100%;">
        <div style = "position : absolute; right : 20px; cursor : pointer" onclick = window.close()>[X]</div>
        <div style="width: 100%; text-align: center;padding: 0px;">
        <h3 style = "letter-spacing: 1px;">${userData.hotel_name} HOTEL</h3>
        </div>
          <div style = "padding: 0px;">
             <div style = "display : flex; justify-content:space-between; align-items:center">
             <h3>
                  OCCUPIED ROOMS
              </h3>
              <div >
                  <h3>Address</h3>
                  <pre style = "margin-top: -20px">${userData.hotel_address
      }</pre>
              </div>
              </div>
          </div>
        <div style="padding-top: 1px">
          <div style="margin-bottom: 15px; font-size: 0.9em; line-height: 22px">
            <table
            id="table"
              style="
                font-family: arial, sans-serif;
                font-size:13px;
                border-collapse: collapse;
                width: 100%;
                page-break-after:auto;"
            >
              <tr style="border-bottom: 2px solid black; border-top: 2px solid black;
              page-break-inside:avoid; page-break-after:auto
              ">
                <th style="border: 1px solid #dddddd; text-align: left; padding: 4px">
                  S.No
                </th>
                <th style="border: 1px solid #dddddd; text-align: left; padding: 4px">
                Room No.
              </th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 4px">
                  Name
                </th>
                <th style="border: 1px solid #dddddd; text-align: center; padding: 4px">
                  Mobile
                </th>
                <th style="border: 1px solid #dddddd; text-align: center; padding: 4px">
                Occupation
                </th>
                <th style="border: 1px solid #dddddd; text-align: center; padding: 4px">
                City
                </th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 4px">
                NOP
                </th>
                
                <th style="border: 1px solid #dddddd; text-align: left; padding: 4px ;min-width:100px!important">
                  Arrival Date & Time   
                </th>
              </tr>
             ${addRows()}
            </table>
          </div>
        </div>
      </div>
      </body>
      </html>`;

    const WindowPrt = window.open(
      "",
      "",
      "left=0,top=0,width=1500,height=900,toolbar=0,scrollbars=0,status=0"
    );

    WindowPrt?.document.write(printContent);
    const delay = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    await delay(1000);
    WindowPrt?.focus();
    WindowPrt?.print();
  };

  const handleClickPrint = () => {
    if (printRecords && printRecords.length > 0) {
      onPrint();
      addReportData({ report_type: "Occupied" });
    } else {
      setTstContent("No data available to print");
      setTstOpn(true);
    }
  };

  return (
    <Box>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <SideBar />
        <Box
          id={styles.mainbox}
          sx={{
            background: "rgba(0,0,0,0.42)",
          }}
          style={{
            marginTop: "62px",
            marginRight: "44px",
            marginBottom: "44px",
          }}
          flex={4}
        >
          <Appbar heading="Dashboard" />

          <Box
            sx={{
              marginLeft: "40px",
              marginRight: "40px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Box id={styles.firstbox}>
                <img id={styles.camera} src="/images/icon_key.png" />
                <Typography
                  sx={{
                    color: `${CLR_ORANGE}`,
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  TOTAL ROOMS :{" "}
                  <span id={styles.dash_stats_count}>
                    {reData?.total_stats?.total_rooms}
                  </span>
                </Typography>
              </Box>
              <Box id={styles.firstbox}>
                <img id={styles.camera} src="/images/icon_bed.png" />
                <Typography
                  sx={{
                    color: `${CLR_ORANGE}`,
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  VACANT ROOMS :{" "}
                  <span id={styles.dash_stats_count}>
                    {reData?.total_stats?.total_vacant_rooms}
                  </span>
                </Typography>
              </Box>
              <Box id={styles.firstbox}>
                <img id={styles.camera} src="/images/icon_user_lock.png" />
                <Typography
                  sx={{ color: CLR_ORANGE, fontSize: "14px", fontWeight: 600 }}
                >
                  OCCUPIED ROOMS :{" "}
                  <span id={styles.dash_stats_count}>
                    {reData?.total_stats?.total_occupied_rooms}
                  </span>
                </Typography>
              </Box>
            </Stack>

            <Box sx={{ width: "100%", marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item sm={6} lg={6} md={6}>
                  <Box
                    id="scroll"
                    sx={{
                      backgroundColor: "#808080",
                      borderRadius: "15px",
                      border: "4px solid #FFC959",
                      minHeight: "192px",
                      maxHeight: "192px",
                      overflowY: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: "8px",
                        paddingBottom: "20px",
                      }}
                    >
                      <Typography
                        id={styles.stat_head}
                        sx={{
                          visibility: "hidden",
                        }}
                      >
                        AVAILABILITY
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          color: "red",
                        }}
                      >
                        <Typography id={styles.stat_head}>LIVE</Typography>
                        <button type="submit" id={styles.glowing_dot}></button>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-around" }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "white",
                          visibility: "hidden",
                        }}
                      >
                        vacantvacantvacant
                      </Typography>
                      <Typography sx={{ fontSize: "14px", color: "white" }}>
                        Vacant
                      </Typography>
                      <Typography sx={{ fontSize: "14px", color: "white" }}>
                        Occupied
                      </Typography>
                    </Box>
                    <Box>
                      {reData.availability_stats &&
                        reData.availability_stats.length > 0 &&
                        reData.availability_stats.map((item, index) => (
                          <Box
                            style={{
                              // paddingRight: "30px",
                              display: "flex",
                              justifyContent: "space-around",
                              // alignItems: "center",
                              // width: "100%!important",
                              // overflowY : "scroll"
                            }}
                            key={index}
                          >
                            <Box sx={{ display: "flex" }}>
                              <BedIcon
                                color={CLR_LIGHT_WHITE}
                                style={{
                                  color: CLR_LIGHT_WHITE,
                                  paddingRight: "4px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  minWidth: "130px",
                                  color: CLR_LIGHT_WHITE,
                                }}
                              >
                                {item?.room_type_name}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "left",
                                paddingRight: "40px",
                                "&:hover": {
                                  cursor:
                                    item?.vacant_rooms > 0
                                      ? "pointer"
                                      : "default",
                                },
                              }}
                              onClick={(event) => {
                                if (item?.vacant_rooms > 0) {
                                  getVacantRoom(
                                    item?.room_type_name,
                                    item.room_nos.vacant_room_no
                                  );
                                }
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "20px",
                                  minWidth: "30px",
                                  color: CLR_LIGHT_WHITE,
                                }}
                              >
                                {item?.vacant_rooms}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                "&:hover": {
                                  cursor:
                                    item?.occupied_rooms > 0
                                      ? "pointer"
                                      : "default",
                                },
                                textDecoration:
                                  item?.occupied_rooms > 0 ? "underline" : "",
                                textDecorationColor: "#FFFFFF",
                              }}
                              onClick={() => {
                                if (item?.occupied_rooms > 0) {
                                  getOccupiedRooms(item?.room_type_name);
                                }
                              }}
                            >
                              <Typography
                                id={item.isLesserPersons ? styles.glow : ""}
                                sx={{
                                  fontSize: "20px",
                                  minWidth: "30px",
                                  color: item.isLesserPersons
                                    ? "#E98D12"
                                    : CLR_LIGHT_WHITE,
                                }}
                              >
                                {item?.occupied_rooms}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} lg={6} md={6}>
                  <Box
                    id={styles.dahboardbox}
                    onClick={() => {
                      navigate("/guest-info");
                    }}
                    style={{
                      backgroundImage: `url(${"images/img_dash_guest_info.png"})`,
                      backgroundSize: "inherit",
                      justifyContent: "normal",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography id={styles.dash_head}>GUEST INFO</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ width: "100%", display: "flex", marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item sm={6} lg={6}>
                  <Box
                    id={styles.dahboardbox}
                    style={{
                      backgroundImage: `url(${"images/img_dash_arrival.png"})`,
                      backgroundSize: "inherit",
                      justifyContent: "normal",
                      alignItems: "flex-start",
                    }}
                    onClick={() => {
                      navigate("/add-booking");
                    }}
                  >
                    <Typography id={styles.dash_head}>ARRIVAL</Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} lg={6}>
                  <Box
                    id={styles.dahboardbox}
                    style={{
                      backgroundImage: `url(${"images/img_dash_departure.png"})`,
                      backgroundSize: "inherit",
                      justifyContent: "normal",
                      alignItems: "flex-start",
                    }}
                    onClick={() => {
                      navigate("/departure");
                    }}
                  >
                    <Typography id={styles.dash_head}>DEPARTURE</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Stack>

      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        sx={{
          width: "100%",
          padding: "5px",
          borderRadius: "10px",
          "& .MuiPaper-root": {
            background: CLR_LIGHT_WHITE,
            borderRadius: "20px",
          },
        }}
        disableEnforceFocus
      >
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                color: "#E98D12",
                fontWeight: "bold",
                fontSize: "25px",
                width: "60%",
              }}
            >
              {roomType}
            </Typography>
            <Box
              sx={{
                width: "20%",
                textAlign: "right",
              }}
            >
              {dlgCont == "Occupied" ? (
                <Tooltip title="Print">
                  <Print
                    sx={{
                      width: "30px",
                      height: "30px",
                      color: "#E98D12",
                      cursor: "pointer",
                      marginRight: "45px",
                    }}
                    onClick={handleClickPrint}
                  />
                </Tooltip>
              ) : (
                ""
              )}
              <Tooltip title="Close">
                <CancelRounded
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    color: "#E98D12",
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </Tooltip>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {dlgCont == "Vacant" ? (
            <>
              <Box
                style={{
                  minHeight: "20vh",
                  minWidth: "40vw",
                  backgroundColor: "white",
                  width: "100%",
                  overFlow: "scroll",
                  // marginBottom: "10px",
                  border: "0.5px solid #7D86A9",
                  borderRadius: "10px",
                  padding: "0px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    position: "relative",
                    padding: "5px 10px",
                  }}
                >
                  {vacantRooms?.map((item, index) => (
                    <Box id={styles.room_head} key={index}>
                      <Box id={styles.rooms}>
                        <Typography>{item?.vacant_room_no}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          ) : (
            <Box
              style={{
                maxHeight: "600px",
                minHeight: "600px",
                minWidth: "100%",
                backgroundColor: "white",
                width: "100%",
                overFlow: "scroll",
                borderRadius: "10px",
                padding: "5px",
              }}
            >
              <TblContainer>
                <TblHead />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((item, index) => (
                    <TableRow key={item?.index}>
                      <TableCell
                        style={{ padding: "8px 10px", minWidth: "50px" }}
                      >
                        {item.sl_no}
                      </TableCell>
                      {headCells?.map((data, ind) => (
                        <React.Fragment key={ind}>
                          {data?.id === "room_number" ? (
                            <TableCell
                              style={{ padding: "8px 40px", minWidth: "80px" }}
                            >
                              {item?.room_no}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "name" ? (
                            <TableCell
                              style={{ padding: "8px 20px", minWidth: "100px" }}
                            >
                              {item?.name}
                            </TableCell>
                          ) : (
                            ""
                          )}

                          {data?.id === "mobile" ? (
                            <TableCell
                              style={{ padding: "8px 0px", minWidth: "150px" }}
                            >
                              {item?.mobile}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "address" ? (
                            <TableCell
                              style={{ padding: "8px 10px", minWidth: "150px" }}
                            >
                              {item?.address}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "coutry_name" ? (
                            <TableCell
                              style={{ padding: "8px 25px", minWidth: "80px" }}
                            >
                              {item?.country_name}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "state_name" ? (
                            <TableCell
                              style={{ padding: "8px 0px", minWidth: "140px" }}
                            >
                              {item?.state_name}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "city_name" ? (
                            <TableCell
                              style={{ padding: "8px 0px", minWidth: "130px" }}
                            >
                              {item?.city_name}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "no_of_persons" ? (
                            <TableCell
                              style={{ padding: "8px 50px", minWidth: "50px" }}
                            >
                              {item?.no_of_persons}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "occupation" ? (
                            <TableCell
                              style={{ padding: "8px 20px", minWidth: "100px" }}
                            >
                              {item?.occupation}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "purpose_of_visit" ? (
                            <TableCell
                              style={{ padding: "8px 40px", minWidth: "80px" }}
                            >
                              {item?.purpose_of_visit}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "arrival_signature_image" ? (
                            <TableCell style={{ padding: "8px" }}>
                              <Box>
                                <img
                                  src={item?.arrival_signature_image_url}
                                  style={{ height: "50px", width: "50px" }}
                                />
                              </Box>
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "arrival_date" ? (
                            <TableCell
                              style={{ padding: "8px 17px", minWidth: "120px" }}
                            >
                              {moment(item?.checkin_date).format("DD-MM-YYYY")}
                            </TableCell>
                          ) : (
                            ""
                          )}
                          {data?.id === "arrival_time" ? (
                            <TableCell
                              style={{ padding: "8px 17px", minWidth: "120px" }}
                            >
                              {moment(item?.checkin_date).format("hh:mm:ss A")}
                            </TableCell>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </TblContainer>
              <TblPagination />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Dashboard;
