import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomLabel from "../../components/CustomLabel";
import styles from "./profilesettings.module.css";
import EditIcon from "@mui/icons-material/Edit";

import Appbar from "../../components/AppBar/Appbar";
import { CLR_DARK_GREY, CLR_LIGHT_WHITE, CLR_ORANGE } from "../../utils/colors";
import ErrorField from "../../components/ErrorField";
import CustomTextField from "../../components/CustomTextField";

import { trackPromise } from "react-promise-tracker";
import {
  getUsersData,
  updateUserDetailsData,
} from "../../services/userService";
import CustomSnackBar from "../../components/CustomSnackBar";
import staticTexts from "../../utils/static-texts";
function ProfileSettings() {
  let intialValues = {
    name: "",
    username: "",
    password: "",
    role_type: "",
    contact: "",
  };
  let initialErrors = {
    name: "",
    username: "",
    password: "",
    role_type: "",
    contact: "",
  };
  const navigate = useNavigate();
  const [openProf, setOpenProf] = useState(false);
  const [openRsrv, setOpenRsrv] = useState(false);

  const [adminValues, setAdminValues] = useState(intialValues);
  const [empValues, setEmpValues] = useState(intialValues);
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [adminErrors, setAdminErrors] = useState({ ...initialErrors });
  const [empErrors, setEmpErrors] = useState({ ...initialErrors });
  useEffect(() => {
    getUserData();
  }, []);

  const validateForm = async (type) => {
    let newerrors;
    let stateValue = null;
    if (type == 1) {
      stateValue = adminValues;
      newerrors = adminErrors;
    } else {
      stateValue = empValues;
      newerrors = empErrors;
    }
    stateValue.role_type = type;

    if (!stateValue.username && stateValue.username.length <= 0) {
      newerrors.username = "This Field is Required";
    } else {
      newerrors.username = "";
    }

    if (!stateValue.password && stateValue.password.length <= 0) {
      newerrors.password = "This Field is Required";
    } else {
      newerrors.password = "";
    }
    if (type == 1) {
      setAdminErrors({ ...newerrors });
    } else {
      setEmpErrors({ ...newerrors });
    }

    for (let key in newerrors) {
      if (newerrors[key] != "") {
        setTstContent("Please fill all the mandatory fields ");
        setTstOpn(true);
        return;
      }
    }
    await trackPromise(
      updateUserDetailsData(stateValue)
        .then((res) => {
          let { data } = res?.data;
          setTstContent(data);
          setTstOpn(true);

          if (type == 1) {
            setAdminErrors(initialErrors);
            setAdminValues(intialValues);
          } else {
            setEmpErrors(initialErrors);
            setEmpValues(intialValues);
          }
          navigate(-1);
        })
        .catch((err) => {
          if (err?.response) {
            setTstContent(err?.response?.data?.error);
            setTstOpn(true);
          }
        })
    );
  };

  const handleSubmitForm = (data) => {
    validateForm(data);
  };

  const getUserData = async () => {
    await trackPromise(
      getUsersData()
        .then((res) => {
          let { data } = res?.data;

          data?.map((item) => {
            if (item?.role_id == 1) {
              setAdminValues({
                ...adminValues,

                name: item?.name,
                username: item?.username,
                role_type: item?.role_id,
                contact: item?.contact,
              });
            } else if (item?.role_id !== 1) {
              setEmpValues({
                ...empValues,

                name: item?.name,
                username: item?.username,
                role_type: item?.role_id,
                contact: item?.contact,
              });
            }
          });
        })
        .catch((err) => {})
    );
  };

  const handleInputDataChange = (e, type) => {
    let { name, value } = e.target;

    if (name == "contact" && value?.length >= 10) {
      value = value.substr(0, 10);
    }

    if (type == 1) {
      setAdminValues({ ...adminValues, [name]: value });
    } else {
      setEmpValues({ ...empValues, [name]: value });
    }
  };

  return (
    <Box id={styles.mainbox}>
      <Box style={{ paddingTop: "12px" }}>
        <Appbar logo="true" />
      </Box>

      <Grid
        container
        spacing={3}
        style={{ paddingLeft: "12px", paddingRight: "12px" }}
      >
        <Grid item lg={5} md={5} sm={5}>
          <Box
            id={styles.firstbox}
            onClick={(item) => {
              navigate(-1);
            }}
          >
            <img
              style={{ width: "14px", height: "24px", cursor: "pointer" }}
              src="/images/Icon_left_arrow.png"
            />
            <Typography
              style={{
                paddingLeft: "10px",
                fontWeight: 500,
                color: "white",
                margin: "8px",
              }}
            >
              Profile Setting
            </Typography>

            <EditIcon
              sx={{ marginLeft: "10px", color: "white", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenProf(!openProf);
                setAdminErrors(initialErrors);
              }}
            />
          </Box>
          <Box>
            <CustomLabel label="User Name *" />

            <CustomTextField
              placeholder="user name"
              value={adminValues?.username}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              disabled={!openProf}
              name="username"
              handleChange={(e) => {
                handleInputDataChange(e, adminValues?.role_type);
              }}
            />

            {/* {adminErrors.username.length > 0 && (
              <ErrorField label={adminErrors.username} />
            )} */}
          </Box>

          <Box>
            <CustomLabel label="Name" />
            <CustomTextField
              placeholder="name"
              value={adminValues?.name}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              name="name"
              disabled={!openProf}
              handleChange={(e) => {
                handleInputDataChange(e, adminValues?.role_type);
              }}
            />

            {/* {adminErrors.name.length > 0 && (
              <ErrorField label={adminErrors.name} />
            )} */}
          </Box>

          <Box>
            <CustomLabel label="Contact" />

            <CustomTextField
              placeholder="Mobile"
              value={adminValues?.contact}
              border="1px solid #707070"
              borderRadius="5px"
              type="number"
              height="0px"
              name="contact"
              disabled={!openProf}
              handleChange={(e) => {
                handleInputDataChange(e, adminValues?.role_type);
              }}
            />

            {/* {adminErrors.contact.length > 0 && (
              <ErrorField label={adminErrors.password} />
            )} */}
          </Box>
          <Box>
            <CustomLabel label="Change Password *" />

            <CustomTextField
              placeholder="password"
              value={adminValues?.password}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              name="password"
              disabled={!openProf}
              handleChange={(e) => {
                handleInputDataChange(e, adminValues?.role_type);
              }}
            />

            {/* {adminErrors.password.length > 0 && (
              <ErrorField label={adminErrors.password} />
            )} */}
          </Box>
          {openProf && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{
                paddingBottom: "25px",
                marginTop: "30px",
              }}
            >
              <Button
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: CLR_DARK_GREY,
                  borderRadius: "10px",
                  width: "110px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: CLR_DARK_GREY,
                    background: CLR_LIGHT_WHITE,
                  },
                }}
                variant="contained"
                onClick={() => {
                  setAdminValues(intialValues);
                  setAdminErrors(initialErrors);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: CLR_ORANGE,
                  borderRadius: "10px",
                  width: "110px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: CLR_ORANGE,
                    background: CLR_LIGHT_WHITE,
                  },
                }}
                onClick={() => {
                  handleSubmitForm(adminValues?.role_type);
                }}
              >
                Save
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item lg={2} md={2} sm={2}>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            style={{ marginRight: "-1px" }}
          />
        </Grid>

        <Grid item lg={5} md={5} sm={5}>
          <Box
            id={styles.firstbox}
            onClick={(item) => {
              navigate(-1);
            }}
          >
            <img
              style={{ width: "14px", height: "24px", cursor: "pointer" }}
              src="/images/Icon_left_arrow.png"
            />
            <Typography
              style={{
                paddingLeft: "10px",
                fontWeight: 500,
                color: "white",
                margin: "8px",
              }}
            >
              Reception Id Setting
            </Typography>

            <EditIcon
              sx={{ marginLeft: "10px", color: "white", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenRsrv(!openRsrv);
                setEmpErrors(initialErrors);
              }}
            />
          </Box>
          <Box>
            <CustomLabel label="User Name *" />

            <CustomTextField
              placeholder="user Name"
              value={empValues?.username}
              name="username"
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              handleChange={(e) => {
                handleInputDataChange(e, "emp");
              }}
              disabled={!openRsrv}
            />
            {/* {empErrors.username.length > 0 && (
              <ErrorField label={empErrors.username} />
            )} */}
          </Box>
          <Box>
            <CustomLabel label="Name *" />
            <CustomTextField
              placeholder="name"
              name="name"
              value={empValues?.name}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              handleChange={(e) => {
                handleInputDataChange(e, "emp");
              }}
              disabled={!openRsrv}
            />
            {/* {empErrors.name.length > 0 && <ErrorField label={empErrors.name} />} */}
          </Box>

          <Box>
            <CustomLabel label="Contact *" />

            <CustomTextField
              placeholder="Mobile"
              name="contact"
              type="number"
              value={empValues?.contact}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              handleChange={(e) => {
                handleInputDataChange(e, "emp");
              }}
              disabled={!openRsrv}
            />
            {/* {empErrors.contact.length > 0 && (
              <ErrorField label={empErrors.contact} />
            )} */}
          </Box>
          <Box>
            <CustomLabel label="Change Password *" />

            <CustomTextField
              placeholder="Password"
              name="password"
              value={empValues?.password}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              handleChange={(e) => {
                handleInputDataChange(e, "emp");
              }}
              disabled={!openRsrv}
            />
            {/* {empErrors.password.length > 0 && (
              <ErrorField label={empErrors.password} />
            )} */}
          </Box>
          {openRsrv && (
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
              sx={{
                paddingBottom: "25px",
                marginTop: "30px",
              }}
            >
              <Button
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: CLR_DARK_GREY,
                  borderRadius: "10px",
                  width: "110px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: CLR_DARK_GREY,
                    background: CLR_LIGHT_WHITE,
                  },
                }}
                variant="contained"
                onClick={() => {
                  setEmpValues(intialValues);
                  setEmpErrors(initialErrors);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  background: CLR_ORANGE,
                  borderRadius: "10px",
                  width: "110px",
                  shadowColor: "1px 1px 6px #E98D12",
                  "&:hover": {
                    color: CLR_ORANGE,
                    background: CLR_LIGHT_WHITE,
                  },
                }}
                onClick={() => {
                  handleSubmitForm(empValues?.role_type);
                }}
              >
                Save
              </Button>
            </Stack>
          )}
        </Grid>
      </Grid>
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />
    </Box>
  );
}

export default ProfileSettings;
