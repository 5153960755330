import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CustomLabel from "../CustomLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../redux/action/profile.action";
import imageCompression from "browser-image-compression";
import styles from "./appbar.module.css";
import {
  CLR_ORANGE,
  CLR_DLG_WHITE,
  CLR_LIGHT_GREY,
  CLR_LIGHT_WHITE,
} from "../../utils/colors";
import Logout from "@mui/icons-material/Logout";
import { logout } from "../../redux/action/login.action";
import staticTexts from "../../utils/static-texts";
import CustomDiolog from "../Diologues/CustomDiolog";
import {
  Person,
  Upload,
  UploadFile,
  UploadFileTwoTone,
} from "@mui/icons-material";
import { trackPromise } from "react-promise-tracker";
import { loginUser, updateProfileImg } from "../../services/userService";
import CustomSnackBar from "../CustomSnackBar";
import ResultDiologueBox from "../ResultDiologueBox";

function Appbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [name, setName] = useState(null);
  const [userData, setUserData] = useState(null);
  const [logoutDlg, setLogoutDlg] = useState(false);

  const fileUpload = useRef(null);
  const [profileImg, setProfileImg] = useState();
  const [profileFile, setProfileFile] = useState();
  const [openProfDlg, setOpenProfDlg] = useState(false);
  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [tstOpn, setTstOpn] = useState(false);

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    setUserData(data);
  }, []);

  const handleOpenProfDlg = () => {
    setOpenProfDlg(true);
  };

  const handleCloseProfDlg = () => {
    setOpenProfDlg(false);
    setProfileImg();
  };

  const handleLogout = () => {
    setTimeout(() => {
      dispatch(logout());
    }, 100);
  };

  const handleCapture = async (target) => {
    const fileInput = document.getElementById("select-image");

    if (target.files) {
      if (target.files?.length !== 0) {
        const file = target.files[0];

        const newUrl = URL.createObjectURL(file);

        const options = {
          maxSizeMB: 0.05,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          setProfileImg(newUrl);
          setProfileFile(compressedFile);
        } catch (error) { }
      }
    }
  };

  const updateProfile = () => {
    const formData = new FormData();
    formData.append("profileImg", profileFile);
    formData.append("userId", userData.userId);

    trackPromise(
      updateProfileImg(formData)
        .then((res) => {
          localStorage.setItem(
            staticTexts.PROFILE_IMG,
            res.data.data.profile_img
          );
          setOpenProfDlg(false);
          setProfileImg();
          setResDlgOpn(true);
          setTimeout(() => setResDlgOpn(false), 3000);
          console.log(profileImg);
        })
        .catch((err) => {
          setTstOpn(true);
          setTimeout(() => setTstOpn(false), 3000);
        })
    );
  };

  const profile_img = localStorage.getItem(staticTexts.PROFILE_IMG)
    ? localStorage.getItem(staticTexts.PROFILE_IMG)
    : null;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = () => {
    const finalData = {
      name: "musammil",
      email: "test@gmail.com",
    };
    // dispatch(postApiaction(finalData))
  };

  return (
    <React.Fragment>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          // sx={{,marginTop:"25px"}}
          style={{
            // height: "130px",
            position: "relative",
            top: "2px",
            marginLeft: "20px",
            marginRight: "30px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          {props?.heading && props?.logo == undefined && (
            <Typography
              sx={{
                color: "white",
                fontWeight: "700",
                margin: "8px",
              }}
            >
              {props?.heading}
            </Typography>
          )}

          {props?.heading != undefined && props?.logo != undefined && (
            <CustomLabel label=" " />
          )}

          {props?.logo != undefined ? (
            <img id={styles.logo} src={userData?.hotel_logo} />
          ) : (
            ""
          )}

          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2, marginRight: "10px", marginTop: "40px" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {userData?.profile_img === null ? (
                <Avatar sx={{ width: 42, height: 42 }}></Avatar>
              ) : (
                <img
                  style={{
                    width: "42px",
                    height: "42px",
                    borderRadius: "50px",
                  }}
                  src={profile_img ? profile_img : userData?.profile_img}
                />
              )}
            </IconButton>
          </Tooltip>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              borderRadius: "20px",
              color: CLR_ORANGE,
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleOpenProfDlg}>
            <Person color={CLR_ORANGE} style={{ paddingRight: "3px" }} />
            Update Profile
          </MenuItem>
          {userData?.role == 1 && (
            <MenuItem
              onClick={(item) => {
                navigate("/profile-settings");
              }}
            >
              <SettingsIcon
                color={CLR_ORANGE}
                style={{ paddingRight: "3px" }}
              />
              Manage Account
            </MenuItem>
          )}
          <MenuItem
            onClick={(item) => {
              navigate("/room-setting");
            }}
          >
            <RoomPreferencesIcon
              color={CLR_ORANGE}
              style={{ paddingRight: "3px" }}
            />
            Room Settings
          </MenuItem>
          <MenuItem
            onClick={(item) => {
              setLogoutDlg(true);
            }}
          >
            <LogoutIcon
              color={CLR_ORANGE}
              style={{ paddingRight: "3px", marginLeft: "2px" }}
            />
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <CustomDiolog
        label="Do you want to logout?"
        open={logoutDlg}
        setOpen={setLogoutDlg}
        customFunction={handleLogout}
        confirmLabel={"Yes"}
        cancelLabel={"No"}
      />

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openProfDlg}
        sx={{
          "& .MuiPaper-root": {
            background: CLR_DLG_WHITE,
            borderRadius: "20px",
            height: "55%",
            width: "76%",
          },
          "& .MuiDialogContent-root": {
            border: "none",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography sx={{ color: CLR_ORANGE, fontWeight: "bold" }}>
            Photo
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseProfDlg}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <Tooltip title="Close">
              <img
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                src="/images/icon_close_circle_orange.png"
                onClick={(item) => {
                  handleCloseProfDlg();
                }}
              />
            </Tooltip>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            className={styles.boxContainer}
            style={{
              justifyContent: "center",
              marginLeft: "20px",
              marginRight: "20px",
              position: "relative",
              display: "flex",
              height: "93%",
              width: "90%",
            }}
            onClick={() => {
              document.getElementById("select-image").click();
            }}
          >
            {profileImg ? (
              <Box
                justifyContent="center"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={profileImg}
                  style={{ width: "100%", height: "100%" }}
                  alt={"snap"}
                ></img>
              </Box>
            ) : (
              <Box
                justifyContent="center"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={
                    profile_img !== null
                      ? profile_img
                      : userData?.profile_img
                        ? userData?.profile_img
                        : "images/img_enlarged_logo.png"
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    background: CLR_LIGHT_GREY,
                  }}
                  alt={"snap"}
                ></img>
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                top: "255px",
                position: "absolute",
              }}
            >
              <input
                accept="image/*"
                id="select-image"
                type="file"
                capture="environment"
                onChange={(e) => handleCapture(e.target)}
                style={{ display: "none" }}
              />
            </Box>
            <UploadFileTwoTone
              className={styles.uploadIcon}
              style={{
                width: "200px",
                height: "200px",
                color: `${CLR_ORANGE}`,
              }}
            />
          </Box>
        </DialogContent>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={1}
          sx={{
            paddingBottom: "25px",
            marginTop: "-35px",
          }}
        >
          <Button
            disabled={!profileImg}
            sx={{
              paddingLeft: "10px",
              marginRight: "-60px",
              paddingRight: "10px",
              background: `${CLR_LIGHT_WHITE}`,
              borderRadius: "10px",
              shadowColor: "1px 1px 6px #E98D12",
              color: `${CLR_ORANGE}`,
              "&:hover": {
                color: `${CLR_ORANGE}`,
                background: `${CLR_LIGHT_WHITE}`,
              },
            }}
            variant="contained"
            onClick={updateProfile}
          >
            Save as Profile
          </Button>
          <Button
            variant="contained"
            sx={{
              paddingLeft: "10px",
              marginLeft: "-50px",
              paddingRight: "10px",
              background: `${CLR_ORANGE}`,
              borderRadius: "10px",
              shadowColor: "1px 1px 6px #E98D12",
              "&:hover": {
                color: `${CLR_ORANGE}`,
                background: `${CLR_LIGHT_WHITE}`,
              },
            }}
            onClick={handleCloseProfDlg}
          >
            Cancel
          </Button>
        </Stack>
      </Dialog>
      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label={"Profile Updated Successfully !"}
        width="400px"
        height="150px"
      />
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={"Profile Updation Failed"}
      />
    </React.Fragment>
  );
}

export default Appbar;
