import { MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import React from "react";
import { CLR_ORANGE } from "../utils/colors";

function CustomSelect(props) {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "250px",
        // width: 250,
      },
    },
  };
  return (
    <>
      {/* <FormControl sx={{ m: 1 }} variant="outlined"> */}

      <Select
        fullWidth
        variant="standard"
        id="combo-box-demo"
        disabled={props?.disable}
        label={props?.label}
        disableUnderline
        value={props?.value}
        onChange={props?.handleChange}
        placeholder={props?.placeholder}
        nooptionsmessage={props?.noOptionsMessage}
        onBlur={props?.onBlur}
        name={props?.name}
        displayEmpty
        renderValue={
          props?.value && props?.value !== ""
            ? undefined
            : () => props?.placeholder
        }
        MenuProps={MenuProps}
        sx={{
          "& .MuiInputBase-input": {
            opacity: props?.opacity ? props.opacity : 1,
            borderRadius: props?.borderRadius,
            position: "relative",
            border: props?.border,
            fontSize: props?.fontSize,
            width: props?.width,
            height: "27px",
            padding: props?.padding,
            borderRadius: props?.borderRadius,
            background: "white!important",
            color:
              props?.value && props?.value !== "" ? "black" : "hsl(0, 0%, 50%)",
          },
          "& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
            color: "#E98D12!important",
          },
          "& .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon": {
            color: "#E98D12!important",
          },
          "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },
          "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },
          "& .css-1cccqvr.css-1cccqvr.css-1cccqvr": {
            fontSize: props?.value !== "" ? "16px!important" : "14px!important",
          },

          // css-1cccqvr.css-1cccqvr.css-1cccqvr

          "& .css-194am0m-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input  ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },
          "& .css-1cthicf-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },

          borderRadius: props?.borderRadius,
          marginRight: props?.marginRight,
        }}
        // input={props.value}

        // input={<OutlinedInput label="Name"  value={props.value}/>}
      >
        {props?.muiOptions.map((option) => {
          return (
            <MenuItem
              sx={{
                fontSize: props?.optionFontSize,
              }}
              key={option?.id}
              value={option?.id}
            >
              {option?.value}
              {/* props.value==""?props.value: */}
            </MenuItem>
          );
        })}
      </Select>
      {/* </FormControl> */}
    </>
  );
}

export default CustomSelect;
