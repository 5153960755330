import axios from "axios";

export const addEditRooms = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/rooms/addEditDeleteRooms`,

    body
  );
};

export const getRoomStatics = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/rooms/getRoomstats`,
    {}
  );
};

export const getAvailableRoomStatics = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/rooms/getAvailableRoomstats/${id}`,
    {}
  );
};
