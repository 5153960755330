import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../components/useFormHook/CustomTextField";
import TextField from "../../components/CustomTextField";
import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../../utils/colors";
import ErrorField from "../../components/ErrorField";
import CustomSnackBar from "../../components/CustomSnackBar";
import { trackPromise } from "react-promise-tracker";
import {
  getHotelsData,
  getRolesData,
  registerUserDetailsData,
} from "../../services/userService";
import CustomSelect from "../../components/CustomSelect";
import CustomLabel from "../../components/CustomLabel";
import styles from "./profilesettings.module.css";

function Register() {
  const [isRegister, setIsRegister] = useState(false);
  const [isLogin, setIsLogin] = useState(true);

  const validationSchema = yup.object().shape({
    loginUsername: yup.string().required("Username Required").trim(),
    loginPassword: yup.string().required("Password Required").trim(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHandler = async (finalData) => {
    let LoginData = { loginUsername: "Ysquare", loginPassword: "Ysquare@123" };

    if (JSON.stringify(finalData) == JSON.stringify(LoginData)) {
      setIsLogin(false);
      setIsRegister(true);
    } else {
      setTstContent("Invalid Credentials");
      setTstOpn(true);
    }
  };

  let intialValues = {
    role_type: "",
    hotel_name: "",
    hotel_address: "",
    hotel_email: "",
    hotel_land_line_number: "",
    hotel_contact_number: "",
    name: "",
    user_email: "",
    contact: "",
    image: "",
    username: "",
    password: "",
  };
  let initialErrors = {
    role_type: "",
    hotel_name: "",
    hotel_address: "",
    hotel_email: "",
    hotel_land_line_number: "",
    hotel_contact_number: "",
    name: "",
    user_email: "",
    contact: "",
    image: "",
    username: "",
    password: "",
  };
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [userValues, setUserValues] = useState(intialValues);
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [userErrors, setUserErrors] = useState({ ...initialErrors });
  const [roles, setRoles] = useState([]);
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    getRolesHotel();
  }, []);

  const getRolesHotel = async () => {
    await trackPromise(
      getRolesData()
        .then((res) => {
          let arr = [];
          res?.data?.map((i) => {
            let a = { id: i.role_id, value: i.role_type };
            arr.push(a);
          });
          setRoles(arr);
        })
        .catch((err) => {
          if (err?.response) {
            setTstContent(err?.response?.data?.error);
            setTstOpn(true);
          }
        })
    );
    await trackPromise(
      getHotelsData()
        .then((res) => {
          let arr = [];
          res?.data?.map((i) => {
            let a = { id: i.hotel_id, value: i.hotel_name };
            arr.push(a);
          });
          setHotels(arr);
        })
        .catch((err) => {
          if (err?.response) {
            setTstContent(err?.response?.data?.error);
            setTstOpn(true);
          }
        })
    );
  };

  const handleInputDataChange = (e) => {
    let { name, value } = e.target;

    let Uservalue = { ...userValues };

    if ((name == "contact" && value.length >= 10) || (name == "hotel_contact_number" && value.length >= 10)) {
      value = value.substr(0, 10);
    }

    const fileInput = document.getElementById("select-image");

    fileInput?.addEventListener("change", () => {
      const imageFile = fileInput?.files[0];

      if (imageFile) {
        const reader = new FileReader();

        reader.readAsDataURL(imageFile);

        reader.addEventListener("load", async () => {
          const imageResult = reader.result;
          setImage(imageResult);
        });
      }
    });
    setUserValues({ ...Uservalue, image, [name]: value });
  };

  const validateForm = async () => {
    let stateValue = userValues;
    let newerrors = userErrors;
    console.log(stateValue);
    if (!stateValue.role_type && stateValue.role_type.length <= 0) {
      newerrors.role_type = "Role Type is Required";
    } else {
      newerrors.role_type = "";
    }
    if (!stateValue.hotel_name && stateValue.hotel_name.length <= 0) {
      newerrors.hotel_name = "Hotel Name is Required";
    } else {
      newerrors.hotel_name = "";
    }
    if (!stateValue.hotel_address && stateValue.hotel_address.length <= 0 && userValues?.role_type === 1) {
      newerrors.hotel_address = "Hotel Address is Required";
    } else {
      newerrors.hotel_address = "";
    }
    if (!stateValue.hotel_email && stateValue.hotel_email.length <= 0 && userValues?.role_type === 1) {
      newerrors.hotel_email = "Hotel Email is Required";
    } else {
      newerrors.hotel_email = "";
    }

    if (!stateValue.hotel_land_line_number && stateValue.hotel_land_line_number.length <= 0 && userValues?.role_type === 1) {
      newerrors.hotel_land_line_number = "Hotel Land Line Number is Required";
    } else {
      newerrors.hotel_land_line_number = "";
    }

    if (!stateValue.hotel_contact_number && stateValue.hotel_contact_number.length <= 0 && userValues?.role_type === 1) {
      newerrors.hotel_contact_number = "Hotel Contact Number is Required";
    } else {
      newerrors.hotel_contact_number = "";
    }

    if (!stateValue.username && stateValue.username.length <= 0) {
      newerrors.username = "Login Name is Required";
    } else {
      newerrors.username = "";
    }
    if (!stateValue.name && stateValue.name.length <= 0) {
      newerrors.name = "User Name is Required";
    } else {
      newerrors.name = "";
    }
    if (!stateValue.contact && stateValue.contact.length <= 0) {
      newerrors.contact = "User Contact is Required";
    } else {
      newerrors.contact = "";
    }
    if (!stateValue.user_email && stateValue.user_email.length <= 0) {
      newerrors.user_email = "User Email is Required";
    } else {
      newerrors.user_email = "";
    }

    if (!stateValue.password && stateValue.password.length <= 0) {
      newerrors.password = "Password is Required";
    } else {
      newerrors.password = "";
    }
    if (stateValue.role_type == 1 && !image) {
      newerrors.image = "Image is Required";
    } else {
      newerrors.image = "";
    }

    setUserErrors({ ...newerrors });

    for (let key in newerrors) {
      if (newerrors[key] != "") {
        setTstContent(newerrors[key]);
        setTstOpn(true);
        return;
      }
    }
    console.log("stateValue", stateValue);
    await trackPromise(
      registerUserDetailsData(stateValue)
        .then((res) => {
          let { data } = res?.data;
          setTstContent(data);
          setTstOpn(true);

          setUserErrors(initialErrors);
          setUserValues(intialValues);

          setTimeout(() => {
            setTstContent(res?.data);
          }, 3000);

          console.log("res.data===>", res);
          setTstOpn(true);
          navigate("/");
        })
        .catch((err) => {
          if (err?.response) {
            setTstContent(err?.response?.data?.error);
            setTstOpn(true);
          }
        })
    );
  };

  const handleSubmitForm = () => {
    validateForm();
  };

  const paperStyle = {
    margin: "10% auto 0% auto",
    width: 300,
    background: "rgba(0,0,0,0.42)",
    padding: "50px 35px",
  };

  return (
    <>
      <>
        {isLogin && (
          <Box>
            <img
              style={{ width: "25vw", marginTop: "5%", marginLeft: "5%" }}
              src="/images/img_sheraton_grande_new_logo.png"
            />
            <Grid container spacing={2}>
              <Paper elevation={3} style={paperStyle}>
                <Grid align="center">
                  <Typography
                    varient="h2"
                    sx={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "22px",
                      marginBottom: 5,
                    }}
                  >
                    Login to Register
                  </Typography>
                </Grid>

                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <CustomTextField
                    placeholder="Username"
                    border="1px solid #707070"
                    borderRadius="10px"
                    height="0px"
                    name="loginUsername"
                    type="text"
                    register={register}
                  />

                  <p>
                    <ErrorField label={errors?.loginUsername?.message} />
                  </p>

                  <CustomTextField
                    placeholder="Password"
                    border="1px solid #707070"
                    borderRadius="10px"
                    height="0px"
                    name="loginPassword"
                    type="password"
                    register={register}
                  />
                  <p>
                    <ErrorField label={errors?.loginPassword?.message} />
                  </p>

                  <Stack
                    sx={{ marginTop: "20px" }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  ></Stack>

                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      marginTop: "20px",
                      fontWeight: "600",
                      backgroundColor: `${CLR_ORANGE}`,
                      "&:hover": {
                        backgroundColor: `${CLR_LIGHT_WHITE}`,
                        color: `${CLR_ORANGE}`,
                        fontWeight: "600",
                      },
                    }}
                    fullWidth
                  >
                    <Link href="#"></Link>
                    Submit
                  </Button>
                </form>
              </Paper>
            </Grid>
            <CustomSnackBar
              open={tstOpn}
              setOpen={setTstOpn}
              tstContent={tstContent}
            />
          </Box>
        )}
      </>
      <>
        {isRegister && (
          <Box id={styles.mainbox}>
            <Box style={{ paddingTop: "12px" }}>
              <img id={styles.logo} src={image} />
            </Box>
            <Grid
              container
              spacing={3}
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
            >
              <Grid item lg={4} md={4} sm={4}></Grid>
              <Grid item lg={5} md={5} sm={5}>
                <Box padding={2}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bolder",
                      color: "#E98D12",
                      textAlign: "center",
                    }}
                  >
                    Registration
                  </Typography>
                </Box>
                <Box>
                  {/* <CustomLabel label="Role *" /> */}
                  <CustomSelect
                    placeholder="Role Type *"
                    style={{ position: "relative" }}
                    id="role"
                    border="1px solid #707070"
                    borderRadius="5px"
                    value={userValues?.role_type}
                    name="role_type"
                    padding="5px 12px 4px 12px"
                    marginRight="5px"
                    muiOptions={roles}
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
                {userValues.role_type == 2 && (
                  <Box>
                    <CustomLabel label="Hotel Name *" />
                    <CustomSelect
                      placeholder="Select Hotel"
                      style={{ position: "relative" }}
                      id="hotel"
                      border="1px solid #707070"
                      borderRadius="5px"
                      value={userValues.hotel_name}
                      name="hotel_name"
                      padding="5px 12px 4px 12px"
                      marginRight="5px"
                      muiOptions={hotels}
                      handleChange={(e) => {
                        handleInputDataChange(e);
                      }}
                    />
                  </Box>
                )}
                {userValues?.role_type == 1 && (
                  <Box>
                    <CustomLabel label="Hotel Name *" />
                    <TextField
                      placeholder="Hotel Name"
                      value={userValues?.hotel_name}
                      border="1px solid #707070"
                      borderRadius="5px"
                      height="0px"
                      name="hotel_name"
                      handleChange={(e) => {
                        handleInputDataChange(e);
                      }}
                    />
                  </Box>
                )}
                {userValues?.role_type == 1 && (
                  <Box>
                    <CustomLabel label="Hotel Address *" />
                    <TextField
                      placeholder="Hotel Address"
                      value={userValues?.hotel_address}
                      border="1px solid #707070"
                      borderRadius="5px"
                      height="0px"
                      name="hotel_address"
                      handleChange={(e) => {
                        handleInputDataChange(e);
                      }}
                    />
                  </Box>
                )}
                {userValues?.role_type == 1 && (
                  <Box>
                    <CustomLabel label="Hotel Email *" />
                    <TextField
                      placeholder="Hotel Email"
                      value={userValues?.hotel_email}
                      border="1px solid #707070"
                      borderRadius="5px"
                      height="0px"
                      name="hotel_email"
                      handleChange={(e) => {
                        handleInputDataChange(e);
                      }}
                    />
                  </Box>
                )}
                {
                  userValues?.role_type == 1 && (
                    <Box>
                      <CustomLabel label="Hotel Land Line Number *" />

                      <TextField
                        placeholder="Hotel Land Line Number"
                        value={userValues?.hotel_land_line_number}
                        border="1px solid #707070"
                        borderRadius="5px"
                        type="text"
                        height="0px"
                        name="hotel_land_line_number"
                        handleChange={(e) => {
                          handleInputDataChange(e);
                        }}
                      />
                    </Box>
                  )
                }
                {
                  userValues?.role_type == 1 && (
                    <Box>
                      <CustomLabel label="Hotel Contact Number *" />

                      <TextField
                        placeholder="Hotel Contact Number"
                        value={userValues?.hotel_contact_number}
                        border="1px solid #707070"
                        borderRadius="5px"
                        type="number"
                        height="0px"
                        name="hotel_contact_number"
                        handleChange={(e) => {
                          handleInputDataChange(e);
                        }}
                      />
                    </Box>
                  )
                }
                <Box>
                  <CustomLabel label="User Name *" />
                  <TextField
                    placeholder="name"
                    value={userValues?.name}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    name="name"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
                <Box>
                  <CustomLabel label="User Email *" />
                  <TextField
                    placeholder="user email"
                    value={userValues?.user_email}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    name="user_email"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>

                <Box>
                  <CustomLabel label="User Contact Number *" />

                  <TextField
                    placeholder="Mobile"
                    value={userValues?.contact}
                    border="1px solid #707070"
                    borderRadius="5px"
                    type="number"
                    height="0px"
                    name="contact"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
                {userValues?.role_type == 1 && (
                  <Box>
                    <CustomLabel label="Hotel Logo *" />
                    <input
                      type="file"
                      style={{
                        placeholder: "",
                        border: "none",
                        background: "#FFFFFF",
                        borderRadius: "5px",
                        borderColor: CLR_ORANGE,
                        height: "30px",
                        width: "100%",
                      }}
                      id="select-image"
                      handleChange={() => {
                        handleInputDataChange();
                      }}
                    />
                  </Box>
                )}

                <Box>
                  <CustomLabel label="Login Name *" />

                  <TextField
                    placeholder="user name"
                    value={userValues?.username}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    name="username"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
                <Box>
                  <CustomLabel label="Password *" />

                  <TextField
                    placeholder="password"
                    value={userValues?.password}
                    border="1px solid #707070"
                    borderRadius="5px"
                    height="0px"
                    name="password"
                    handleChange={(e) => {
                      handleInputDataChange(e);
                    }}
                  />
                </Box>
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  spacing={2}
                  sx={{
                    paddingBottom: "25px",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      background: CLR_ORANGE,
                      borderRadius: "10px",
                      width: "110px",
                      shadowColor: "1px 1px 6px #E98D12",
                      "&:hover": {
                        color: CLR_ORANGE,
                        background: CLR_LIGHT_WHITE,
                      },
                    }}
                    onClick={() => {
                      handleSubmitForm();
                    }}
                  >
                    Register
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <CustomSnackBar
              open={tstOpn}
              setOpen={setTstOpn}
              tstContent={tstContent}
            />
          </Box>
        )}
      </>
    </>
  );
}

export default Register;
