import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styles from "./guestinfo.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CustomLabel from "../../components/CustomLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import autoTable from "jspdf-autotable";
import {
  AllCells,
  ArrivalCells,
  DraftCells,
  DeletedCells,
  DepartureCells,
  DocumentCells,
  AdminCells,
  BlackListCells,
} from "../../utils/models/tablemodels";
import AllTable from "../../components/Tables/AllTable";
import DeleteTableDataDlg from "../../components/Diologues/DeleteTableDataDlg";
import Appbar from "../../components/AppBar/Appbar";
import {
  CLR_DARK_GREY,
  CLR_LIGHT_ORANGE,
  CLR_LIGHT_WHITE,
  CLR_ORANGE,
} from "../../utils/colors";
import jsPDF from "jspdf";
import ErrorField from "../../components/ErrorField";
import { trackPromise } from "react-promise-tracker";
import staticTexts from "../../utils/static-texts";
import {
  activateBookingData,
  getMonthsHistory,
  getYearsHistory,
} from "../../services/bookingServices";
import { addReportData } from "../../services/utilsServices";
import CustomSnackBar from "../../components/CustomSnackBar";
import RoomShiftDialog from "../../components/RoomShift/RoomShiftDlgbox";
import moment from "moment";
import ResultDiologueBox from "../../components/ResultDiologueBox";

function GuestInfo() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [deleteData, setDeleteData] = useState([]);
  const [searchError, setSearchError] = useState(false);
  const [dltDlgOpen, setDltDlgOpen] = useState(false);
  const searchComponent = useRef(null);
  const getVal = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [userData, setUserData] = useState(null);
  const [tblIndex, setTblIndex] = useState(0);
  const [prtIcnFlag, setPrtIcnFlg] = useState(false);
  const [dldIcnFlg, setDldIcnFlg] = useState(false);
  const [dltIcnFlg, setDltIcnFlg] = useState(true);
  const [fltIndex, setFltIndex] = useState(-1);
  const [tabs, setTabs] = useState([]);
  const [flterFlg, setFlterFlg] = useState(false);
  const [toggleVal, setToggleVal] = useState(false);
  const [filterDates, setFilterDates] = useState([]);
  const [posArray, setPosArray] = useState([]);
  const [currentSelected, setCurrentSelected] = useState([]);
  const [currentTbl, setCurrentTbl] = useState(null);
  const [checked, setChecked] = useState(false);

  const [rmShiftDlgOpen, setRmShiftDlgOpen] = useState(false);
  const [getInfo, setGetInfo] = useState(false);

  const [resDlgCnt, setResDlgCnt] = useState(false);
  const [resDlgOpn, setResDlgOpn] = useState(false);

  const [yearFilters, setYearFilters] = useState([]);
  const [monthFilters, setMonthFilters] = useState([]);
  const [monthDate, setMonthDate] = useState([]);
  const [year, setYear] = useState();
  const [yearIndex, setYearIndex] = useState();
  const [isDeleted, setIsdeleted] = useState(false);
  const [hotelName, setHotelName] = useState('');
  let serialIndex = 1;
  const [filterValue, setFilterValue] = useState({
    from_date: new Date(),
    to_date: new Date(),
    table: "",
    from_time: new Date(new Date().setHours(0, 0, 0, 0)),
    to_time: new Date().setHours(23, 59, 59, 999),
  });

  const admin_tabs = [
    { id: 1, value: "Arrival", heads: ArrivalCells },
    {
      id: 2,
      value: "Departure",
      heads: DepartureCells,
    },
    {
      id: 4,
      value: "Drafts",
      heads: DraftCells,
    },
    {
      id: 0,
      value: "All",
      heads: AllCells,
    },
    {
      id: 5,
      value: "Document",
      heads: DocumentCells,
    },
    {
      id: 7,
      value: "BlackList",
      heads: BlackListCells,
    },
    {
      id: 6,
      value: "Admin",
      heads: AdminCells,
    },
    {
      id: 3,
      value: "Deleted",
      heads: DeletedCells,
    },
  ];

  const concierge_tabs = [
    { id: 1, value: "Arrival", heads: ArrivalCells },
    {
      id: 2,
      value: "Departure",
      heads: DepartureCells,
    },

    {
      id: 3,
      value: "Drafts",
      heads: DraftCells,
    },
    {
      id: 0,
      value: "All",
      heads: AllCells,
    },
    {
      id: 4,
      value: "Document",
      heads: DocumentCells,
    },
    {
      id: 7,
      value: "BlackList",
      heads: BlackListCells,
    },

  ];

  const getAllYears = async () => {
    setMonthDate([]);
    setYearIndex();
    setFltIndex();
    setYearFilters([]);
    setMonthFilters([]);
    await trackPromise(
      getYearsHistory()
        .then((res) => {
          setYearFilters(res.data.data);
        })
        .catch((err) => {
          // console.log("YearsErr", err);
        })
    );
  };

  const handleYearChange = async (data, index) => {
    setMonthDate([]);
    setYearIndex(index);
    trackPromise(
      getMonthsHistory({ year: data.Year })
        .then((res) => {
          setYear(data.Year);
          setMonthFilters(res.data.data);
        })
        .catch((err) => {
          // console.log("MonthsErr", err);
        })
    );
  };

  const handleMonth = (month, index) => {
    setFltIndex(index);
    setMonthDate([{ month, year }]);
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    let hotel_name = data?.hotel_name?.split(' ')?.reduce((n, w) => {
      n += w.charAt(0);
      return n;
    }, '').toUpperCase();
    if (Number(data?.hotelId) == 5) {
      setHotelName('HP');
    } else if (Number(data?.hotelId) == 7) {
      setHotelName('BI');
    } else {
      setHotelName(hotel_name || '');
    }
    if (data?.role == 1) {
      setTabs(admin_tabs);
      setDltIcnFlg(false);
    } else {
      setTabs(concierge_tabs);
      setDltIcnFlg(false);
    }
    setPrtIcnFlg(true);
    setUserData(data);
  }, []);
  const [finalFltObj, setFinalFltObj] = useState({
    from_date: "",
    table: "",
    to_date: "",
    day: "",
  });

  const handleActivateData = async () => {
    let booking_lines = [];
    let roomArr = [];
    if (deleteData && deleteData?.length > 0) {
      deleteData?.map((item, index) => {
        booking_lines.push({
          booking_id: item?.booking_id,
          room_no: item?.room_no,
          room_type_id: item?.room_type_id,
          serial_number: item?.serial_number,
          room_line_id: item?.room_line_id,
        });
      });

      await trackPromise(
        activateBookingData(booking_lines)
          .then((res) => {
            setToggleVal(!toggleVal);
            setDeleteData([]);
            setChecked(false);
            setResDlgCnt("Restored Successfully !");
            setResDlgOpn(true);
            setTimeout(() => {
              setResDlgOpn(false);
            }, 2000);
          })
          .catch((err) => {
            setTstContent(err.response.data.error);
            setTstOpn(true);
            setChecked(false);
          })
      );
    } else {
      setTstOpn(true);
      setTstContent("Please select atleast one item");
    }
  };

  const handleFilterChange = (data, index) => {
    setFlterFlg(false);
    setFltIndex(index);
    let obj = {
      from_date: "",
      to_date: "",
      table: "",
      pos: "",
      day: "",
    };
    let initFilter = [...filterDates];
    if (data.id === 10) {
      setAnchorEl(true);
    } else {
      setAnchorEl(null);
    }
    obj.pos = index;
    if (data.value === "Today") {
      let currDate = new Date();
      obj.from_date = new Date(currDate.setUTCHours(0, 0, 0, 0));
      obj.to_date = new Date(currDate.setUTCHours(23, 59, 59, 999));
      obj.day = data.value;
    } else if (data.value === "Yesterday") {
      let currDate = new Date();
      currDate.setDate(currDate.getDate() - 1).toLocaleString();
      obj.from_date = new Date(currDate.setUTCHours(0, 0, 0, 0));

      obj.to_date = new Date(currDate.setUTCHours(23, 59, 59, 999));
      obj.day = data.value;
    } else if (data.value === "This week") {
      // let currDate = new Date();

      // currDate.setDate(currDate.getDate() - 7).toLocaleString();
      // obj.from_date = new Date(currDate.setHours(0, 0, 0, 0));
      // currDate = new Date();
      // obj.to_date = new Date(currDate.setHours(23, 59, 59, 999));
      // obj.day = data.value
      // console.log("FROM DATE IS",obj.from_date)
      // console.log("TO DATE IS",obj.to_date)
      let now = new Date();
      let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let lastSunday = new Date(
        today.setDate(today.getDate() - today.getDay())
      );
      obj.from_date = new Date(lastSunday.setUTCHours(0, 0, 0, 0));
      obj.to_date = new Date(now.setUTCHours(23, 59, 59, 999));
      obj.day = data.value;
    } else if (data.value === "Last Week") {
      let currDate = new Date();
      currDate.setDate(currDate.getDate() - 14).toLocaleString();
      obj.from_date = new Date(currDate.setUTCHours(0, 0, 0, 0));
      currDate = new Date();
      currDate.setDate(currDate.getDate() - 7).toLocaleString();
      obj.to_date = new Date(currDate.setUTCHours(23, 59, 59, 999));
      obj.day = data.value;

      //       let now = new Date();
      // let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // let lastSunday = new Date(today.setDate(today.getDate()-today.getDay()));
      // lastSunday = new Date(lastSunday.setDate(lastSunday.getDate() - 7).setHours(23,59,59,999))
      // // console.log("from date",new Date(today.setHours(0,0,0,0)));
      // let lastSat = new Date(today.setDate(today.getDate()-today.getDay()));
      // lastSat = new Date(lastSat.setDate(lastSat.getDate() - 1).setHours(0,0,0,0))
      // console.log("from date", (lastSat))
      // console.log("to date",(lastSunday));
    } else if (data.value === "Last Month") {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
      obj.from_date = firstDay;
      obj.to_date = new Date(lastDay.setUTCHours(23, 59, 59, 999));
      obj.day = data.value;
    } else if (data.value === "Custom") {
    }

    let currentIndex = initFilter.findIndex(
      (x) => x.from_date.getTime() == obj.from_date.getTime()
    );

    if (currentIndex != -1) {
      initFilter.splice(currentIndex, 1);
    } else {
      initFilter.push(obj);
    }

    let customIndex = initFilter.findIndex((x) => x.index == 5);
    if (customIndex != -1) {
      initFilter.splice(customIndex, 1);
    }
    let newIndexArray = [...posArray];
    let currentPosIndex = newIndexArray.findIndex((x) => x == index);

    if (currentPosIndex != -1) {
      newIndexArray.splice(currentPosIndex, 1);
    } else {
      newIndexArray.push(index);
    }
    setPosArray(newIndexArray);

    setFilterDates(initFilter);

    setFinalFltObj({
      ...finalFltObj,
      from_date: obj.from_date,
      to_date: obj.to_date,
      day: obj.day,
    });

    setToggleVal(!toggleVal);
  };

  const filterColoumns = [
    { id: 0, value: "Today" },
    { id: 1, value: "Yesterday" },
    { id: 2, value: "This week" },
    { id: 3, value: "Last Week" },
    { id: 4, value: "Last Month" },
    { id: 5, value: "Custom" },
  ];

  const handleChange = (event, index) => {
    setValue(index);
    setTblIndex(index);

    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    if (index == 3 && data.role == 1) {
      setPrtIcnFlg(true);
      setDldIcnFlg(false);
      setDltIcnFlg(false);
    } else if (index === 3) {
      setPrtIcnFlg(true);
      setDltIcnFlg(false);
    } else if (index === 0) {
      setPrtIcnFlg(true);
      setDltIcnFlg(false);
    } else if (index === 1) {
      setPrtIcnFlg(true);
      setDltIcnFlg(false);
    } else if (index === 7) {
      setPrtIcnFlg(false);
      setDltIcnFlg(false);
      getAllYears();
    } else if (index === 2) {
      setPrtIcnFlg(false);
      setDltIcnFlg(false);
    } else if (index === 6) {
      setPrtIcnFlg(true);
      setDldIcnFlg(false);
      setDltIcnFlg(false);
      getAllYears();
    } else {
      setPrtIcnFlg(false);
      setDldIcnFlg(false);
      setDltIcnFlg(false);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPosArray([]);
    setFltIndex(5);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterValue({
      from_date: "",
      to_date: "",
      table: "",
      from_time: "",
      to_time: "",
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [filterTimeError, setFilterTimeError] = useState(null);
  const [filterDateError, setFilterDateError] = useState(null);
  const handleDateChange = (value) => {
    setFilterValue({ ...filterValue, from_date: value });
  };
  const handleToDateChange = (value) => {
    let from_date = filterValue.from_date
      ? filterValue.from_date.setHours(0, 0, 0, 0)
      : filterValue.from_date;
    let val = value ? value.setHours(23, 59, 0, 0) : value;
    setFilterValue({ ...filterValue, to_date: value });
    if (val >= from_date) {
      setFilterDateError(null);
    } else {
      setFilterDateError("To Date should be greater than From Date");
    }
  };
  const handleFromTimeChange = (value) => {
    setFilterValue({ ...filterValue, from_time: value });
  };
  const handleToTimeChange = (value) => {
    if (value >= filterValue.from_date) {
      setFilterValue({ ...filterValue, to_time: value });
      // setSrchEnable(false)
      // setSrchTimeEnable(true)
      setFilterTimeError(null);
    } else {
      setFilterTimeError("To Time should be greater than from Time");
      // setSrchEnable(true)
      // setSrchTimeEnable(false)
    }
  };

  const handleClickPrint = () => {
    if (tableData && tableData.length > 0) {
      addReportData({ report_type: currentTbl });
      onPrint();
    } else {
      setTstContent("No data available to print");
      setTstOpn(true);
    }
  };

  const handleSearchGuest = () => {
    if (searchComponent.length <= 5) {
      setSearchError(false);
    } else {
      setSearchError(true);
    }
  };

  const addRows = (typeIndex) => {
    let trs = "";

    if (typeIndex == 1) {
      tableData.sort(
        (a, b) =>
          new Date(a.checkout_date).getTime() -
          new Date(b.checkout_date).getTime()
      );
    } else {
      tableData.sort(
        (a, b) =>
          new Date(a.checkin_date).getTime() -
          new Date(b.checkin_date).getTime()
      );
    }
    if (typeIndex == 6) {
      tableData.length > 0 &&
        tableData.forEach((elem, index) => {
          trs += `
          <tr>
          <td style="border: 1px solid #dddddd; text-align: left;font-weight:bold; padding: 8px; width:100px; page-break-inside:avoid; page-break-after:auto">
          ${elem.date}
         </td>
         </tr>
          `;
          elem.records.forEach((element, index) => {
            trs += `
          <tr>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${moment(elem.date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD") ?

                `${moment(elem?.date).format("MMMYY").toUpperCase()}/${hotelName}${element?.formatted_serial?.split('-')[1]?.split('/')[1]}`

                :
                Number(userData.hotelId) === 4
                  ? `${moment(elem.date).format("MMMYY").toUpperCase()}/${hotelName}${serialIndex++}`
                  : `${moment(elem.date).format("DD-MMM").toUpperCase()}-${(index + 1)}`
              }
         </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px; page-break-inside:avoid; page-break-after:auto">
           ${element?.room_no}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px; page-break-inside:avoid; page-break-after:auto">
          ${element?.name}
          </td>
          <td style="border: 1px solid #dddddd;  text-align: center; padding: 8px; page-break-inside:avoid; page-break-after:auto">
          ${element?.mobile}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px">
          ${element?.address},
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">
          ${element?.booking_source}
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">
          ${element?.checkout_date != ""
                ? moment(element?.checkout_date).format("DD-MM-YYYY")
                : "--"
              }
          </td>  
        </tr>`;
          });
        });
    } else {
      tableData.length > 0 &&
        tableData.forEach((element, index) => {
          let dateTime = new Date(element.checkin_date);
          let dateTime1 = new Date(element.checkout_date);
          dateTime = dateTime.toLocaleString().slice(11);
          dateTime1 = dateTime1.toLocaleString().slice(11);
          if (typeIndex == 0) {
            trs += `
          <tr>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">

          ${(element?.checkin_date && moment(element?.checkin_date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD")) ?
                `${element?.arrival_serial?.split('/')[0]}/${hotelName}${element?.arrival_serial?.split('/')[1]}` :
                element?.serial_number
              }
         </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
           ${element?.room_no}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.name}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element.mobile ? element?.mobile : ""}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.occupation}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.address}, ${element?.country_name}, ${element?.state_name
              }, ${element?.city_name}.
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.no_of_persons}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.purpose_of_visit}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
    
          ${moment(element?.checkin_date).format("DD-MM-YYYY")}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${moment(element?.checkin_date).format("hh:mm:ss A")}
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 5px">
          <img src =${element.arrival_signature_image_url
              } style = "width : 80px; height : 80px;"/>
          </td>    
        </tr>`;
          } else {
            trs += `
          <tr>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">

              ${(tblIndex === 2 ?
                (element?.checkin_date && moment(element.checkin_date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD"))
                :
                (!!element?.checkin_date && moment(element[tblIndex === 1 ? 'checkout_date' : 'checkin_date']).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD"))) ?
                (tblIndex === 2 ?
                  `${element.arrival_serial?.split('/')[0]}/${hotelName}${element?.arrival_serial?.split('/')[1]}`
                  :
                  (tblIndex == 0 || tblIndex == 3) ? `${element.arrival_serial?.split('/')[0]}/${hotelName}${element.arrival_serial?.split('/')[1]}` : `${element.departure_serial?.split('/')[0]}/${hotelName}${element.departure_serial?.split('/')[1]}`
                )
                :
                element.serial_number
              }
         </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
           ${element?.room_no}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.name}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.mobile}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px">
          ${element?.address}, ${element?.country_name}, ${element?.state_name
              }, ${element?.city_name}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;page-break-inside:avoid; page-break-after:auto">
          ${element?.occupation}
          </td>
          
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px">
          ${element?.no_of_persons}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px">
          ${element?.purpose_of_visit}
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">

          ${element?.heading_to == null ? "---" : element?.heading_to}
          </td>
          <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">
          ${new Date(element?.checkin_date).toISOString().slice(0, 10) ===
                "1970-01-01"
                ? "---"
                : moment(element?.checkin_date).format("DD-MM-YYYY")
              }
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">
          ${new Date(element?.checkin_date).toISOString().slice(0, 10) ===
                "1970-01-01"
                ? "---"
                : moment(element?.checkin_date).format("hh:mm:ss A")
              }
          </td>
          
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">
          ${new Date(element?.checkout_date).toISOString().slice(0, 10) ===
                "1970-01-01"
                ? "---"
                : moment(element?.checkout_date).format("DD-MM-YYYY")
              }
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 8px">
          ${new Date(element?.checkout_date).toISOString().slice(0, 10) ===
                "1970-01-01"
                ? "---"
                : moment(element?.checkout_date).format("hh:mm:ss A")
              }
          </td>
          <td style="border: 1px solid #dddddd; text-align: center; padding: 5px">
          <img src =${element.departure_signature_image_url == null
                ? element.arrival_signature_image_url
                : element.departure_signature_image_url
              } alt="No Image" style = "width : 80px; height : 80px;"/>
          </td>
        </tr>`;
          }
        });
    }
    return trs;
  };
  function myFunction() {
    Window.close();
  }

  const onPrint = async () => {
    var printContent;
    if (tblIndex == 0) {
      let dayDate = new Date().toLocaleDateString();
      if (finalFltObj.day == "Today") {
        dayDate = new Date().toLocaleDateString();
      } else if (finalFltObj.day == "Yesterday") {
        let yesterday = new Date(Date.now() - 864e5);
        let d = new Date();
        dayDate = yesterday.toLocaleDateString();
      } else if (finalFltObj.day == "This week") {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let lastSunday = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        dayDate = `${lastSunday.toLocaleDateString()} - ${now.toLocaleDateString()}`;
      } else if (finalFltObj.day == "Last Week") {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let lastSunday = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        lastSunday = lastSunday.setDate(lastSunday.getDate() - 7);
        let lalastSunday = lastSunday;
        lalastSunday = new Date(lalastSunday).toLocaleDateString();
        let lastSat = new Date(today.setDate(today.getDate() - today.getDay()));
        lastSat = lastSat.setDate(lastSat.getDate() - 1);
        lastSat = new Date(lastSat).toLocaleDateString();
        dayDate = `${lalastSunday} - ${lastSat}`;

        // dayDate = `${lastSunday.toLocaleDateString()} - ${now.toLocaleDateString()}`
      }

      printContent = `<div style = "position : absolute; right : 20px; cursor : pointer" onclick = window.close()>[X]</div>
      <div style = "position : absolute; left : 10px; top : 145px" onclick = window.close()> Date : ${dayDate}</div>
      <div style="width: 100%; height: 150px">
        <div>
        <div style = "display : flex;align-item: center; justify-content:center;">
              <div style = "display : flex; flex-direction : column ; visibility : hidden">
               <span style = "margin-bottom : 10px"> Date : .................</span>
               <span>  Day : .................</span>
              </div>
              <div style="display: flex; flex-direction: column; margin-top : -5px">
              <div style="display: flex; margin-top : -30px;">
              <div style="display:flex;flex-direction:column;align-items: center;">
                  <div style="display: flex;">
                      <span
                          style=" letter-spacing: 1px;margin-top: 32px;font-size: 30px;font-weight: 600;">
                          ${userData.hotel_name.includes('(')
          ? userData.hotel_name.split('(')[0]
          : userData.hotel_name
        }
                      </span>
                      <span style="margin-left : 10px; margin-top: 45px">HOTEL</span>
                  </div>

                  <span style=" letter-spacing: 1px">
                  ${userData.hotel_name.includes('(')
          ? userData.hotel_name.slice(userData.hotel_name.indexOf('('))
          : ''}  
                  </span>
                  <span style="width: 77%;text-align: center;margin-top: 5px;">${userData.hotel_address}</span>
              </div>

          </div>
              </div>
            </div>
           <div style = "display : flex; justify-content:space-between;">
           <h3>
                ARRIVAL REGISTER
            </h3>
            <div style="display: flex;flex-direction: column;">

                        <span
                            style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                            <span style="font-weight: 700;">Contact No :</span>
                            <pre style="white-space: break-spaces;width: 16.4rem;
                            ">${userData?.hotel_land_line_number || '--'}, ${userData.hotel_contact_number || '--'}
                            </pre>
                        </span>
                        <span
                            style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                            <span style="font-weight: 700;">Email :</span>
                            <pre style="white-space: break-spaces;width: 16.4rem;
                            ">${userData?.hotel_email || '--'}
                            </pre>
                        </span>
                    </div>
        </div>
      <div style="padding-top: 20px">
        
        <div style="margin-bottom: 15px; font-size: 0.9em; line-height: 22px">
          <table
          id="table"
            style="
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
              
              page-break-after:auto;
              
            "
          >
            <tr style="border-bottom: 2px solid black; border-top: 2px solid black;
            page-break-inside:avoid; page-break-after:auto
            ">
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                S.No
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Room No.
            </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Name
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Mobile
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Occupation
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Address
            </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              No of Persons
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Purpose Of Visit
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 5px ;min-width:100px!important">
                Arrival Date   
              </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Time
              </th>
              
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
              Signature
            </th>
            </tr>
           ${addRows(0)}
          </table>
        </div>
      </div>
    </div>
    </body>
    </html>
    `;
    } else if (tblIndex == 6) {
      let curYear;
      tableData.length > 0 &&
        tableData.forEach((ele, ind) => {
          curYear = moment(new Date(ele.date)).format("MMM, YYYY");
        });

      printContent = `<div style = "position : absolute; right : 20px; cursor : pointer" onclick = window.close()>[X]</div>
      <div style = "position : absolute; left : 20px; top : 130px" onclick = window.close()> Month : ${curYear}</div>
      <div style="width: 100%; height: 150px">
        <div >
        <div style = "display : flex;align-item: center; justify-content:center;">
        <div style = "display : flex; flex-direction : column ; visibility : hidden">
         <span style = "margin-bottom : 10px"> Date : .................</span>
         <span>  Day : .................</span>
        </div>
        <div style="display: flex; flex-direction: column; margin-top : -5px">
        <div style="display: flex; margin-top : -30px;">
        <div style="display:flex;flex-direction:column;align-items: center;">
            <div style="display: flex;">
                <span
                    style=" letter-spacing: 1px;margin-top: 32px;font-size: 30px;font-weight: 600;">
                    ${userData.hotel_name.includes('(')
          ? userData.hotel_name.split('(')[0]
          : userData.hotel_name
        }
                </span>
                <span style="margin-left : 10px; margin-top: 45px">HOTEL</span>
            </div>

            <span style=" letter-spacing: 1px">
            ${userData.hotel_name.includes('(')
          ? userData.hotel_name.slice(userData.hotel_name.indexOf('('))
          : ''}  
            </span>
            <span style="width: 77%;text-align: center;margin-top: 5px;">${userData.hotel_address}</span>
        </div>

    </div>
        </div>
      </div>
     <div style = "display : flex; justify-content:space-between;">
     <h3>
     MONTHWISE RECORD
      </h3>
      <div style="display: flex;flex-direction: column;">

                  <span
                      style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                      <span style="font-weight: 700;">Contact No :</span>
                      <pre style="white-space: break-spaces;width: 16.4rem;
                      ">${userData?.hotel_land_line_number || '--'}, ${userData.hotel_contact_number || '--'}
                      </pre>
                  </span>
                  <span
                      style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                      <span style="font-weight: 700;">Email :</span>
                      <pre style="white-space: break-spaces;width: 16.4rem;
                      ">${userData?.hotel_email || '--'}
                      </pre>
                  </span>
              </div>
  </div>
      <div style="padding-top: 20px">
        <div style="margin-bottom: 15px; font-size: 0.9em; line-height: 22px">
          <table
          id="table"
            style="
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
              page-break-after:auto;">
            <tr style="border-bottom: 2px solid black; border-top: 2px solid black;
            page-break-inside:avoid; page-break-after:auto
            ">
              <th style="border: 1px solid #dddddd; padding: 8px">
                S.No
              </th>
              <th style="border: 1px solid #dddddd; padding: 8px">
              Room No
              </th>
              <th style="border: 1px solid #dddddd; padding: 8px">
                Name
              </th>
              <th style="border: 1px solid #dddddd; padding: 8px">
                Mobile
              </th>
              <th style="border: 1px solid #dddddd; padding: 8px">
              Address
              </th>
              <th style="border: 1px solid #dddddd; padding: 8px">
              Booking Source
              </th>
              <th style="border: 1px solid #dddddd; padding: 5px ;min-width:100px!important">
                Departure Date   
              </th>
            </tr>
           ${addRows(6)}
          </table>
        </div>
      </div>
    </div>
    </body>
    </html>
    `;
    } else {
      let dayDate = new Date().toLocaleDateString();
      if (finalFltObj.day == "Today") {
        dayDate = new Date().toLocaleDateString();
      } else if (finalFltObj.day == "Yesterday") {
        let yesterday = new Date(Date.now() - 864e5);
        let d = new Date();
        dayDate = yesterday.toLocaleDateString();
      } else if (finalFltObj.day == "This week") {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let lastSunday = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        dayDate = `${lastSunday.toLocaleDateString()} - ${now.toLocaleDateString()}`;
      } else if (finalFltObj.day == "Last Week") {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let lastSunday = new Date(
          today.setDate(today.getDate() - today.getDay())
        );
        lastSunday = lastSunday.setDate(lastSunday.getDate() - 7);
        let lalastSunday = lastSunday;
        lalastSunday = new Date(lalastSunday).toLocaleDateString();
        let lastSat = new Date(today.setDate(today.getDate() - today.getDay()));
        lastSat = lastSat.setDate(lastSat.getDate() - 1);
        lastSat = new Date(lastSat).toLocaleDateString();
        dayDate = `${lalastSunday} - ${lastSat}`;
      }
      printContent = `
      <div style = "position : absolute; right : 20px; cursor : pointer" onclick = window.close()>[X]</div>
      <div style = "position : absolute; left : 20px; top : 130px" onclick = window.close()> Date : ${dayDate}</div>
      <div style="width: 100%; height: 150px">
      <div >
      <div style = "display : flex;align-item: center; justify-content:center;">
              <div style = "display : flex; flex-direction : column ; visibility : hidden">
               <span style = "margin-bottom : 10px"> Date : .................</span>
               <span>  Day : .................</span>
              </div>
              <div style="display: flex; flex-direction: column; margin-top : -5px">
              <div style="display: flex; margin-top : -30px;">
              <div style="display:flex;flex-direction:column;align-items: center;">
                  <div style="display: flex;">
                      <span
                          style=" letter-spacing: 1px;margin-top: 32px;font-size: 30px;font-weight: 600;">
                          ${userData.hotel_name.includes('(')
          ? userData.hotel_name.split('(')[0]
          : userData.hotel_name
        }
                      </span>
                      <span style="margin-left : 10px; margin-top: 45px">HOTEL</span>
                  </div>

                  <span style=" letter-spacing: 1px">
                  ${userData.hotel_name.includes('(')
          ? userData.hotel_name.slice(userData.hotel_name.indexOf('('))
          : ''}  
                  </span>
                  <span style="width: 77%;text-align: center;margin-top: 5px;">${userData.hotel_address || '--'}</span>
              </div>

          </div>
              </div>
            </div>
           <div style = "display : flex; justify-content:space-between;">
           <h3>
           ${tblIndex == 3 ? "ALL REGISTER" : "DEPARTURE REGISTER"}
            </h3>
            <div style="display: flex;flex-direction: column;">

                        <span
                            style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                            <span style="font-weight: 700;">Contact No :</span>
                            <pre style="white-space: break-spaces;width: 16.4rem;
                            ">${userData?.hotel_land_line_number || '--'}, ${userData.hotel_contact_number || '--'}
                            </pre>
                        </span>
                        <span
                            style="display: flex;overflow: hidden;text-overflow: ellipsis;align-items: baseline;gap: 3px;height: 2rem;">
                            <span style="font-weight: 700;">Email :</span>
                            <pre style="white-space: break-spaces;width: 16.4rem;
                            ">${userData?.hotel_email || '--'}
                            </pre>
                        </span>
                    </div>
        </div>
    <div style="padding-top: 20px">
       
        <div style="margin-bottom: 15px; font-size: 0.9em; line-height: 22px">
          <table
          id="table"
            style="
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            "
          >
            <tr style="border-bottom: 2px solid black; border-top: 2px solid black">
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                S.No
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Room No
            </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Name
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Mobile
              </th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 8px">
              Address
            </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              Occupation
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
              No of Persons
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px">
                Purpose Of Visit
              </th>
              <th style="border: 1px solid #dddddd; text-align: center; padding: 8px">
                Heading to
              </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
                Arrival Date
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
                Arrival Time
              </th>
              <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
              Departure Date
            </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
                Departure Time
              </th>
            <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;min-width:100px!important">
              Signature
            </th>
            </tr>
           ${addRows(1)}
          </table>
        </div>
      </div>
    </div>    
    `;
    }

    const WindowPrt = window.open(
      "",
      "",
      "left=0,top=0,width=1500,height=900,toolbar=0,scrollbars=0,status=0"
    );

    WindowPrt?.document.write(printContent);
    const delay = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    await delay(1000);
    WindowPrt?.focus();
    WindowPrt?.print();
  };

  const handleDownloadData = () => {
    const doc = new jsPDF();
    doc.setLineWidth(2);
    doc.text(50, 20, "Stay Court");
    if (tblIndex == 0) {
      autoTable(doc, {
        //   columnStyles: { room_data: { halign: "center" } },
        body: tableData,
        columnStyles: {
          0: { cellWidth: 8 },
          1: { cellWidth: 14 },
          2: { cellWidth: 20 },
          3: { cellWidth: 25 },
          4: { cellWidth: 33 },
          5: { cellWidth: 13 },
          6: { cellWidth: 20 },
          7: { cellWidth: 22 },
        },
        columns: [
          // { header: "Sl No", dataKey: "sl_no" },
          { header: "Room No", dataKey: "room_no" },
          { header: "S No", dataKey: "sl_no" },
          { header: "Room No", dataKey: "room_no" },
          { header: "Name", dataKey: "name" },
          { header: "Mobile", dataKey: "mobile" },
          { header: "Address", dataKey: "download_address" },

          { header: "NOP", dataKey: "no_of_persons" },
          { header: "Purpose Of Visit", dataKey: "purpose_of_visit" },
          { header: "Arrival Date", dataKey: "checked_in" },
        ],
      });
    } else {
      autoTable(doc, {
        //   columnStyles: { room_data: { halign: "center" } },
        body: tableData,
        columnStyles: {
          0: { cellWidth: 8 },
          1: { cellWidth: 14 },
          2: { cellWidth: 20 },
          3: { cellWidth: 25 },
          4: { cellWidth: 33 },
          5: { cellWidth: 13 },
          6: { cellWidth: 20 },
          7: { cellWidth: 22 },
          8: { cellWidth: 22 },
        },
        columns: [
          { header: "Sl No", dataKey: "sl_no" },
          { header: "Room No", dataKey: "room_no" },
          { header: "Name", dataKey: "name" },
          { header: "Mobile", dataKey: "mobile" },
          { header: "Address", dataKey: "download_address" },
          { header: "NOP", dataKey: "no_of_persons" },
          { header: "Purpose Of Visit", dataKey: "purpose_of_visit" },
          { header: "Arrival Date", dataKey: "checked_in" },
          { header: "Departure Date", dataKey: "checkout_in" },
        ],
      });
    }

    doc.save(`Stay_Court_${new Date().toISOString().slice(0, 10)}`);
  };
  let data1 = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
  // if(data.role != "Admin")
  // if (index == 0 && data.role == "Admin") {
  return (
    <Box>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Box
          id={styles.mainbox}
          sx={{
            display: {
              xs: "none",
              sm: "block",
            },

            background: "rgba(0,0,0,0.42)",
          }}
          style={{
            marginTop: "62px",
            marginRight: "44px",
            marginBottom: "44px",
          }}
          flex={4}
        >
          <Appbar logo="true" />
          <Box
            id={styles.firstbox}
            style={{ marginLeft: "30px", marginBottom: "8px" }}
            onClick={(item) => {
              navigate(-1);
            }}
          >
            <img
              style={{ width: "14px", height: "24px", cursor: "pointer" }}
              src="/images/Icon_left_arrow.png"
            />
            <Typography
              sx={{
                color: "white",
                margin: "8px",
                fontweight: "700",
                fontSize: "18px",
              }}
            >
              Guest Info
            </Typography>
          </Box>

          <Box
            id={styles.firstbox}
            style={{ marginLeft: "25px", display: "none" }}
          >
            <CustomLabel style={{ paddingLeft: "18px" }} label="Search" />
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
                height: "30px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search room no or id"
                type="number"
                ref={getVal}
                inputProps={{ "aria-label": "Search room no or id" }}
                onChange={(event) => searchComponent.handleSearch(event)}
              />
              <IconButton
                type="submit"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={handleSearchGuest}
              >
                <SearchIcon style={{ color: CLR_ORANGE }} />
              </IconButton>
            </Paper>
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid
              container
              spacing={2}
              style={{
                marginRight: "15px",
                marginLeft: "15px",
                marginBottom: "15px",
              }}
            >
              <Grid item xs={12} lg={12} md={12}>
                {tblIndex === 6 || tblIndex === 7 ? (
                  <Box>
                    <Box>
                      {yearFilters?.map((data, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          id={styles.filterbutton}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleYearChange(data, index);
                          }}
                          sx={{
                            backgroundColor:
                              yearIndex == index
                                ? "#E98D12!important"
                                : "#FFFF!important",
                            color:
                              yearIndex == index
                                ? "#FFFF!important"
                                : "#E98D12!important",
                          }}
                        >
                          {data.Year}
                        </Button>
                      ))}
                    </Box>
                    <Box>
                      {monthFilters?.map((data, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          id={styles.filterbutton}
                          onClick={() => {
                            handleMonth(data.month, index);
                          }}
                          sx={{
                            backgroundColor:
                              fltIndex == index
                                ? "#E98D12!important"
                                : "#FFFF!important",
                            color:
                              fltIndex == index
                                ? "#FFFF!important"
                                : "#E98D12!important",
                          }}
                        >
                          {data.month}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <>
                    {filterColoumns?.map((data, index) => {
                      return data?.value === "Custom" ? (
                        <React.Fragment key={index}>
                          <Button
                            // variant="contained"
                            id={styles.customfilterbutton}
                            key={index}
                            onClick={handleClick}
                            sx={{
                              backgroundColor:
                                fltIndex == index ? "#E98D12!important" : "",
                              color: fltIndex == index ? "white!important" : "",
                            }}
                          >
                            {data?.value}
                          </Button>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Menu
                              anchorEl={anchorEl}
                              id="account-menu"
                              open={open}
                              onClose={handleClose}
                              // onClick={handleClose}
                              PaperProps={{
                                elevation: 0,
                                sx: {
                                  overflow: "visible",
                                  filter:
                                    "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                  mt: 1.5,
                                  "& .MuiAvatar-root": {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                  },
                                  borderRadius: "20px",
                                  color: "white",
                                  background: CLR_LIGHT_ORANGE,
                                  boxShadow: "0px 3px 6px #00000029",

                                  mixBlendMode: "screen",
                                  // mix-blend-mode: screen;
                                },
                              }}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Box>
                                <MenuItem>
                                  <CustomLabel
                                    style={{ paddingLeft: "18px" }}
                                    label="From Date"
                                  />

                                  <DesktopDatePicker
                                    inputformat={"DD/MM/YYYY"}
                                    maxDate={new Date()}
                                    value={filterValue.from_date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          width: "100%",
                                          background: "white!important",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                </MenuItem>
                                {/* <MenuItem>
                                  <CustomLabel
                                    style={{
                                      paddingLeft: "18px",
                                      paddingRight: "23px",
                                    }}
                                    label="From Time"
                                  />
                                  <TimePicker
                                    value={filterValue.from_time}
                                    onChange={handleFromTimeChange}
                                    ampm={false}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          width: "100%",
                                          background: "white!important",
                                          borderRadius: "10px",
                                          justifyContent: "flex-end",
                                        }}
                                      />
                                    )}
                                  />
                                </MenuItem> */}
                              </Box>
                              <Box>
                                <MenuItem>
                                  <Typography
                                    sx={{
                                      color: "white",
                                      margin: "12px",
                                      paddingRight: "23px",
                                    }}
                                  >
                                    To Date
                                  </Typography>

                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DesktopDatePicker
                                      minDate={
                                        filterValue.from_date
                                          ? filterValue.from_date
                                          : new Date()
                                      }
                                      inputformat={"DD/MM/YYYY"}
                                      value={filterValue.to_date}
                                      onChange={handleToDateChange}
                                      //   {...props.register(props.name)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          sx={{
                                            width: "100%",
                                            background: "white!important",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </MenuItem>

                                {/* <MenuItem>
                                  <Typography
                                    sx={{
                                      color: "white",
                                      margin: "12px",
                                      paddingRight: "23px",
                                    }}
                                  >
                                    To Time
                                  </Typography>
                                  <TimePicker
                                    value={filterValue.to_time}
                                    onChange={handleToTimeChange}
                                    ampm={false}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          width: "100%",
                                          background: "white!important",
                                          borderRadius: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                </MenuItem> */}
                                {filterDateError && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ErrorField label={filterDateError} />
                                  </Box>
                                )}
                              </Box>

                              <MenuItem id={styles.menu_item_btn}>
                                <Button
                                  sx={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    background: CLR_DARK_GREY,
                                    borderRadius: "10px",
                                    width: "110px",
                                    shadowColor: "1px 1px 6px #E98D12",
                                    "&:hover": {
                                      color: CLR_DARK_GREY,
                                      background: CLR_LIGHT_WHITE,
                                    },
                                  }}
                                  variant="contained"
                                  onClick={() => {
                                    setFilterValue({
                                      from_date: new Date(),
                                      to_date: new Date(),
                                      table: "",
                                      // from_time: new Date(
                                      //   new Date().setHours(0, 0, 0, 0)
                                      // ),
                                      // to_time: new Date().setHours(
                                      //   23,
                                      //   59,
                                      //   59,
                                      //   999
                                      // ),
                                    });

                                    setFlterFlg(false);
                                    setAnchorEl(null);
                                    setToggleVal(!toggleVal);
                                    setFltIndex(-1);
                                    setFilterDates([]);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  // disabled = {srchDateEnable && srchTimeEnable ? true : false }
                                  variant="contained"
                                  sx={{
                                    marginLeft: "10px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    background: CLR_ORANGE,
                                    borderRadius: "10px",
                                    width: "110px",
                                    shadowColor: "1px 1px 6px #E98D12",
                                    "&:hover": {
                                      color: CLR_ORANGE,
                                      background: CLR_LIGHT_WHITE,
                                    },
                                  }}
                                  onClick={() => {
                                    // let fromDate = new Date(
                                    //   filterValue.from_date.getFullYear(),
                                    //   filterValue.from_date.getMonth(),
                                    //   filterValue.from_date.getDate(),
                                    //   // filterValue.from_time?.getHours(),
                                    //   // filterValue.from_time?.getMinutes(),
                                    //   // filterValue.from_time.getSeconds()
                                    // );
                                    // let toDate = new Date(
                                    //   filterValue.to_date.getFullYear(),
                                    //   filterValue.to_date.getMonth(),
                                    //   filterValue.to_date.getDate(),
                                    //   // new Date(filterValue.to_time)?.getHours(),
                                    //   // new Date(
                                    //   //   filterValue.to_time
                                    //   // ).getMinutes(),
                                    //   // new Date(filterValue.to_time)?.getSeconds()
                                    // );

                                    setToggleVal(!toggleVal);
                                    setFilterDates([
                                      {
                                        index: 5,
                                        from_date: moment(filterValue.from_date).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                                        to_date: moment(filterValue.to_date).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                                      },
                                    ]);
                                    setFlterFlg(true);
                                    setAnchorEl(null);
                                  }}
                                // id={styles.search_button}
                                >
                                  SEARCH
                                </Button>
                              </MenuItem>
                            </Menu>
                          </LocalizationProvider>
                        </React.Fragment>
                      ) : (
                        // <Box id={styles.filterbutton}>
                        //   <Typography >{data.value}</Typography>
                        // </Box>
                        <React.Fragment key={index}>
                          <Button
                            variant="contained"
                            id={styles.filterbutton}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleFilterChange(data, index);
                            }}
                            sx={{
                              backgroundColor: posArray.includes(index)
                                ? "#E98D12!important"
                                : "#FFFF!important",
                              color: posArray.includes(index)
                                ? "#FFFF!important"
                                : "#E98D12!important",

                              "&:hover": {
                                backgroundColor: posArray.includes(index)
                                  ? "#E98D12!important"
                                  : "#FFFF!important",
                                color: posArray.includes(index)
                                  ? "#FFFF!important"
                                  : "#E98D12!important",
                                boxShadow: "0 4px 12px rgb(0 0 0 / 15%)",
                              },
                            }}
                          >
                            {data.value}
                          </Button>
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                  "& .Mui-selected": {
                    color: "white!important",
                  },

                  "& .MuiTabs-indicator ": {
                    backgroundColor: "#E98D12!important",
                  },
                }}
                style={{ color: "white" }}
              >
                {tabs.map((data, index) => (
                  <Tab
                    key={index}
                    sx={{ color: "white" }}
                    label={data?.value}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
              <Box
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginRight: "20px",
                }}
              >
                {/* {tblIndex === 6 && data1.role == 1 && (
                  <Tooltip title="Activate">
                    <Box
                      sx={{
                        color: "white",
                        marginRight: "12px!important",
                        cursor: "pointer",
                      }}
                    >
                      <ToggleSwitch
                        handleChange={(e) => {
                          setChecked(true);
                          setTimeout(() => {
                            handleActivateData();
                          }, 500);
                        }}
                        checked={checked}
                      />
                    </Box>
                  </Tooltip>
                )} */}

                {prtIcnFlag && (
                  <>
                    <PrintIcon
                      sx={{
                        color: "white",
                        marginRight: "12px",
                        cursor: "pointer",
                      }}
                      onClick={handleClickPrint}
                    />
                  </>
                )}
                {dldIcnFlg && (
                  <Tooltip title="Download">
                    <FileDownloadIcon
                      sx={{
                        color: "white",
                        marginRight: "15px",
                        cursor: "pointer",
                        width: "37px",
                        height: "35px",
                      }}
                      onClick={() => {
                        if (tableData && tableData?.length > 0) {
                          handleDownloadData();
                        } else {
                          setTstContent("No data available to print");
                          setTstOpn(true);
                        }
                      }}
                    />
                  </Tooltip>
                )}
                {dltIcnFlg && (
                  <Tooltip title="Delete">
                    <DeleteIcon
                      sx={{ color: "white", cursor: "pointer" }}
                      onClick={() => {
                        if (deleteData?.length > 0) {
                          setDltDlgOpen(true);
                        } else {
                          setTstOpn(true);
                          setTstContent("Please select atleast one item");
                        }
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            </Box>
            <>
              {tabs.map((data, index) => (
                <TabPanel value={value} index={index} key={index}>
                  <AllTable
                    key={index}
                    index={tblIndex}
                    setDeleteData={setDeleteData}
                    searchComponent={searchComponent}
                    setTableData={setTableData}
                    filterDates={filterDates}
                    toggleVal={toggleVal}
                    tstContent={tstContent}
                    setTstContent={setTstContent}
                    setTstOpn={setTstOpn}
                    setDltData={setDeleteData}
                    setDltDlgOpen={setDltDlgOpen}
                    setCurrentTbl={setCurrentTbl}
                    setRmShiftDlgOpen={setRmShiftDlgOpen}
                    setGetInfo={setGetInfo}
                    monthDate={monthDate}
                    isDeleted={isDeleted}
                  />
                </TabPanel>
              ))}
            </>
          </Box>
        </Box>
      </Stack >

      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label={resDlgCnt}
        width="400px"
        height="150px"
      />

      <DeleteTableDataDlg
        open={dltDlgOpen}
        data={deleteData}
        setDeleteData={setDeleteData}
        label="Do you want to delete all the selected items?"
        setOpen={setDltDlgOpen}
        setToggleVal={setToggleVal}
        toggleVal={toggleVal}
        currentTbl={currentTbl}
        setIsdeleted={setIsdeleted}
      />
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />

      <RoomShiftDialog
        label="Room Shift"
        open={rmShiftDlgOpen}
        setOpen={setRmShiftDlgOpen}
        data={deleteData}
        setToggleVal={setToggleVal}
        toggleVal={toggleVal}
        currentTbl={currentTbl}
        InfoOpen={getInfo}
        setGetInfo={setGetInfo}
      />
    </Box >
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    key: index,
  };
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      key={index}
    >
      {value === index && (
        <Box sx={{ p: 3 }} key={index}>
          <Typography component={"span"} variant={"body2"}>
            {children}{" "}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default GuestInfo;
