import React, { Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import BookingForm from "./pages/Booking_form/BookingForm";
import RoomSettingsForm from "./pages/room_settings/RoomSettingsForm";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";
import axios from "axios";
import Dashboard from "./pages/DashBoard/Dashboard";
import { usePromiseTracker } from "react-promise-tracker";
import DepartureForm from "./pages/Departure/DepartureForm";
import GuestInfo from "./pages/GuestInfo/GuestInfo";
import PrivateRoute from "./utils/PrivateRoute";
import Register from "./pages/ProfileSettings/Register";
function App() {
  const loading = <div className="loader"></div>;
  const { promiseInProgress } = usePromiseTracker();
  const LoginForm = React.lazy(() => import("./pages/LoginForm"));

  const GuestInfoPage = React.lazy(() => import("./pages/GuestInfo/GuestInfo"));

  const DeparturePage = React.lazy(() =>
    import("./pages/Departure/DepartureForm")
  );
  return (
    <>
      {promiseInProgress === true ? (
        <div
          style={{
            width: "100%",
            height: "96vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: "100",
          }}
        >
          <div className="loader"></div>
        </div>
      ) : null}
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />

            <Route
              exact
              path="/login"
              name="Login Page"
              element={<LoginForm />}
            />
            <Route exact path="/" name="Login Page" element={<LoginForm />} />
            <Route
              path="/dashboard"
              name="Dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            {/* <Route
              exact
              path="/dashboard"
              name="Dashboard"
              element={<Dashboard />}
            /> */}

            <Route
              exact
              path="/add-booking"
              name="BookingForm"
              element={
                <PrivateRoute>
                  <BookingForm />
                </PrivateRoute>
              }
            />

            <Route
              // exact
              path="/guest-info"
              name="GuestInfo"
              element={
                <PrivateRoute>
                  <GuestInfo />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/room-setting"
              name="RoomSettings"
              element={
                <PrivateRoute>
                  <RoomSettingsForm />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/profile-settings"
              name="ProfileSettings"
              element={
                <PrivateRoute>
                  <ProfileSettings />
                </PrivateRoute>
              }
            />

            <Route
              exact
              path="/departure"
              name="Departure"
              element={
                <PrivateRoute>
                  <DepartureForm />
                </PrivateRoute>
              }
            />

            <Route
              path="/edit-booking"
              name="BookingForm"
              element={
                <PrivateRoute>
                  <BookingForm />
                </PrivateRoute>
              }
            />

            <Route
              // path="/edit-departure/:booking_id"
              path="/edit-departure"
              name="BookingForm"
              element={
                <PrivateRoute>
                  <DepartureForm />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
