import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
// import "../pages/Booking_form/bookingform.module.css"
function CustomSelectValue(props) {
  return (
    <>
      {/* <InputLabel id="demo-select-small">{props.placeholder}</InputLabel> */}

      <Select
        fullWidth
        // style={{minHeight: "35px"}}
        variant="standard"
        id="room-det"
        disabled={props?.disable}
        // label={props.label}
        defaultValue="none"
        // labelId="demo-select-small"
        labelId="demo-simple-select-standard-label"
        disableUnderline
        value={props?.value}
        onChange={props?.handleChange}
        placeholder={props?.placeholder}
        nooptionsmessage={props?.noOptionsMessage}
        onBlur={props?.onBlur}
        // minHeight= "32px"
        name={props?.name}
        displayEmpty
        renderValue={props?.value !== "" ? undefined : () => props?.placeholder}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          opacity: props?.opacity ? props.opacity : 1,
          "& .MuiInputBase-input": {
            //   "&:hover": {
            //     // border: "1px solid black",
            //   },
            borderRadius: props?.borderRadius,
            position: "relative",
            border: props?.border,
            fontSize: props?.fontSize,
            minHeight: "27px!important",
            width: props?.width,
            padding: props?.padding,
            // marginTop:"4px",
            // marginBottom:"8px",
            background: "white!important",
            color: props?.value !== "" ? "black" : "hsl(0, 0%, 50%)",
          },
          "& .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon": {
            color: "#E98D12!important",
          },
          "& .css-1utq5rl ": {
            color: "#E98D12!important",
          },

          "& .css-1mf6u8l-MuiSvgIcon-root-MuiSelect-icon": {
            color: "#E98D12!important",
          },
          "& .css-1cccqvr.css-1cccqvr.css-1cccqvr": {
            fontSize: props?.value !== "" ? "16px!important" : "14px!important",
          },
          "& .css-1cccqvr.css-1cccqvr.css-1cccqvr ": {
            fontSize: props?.value !== "" ? "16px!important" : "14px!important",
          },
          "& .css-1cthicf-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },
          "& .css-194am0m-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input  ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },
          "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input ":
            {
              fontSize:
                props?.value !== "" ? "16px!important" : "14px!important",
            },

          // "& .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select ": {
          //   fontSize: props.value == "" ?"14px!important":"16px!important",
          // },
          // "& .css-uipmbc-MuiInputBase-root-MuiInput-root-MuiSelect-root .MuiInputBase-input":{
          //   fontSize: props.value !== "" ?"16px":"14px!important",
          // },
          borderRadius: props?.borderRadius,
          marginRight: props?.marginRight,
          // height: "35px",
        }}
      >
        {props?.muiOptions.map((option, index) => {
          return (
            <MenuItem
              sx={{ fontSize: props?.optionFontSize }}
              value={option?.value}
              key={index}
            >
              {option?.value}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

export default CustomSelectValue;
