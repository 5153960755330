import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CLR_DLG_WHITE,
  CLR_LIGHT_GREY,
  CLR_LIGHT_WHITE,
  CLR_ORANGE,
} from "../../utils/colors";
import ReactSignatureCanvas from "react-signature-canvas";
import Compressor from "compressorjs";

function SignatureDiologue(props) {
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [viewFlag, setViewFlag] = useState(false);
  useEffect(() => {
    if (props?.signImage) {
      setViewFlag(true);
      setImage(props?.signImage);
      //   setSource(props.image);
    }
  }, [props?.signImage]);

  const sigCanvas = useRef({});
  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      var file = dataURLtoFile(dataURL, "signature");
      setImage(dataURL);
      // setImageFile(file);
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setImageFile(compressedResult);
        },
      });

      return dataURL;
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl?.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr?.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr?.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const clearForm = () => {
    if (sigCanvas.current && !viewFlag) {
      sigCanvas.current.clear();
    }
    clearTheLog();
  };

  const clearTheLog = () => {
    setImage(null);
    setImageFile(null);
    props?.setSignImage(null);
    props?.setSignFile(null);
    setViewFlag(false);
  };

  return (
    <Dialog
      // onClose={props.open == true ? props.setOpen(false) : ""}
      aria-labelledby="customized-dialog-title"
      open={props?.open}
      sx={{
        "& .MuiPaper-root": {
          background: CLR_DLG_WHITE,
          borderRadius: "20px",
        },
        "& .MuiDialogContent-root": {
          border: "none",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            props?.setOpen(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <Tooltip title="Close">
            <img
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              src="/images/icon_close_circle_orange.png"
              onClick={(item) => {
                setImage(null);
                props?.setOpen(false);
              }}
            />
          </Tooltip>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          {viewFlag && (
            <img
              height={550}
              width={550}
              onClick={() => {
                setViewFlag(false);
              }}
              src={props?.signImage}
            />
          )}

          {!viewFlag && (
            <ReactSignatureCanvas
              penColor="green"
              ref={sigCanvas}
              onEnd={formatIntoPng}
              canvasProps={{
                height: 550,
                width: 550,
                className: "sigCanvas",
              }}
            />
          )}
        </Box>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
        sx={{
          paddingBottom: "25px",
          marginTop: "10px",
        }}
      >
        <Button
          disabled={!image}
          sx={{
            padding: "10px 20px",
            background: `${CLR_LIGHT_WHITE}`,
            borderRadius: "10px",
            shadowColor: "1px 1px 6px #E98D12",
            color: `${CLR_ORANGE}`,
            "&:hover": {
              color: `${CLR_ORANGE}`,
              background: `${CLR_LIGHT_WHITE}`,
            },
          }}
          variant="contained"
          onClick={() => {
            clearForm();
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          disabled={!image}
          sx={{
            padding: "10px 20px",
            background: `${CLR_ORANGE}`,
            borderRadius: "10px",
            shadowColor: "1px 1px 6px #E98D12",
            "&:hover": {
              color: `${CLR_ORANGE}`,
              background: `${CLR_LIGHT_WHITE}`,
            },
          }}
          onClick={(e) => {
            if (image) {
              props?.setSignImage(image);
              props?.setSignFile(imageFile);
              props?.setOpen(false);
            }
            e.stopPropagation();
          }}
        >
          Save
        </Button>
      </Stack>
    </Dialog>
  );
}

export default SignatureDiologue;
