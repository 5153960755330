import { Typography } from "@mui/material";
import React from "react";

function CustomLabel({ label, color, fontSize }) {
  return (
    <Typography
      sx={{
        color: color === undefined ? "white" : color,
        margin: "12px",
        fontWeight: "bold",
        fontSize: fontSize,
      }}
    >
      {label}
    </Typography>
  );
}

export default CustomLabel;
