import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CLR_ORANGE } from "../../utils/colors";
import styles from "./roomsection.module.css";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { InfoRounded } from "@mui/icons-material";
function RoomSection(props) {
  const [flag, setFlag] = useState(false);
  const [txtVal, setTxtVal] = useState("");
  useEffect(() => {
    return () => {
      setFlag(false);
    };
  }, []);
  return (
    <Grid item lg={6} md={6} sm={6}>
      <Box sx={{ marginBottom: "5px" }}>
        <Box id={styles.filterbutton}>{props?.data?.type}</Box>
      </Box>
      <Box style={{ position: "relative", display: "flex" }}>
        {props?.deleteCatIndex === props?.blockIndex && (
          <Tooltip title={"Delete " + props?.data?.type + " category"}>
            <DeleteIcon
              sx={{
                width: "20px",
                height: "24px",
                cursor: "pointer",
                position: "absolute",
                right: "8px",
                top: "-10px",
                zIndex: 2,
                color: CLR_ORANGE,
              }}
              onClick={() => {
                props?.setCatDlgOpn(true);
                props?.setDelteDlgWrd(
                  "Are you sure you want to delete the category " +
                    props?.data?.type +
                    " ?"
                );
              }}
            />
            {/* <CloseIcon
              sx={{
                width: "20px",
                height: "24px",
                cursor: "pointer",
                position: "absolute",
                right: "8px",
                top: "-10px",
                zIndex: 2,
                color: CLR_ORANGE,
              }}
              onClick={() => {
                props.setCatDlgOpn(true);
                props.setDelteDlgWrd(
                  "Are You Sure You want to delete the category " +
                    props.data.type +
                    " ?"
                );
                
              }}
            /> */}
          </Tooltip>
        )}

        <Box
          style={{
            minHeight: "20vh",
            backgroundColor: "white",
            width: "95%",
            overFlow: "scroll",
            marginBottom: "10px",
            border: "0.5px solid #7D86A9",
            borderRadius: "10px",
            padding: "5px",
          }}
          onClick={(e) => {
            setFlag(true);
            props?.setDeleteCatIndex(props?.blockIndex);
          }}
        >
          <Box
            style={{
              display: "flex",
              flexFlow: "row wrap",
              position: "relative",
            }}
          >
            {props?.data?.values.map((item, index) => {
              return (
                <Box id={styles.room_head} key={index}>
                  {item.room_availability == "Occupied" ? (
                    <Tooltip
                      title={
                        "Can't Delete Room Number " +
                        item?.room__number +
                        " (Occupied)"
                      }
                    >
                      <InfoRounded id={styles.room_close_icon} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={"Delete Room Number " + item?.room__number}>
                      <CloseIcon
                        id={styles.room_close_icon}
                        onClick={() => {
                          props?.setRoomDlgOpn(true);
                          props?.setDelteDlgWrd(
                            "Are you sure you want to delete room number " +
                              item?.room__number +
                              " ?"
                          );

                          props?.setDelRoomObj({
                            id: item?.room__number,
                            index: props?.blockIndex,
                          });
                        }}
                      />
                    </Tooltip>
                  )}
                  <Box id={styles.rooms} key={index}>
                    <Typography>{item?.room__number}</Typography>
                  </Box>
                </Box>
              );
            })}
            {flag && (
              <TextField
                autoFocus
                type="text"
                // type="text"
                sx={{
                  "& .MuiOutlinedInput-input": { height: "0px" },

                  "& .MuiInputBase-input": {},
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                    border: "none",
                  },
                }}
                value={txtVal}
                inputProps={{
                  maxLength: 3,
                  inputMode: "text",
                  pattern: "[0-9]*",
                }}
                style={{
                  background: "#FFFFFF",
                  borderRadius: "10px",
                  border: "none",
                  borderColor: "coral",
                  width: "70px",
                  paddingTop: "5px",
                  marginTop: "2px",
                }}
                onChange={(event) => {
                  let val = event.target.value;
                  // val = val === "." ? "" : val
                  val = val.replace(/[^\w ]/g, "");
                  val = val.replace(/^\s+|\s+$/gm, "");
                  if (
                    // name == "no_of_persons" &&
                    val?.length >= 3
                  ) {
                    val = val.substr(0, 3);
                  }
                  const re = /^[0-9A-Za-z\b]+$/;
                  if (val === "" || re.test(val)) {
                    // val = Math.max(0, parseInt(val))
                    // val
                    // .toString()
                    // .slice(0, 3);
                    setTxtVal(val);
                  }
                  // event.target.value = Math.max(0, parseInt(event.target.value))
                  //   .toString()
                  //   .slice(0, 3);
                  // setTxtVal(event.target.value);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                  if (event.key === "Enter" && event.target.value != "") {
                    props?.handleToAddRoom(event, props?.blockIndex);
                    setTxtVal("");
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}

export default RoomSection;
