import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import TableUtils from "../TableUtils";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styles from "./alltable.module.css";
import {
  AllCells,
  ArrivalCells,
  DepartureCells,
  DraftCells,
  DeletedCells,
  DocumentCells,
  AdminCells,
  BlackListCells
} from "../../utils/models/tablemodels";
import ToggleSwitch from "../ToggleSwich/ToggleSwitch";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../../utils/colors";

import { trackPromise } from "react-promise-tracker";
import staticTexts from "../../utils/static-texts";
import {
  InsertSerialId,
  activateBookingData,
  customerBlocking,
  getAllDocuments,
  getGuestInfoData,
  getMonthRecords,
  updateIsFavorite,
} from "../../services/bookingServices";
import {
  DragHandle,
  Favorite,
  FavoriteBorder,
  SwapHorizontalCircleRounded,
} from "@mui/icons-material";
import moment from "moment";
import Carousel from "react-material-ui-carousel";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ResultDiologueBox from "../ResultDiologueBox";
import CustomSnackBar from "../CustomSnackBar";
import CustomSaveButton from "../CustomSaveButton";
import CustomCancelButton from "../CustomCancelButton";
import CustomTextField from "../CustomTextField";
import CustomLabel from "../CustomLabel";
import CustomDiolog from "../Diologues/CustomDiolog";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  // background: "white",
  boxShadow: isDragging ? "0px 3px 6px black" : "",
  border: isDragging ? "solid gray 2px " : "",
  width: "100%",
  alignItems: "center",
  height: "fitContent",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#f5f4f2" : "white",
  boxShadow: isDraggingOver ? "0px 3px 6px black" : "",
  border: isDraggingOver ? "solid grey 2px " : "",
  width: "100%",
});

function AllTable(props) {
  const [records, setRecords] = useState([]);
  // const [dltDlgOpen, setDltDlgOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  // const [actData, setActData] = useState([]);
  // const [delDlgCnt, setDelDlgCnt] = useState(null);

  // const [rmShiftDlgOpen, setRmShiftDlgOpen] = useState(false);
  // const [getInfo, SetGetInfo] = useState(false);

  const [filterFn, setfilterFn] = useState({
    fn: (items) => {
      // setFilterRecords(items);
      return items;
    },
  });
  const [selected, setSelected] = useState([]);
  const [headCells, setHeadCells] = useState([]);

  const navigate = useNavigate();

  const [checkBoxFlag, setCheckBoxFlag] = useState(false);
  const [activateFlag, setActivateFlag] = useState(false);

  const [currentTbl, setCurrentTbl] = useState(null);

  const [roomType, setRoomType] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useState(null);
  const [hotelName, setHotelName] = useState('');

  const [page, setPage] = useState(1);
  const pages = [10, 20, 30];
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [tableType, setTableType] = useState("");
  const [count, setCount] = useState(0);
  const [tstOpn, setTstOpn] = useState(false);
  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [resDlgCnt, setResDlgCnt] = useState(null);
  const [deleteData, setDeleteData] = useState([]);
  let serialIndex = 1;

  useEffect(() => {
    let tableType;

    if (props?.index === 0) {
      tableType = "arrival";
      setTableType("arrival");
    } else if (props?.index === 1) {
      tableType = "departure";
      setTableType("departure");
    } else if (props?.index === 2) {
      tableType = "draft";
      setTableType("draft");
    } else if (props?.index === 3) {
      tableType = "All";
      setTableType("All");
    } else if (props?.index === 4) {
      tableType = "document";
      setTableType("document");
    } else if (props?.index === 5) {
      tableType = "blackList";
      setTableType("blackList");
    }

    else if (props?.index === 6) {
      tableType = "admin";
      setTableType("admin");
    } else if (props?.index === 7) {
      tableType = "deleted";
      setTableType("deleted");
    }

    const roomTypeName = searchParams.get("room_type");
    setRoomType(roomTypeName);

    if (props?.index === 4 || props.index === 5) {
      getAllDocument(tableType, page, rowsPerPage);
    } else if (props?.index != 4 && props?.index != 5 && props?.index != 6 && props?.index != 7) {
      getGuestInfo(tableType, roomTypeName, page, rowsPerPage);
    }
    return () => {
      // cleanup
    };
  }, [props?.toggleVal]);

  const [dateRecords, setDateRecords] = useState([]);

  useEffect(() => {
    if (props.monthDate.length > 0) {
      getMonthHistory(props.monthDate[0]?.month, props.monthDate[0]?.year);
    }
  }, [props?.monthDate, props.isDeleted]);

  useEffect(() => {
    let tableType;

    if (props?.index === 0) {
      setHeadCells(ArrivalCells);
      setCheckBoxFlag(false);
      tableType = "arrival";
      // setTableType('Arrival');
    } else if (props?.index === 1) {
      setHeadCells(DepartureCells);
      setCheckBoxFlag(false);
      tableType = "departure";
      // setTableType('Departure');
    } else if (props?.index === 2) {
      setHeadCells(DraftCells);
      setCheckBoxFlag(false);
      tableType = "draft";
      // setTableType('Draft');
    } else if (props?.index === 3) {
      setHeadCells(AllCells);
      // setCheckBoxFlag(true);
      setCheckBoxFlag(userData?.role == 1 ? true : false);
      tableType = "All";
    } else if (props?.index === 4) {
      setHeadCells(DocumentCells);
      // setActivateFlag(true);
      // setCheckBoxFlag(true);
      tableType = "document";
    } else if (props?.index === 5) {
      setHeadCells(BlackListCells);
      tableType = "blackList";
    }
    else if (props?.index === 6) {
      setHeadCells(AdminCells);
      // setActivateFlag(true);
      // setCheckBoxFlag(true);
      tableType = "admin";
    } else if (props?.index === 7) {
      setHeadCells(DeletedCells);
      setActivateFlag(true);
      setCheckBoxFlag(true);
      tableType = "deleted";
      // setTableType('Deleted');
    } else {
      setHeadCells(AllCells);
      setCheckBoxFlag(true);
      tableType = "All";
      // setTableType('All');
    }
    props?.setCurrentTbl(tableType);
    setCurrentTbl(tableType);
    const roomTypeName = searchParams.get("room_type");
    setRoomType(roomTypeName);

    return () => {
      // cleanup
    };
  }, [props?.index]);

  const handleChangePage = (event, newPage) => {
    const updatedPage = newPage + 1;
    setPage(updatedPage);
    if (props?.index === 4) {
      getAllDocument(tableType, page, rowsPerPage);
    } else if (props?.index != 4 && props?.index != 5) {
      getGuestInfo(tableType, roomType, updatedPage, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    const page_size = event.target.value;
    setRowsPerPage(event.target.value);
    setTimeout(() => {
      if (props?.index === 4) {
        getAllDocument(tableType, page, page_size);
      } else if (props?.index != 4 && props?.index != 5) {
        getGuestInfo(tableType, roomType, page, page_size);
      }
    });
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    let hotel_name = data?.hotel_name?.split(' ')?.reduce((n, w) => {
      n += w.charAt(0);
      return n;
    }, '').toUpperCase();
    if (Number(data?.hotelId) == 5) {
      setHotelName('HP');
    } else if (Number(data?.hotelId) == 7) {
      setHotelName('BI');
    } else {
      setHotelName(hotel_name || '');
    }
    setUserData(data);
  }, []);
  const getGuestInfo = async (tableType, roomType, page, page_size) => {
    await trackPromise(
      getGuestInfoData(
        {
          table_type: tableType,
          filterValue: props?.filterDates,
          page,
          pageSize: page_size,
        },
        roomType
      )
        .then((res) => {
          try {
            let { data, totalData } = res?.data;

            let finalObj = [...data];
            if (data && data?.length > 0) {
              data?.map((main_data, index) => {
                finalObj[index].checked_in = main_data?.checkin_date
                  ? main_data?.checkin_date.slice(0, 10)
                  : null;
                finalObj[index].checkout_in = main_data?.checkout_date
                  ? main_data?.checkout_date.slice(0, 10)
                  : null;
                finalObj[index].sl_no = index + 1;
                finalObj[
                  index
                ].download_address = `${main_data?.address},${main_data?.country_name},${main_data?.state_name},${main_data?.city_name}`;
              });

              props?.setTableData(finalObj);
              setRecords(finalObj);
              setCount(totalData);
            } else {
              props?.setTableData([]);
              setRecords([]);
              props?.setTstContent("No Data Found");
              props?.setTstOpn(true);
            }
          } catch (err) {
            // alert(err);
          }
        })
        .catch((err) => { })
    );
  };

  const getMonthHistory = async (month, year) => {
    const startDate = moment
      .utc(`${month} 1, ${year}`, "MMMM D, YYYY")
      .startOf("month");
    const endDate = moment.utc(startDate).endOf("month");

    const fromDate = startDate.toISOString();
    const toDate = endDate.toISOString();

    trackPromise(
      getMonthRecords({ tableType, fromDate, toDate })
        .then((res) => {
          let { data } = res?.data;
          setDateRecords(data);
          if (data && data?.length > 0) {
            props?.setTableData(data);
          }
        })
        .catch((err) => {
          // console.log("DatesErr", err);
        })
    );
  };

  const getAllDocument = async (tableType, page, page_size) => {
    try {
      const res = await trackPromise(
        getAllDocuments({
          table_type: tableType,
          filterValue: props?.filterDates,
          page,
          pageSize: page_size,
        })
      );

      const { data, totalData } = res.data;

      if (data && data.length > 0) {
        setRecords([...data]);
        setCount(Number(totalData.total_count));
      } else {
        setRecords([]);
        props?.setTstContent("No Data Found");
        props?.setTstOpn(true);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImage, setSelectedImage] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);

  const handleImageClick = (idImages, custImages) => {
    const allImages = [...idImages];

    const imageExists = allImages.some((image) => image.url === custImages);

    if (!imageExists) {
      allImages.push({ url: custImages });
    }
    setSelectedImage(true);
    setSelectedImages(allImages);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
    setSelectedImage(false);
  };

  const handleRowDelete = (event, row) => {
    event.stopPropagation();
    props?.setDltData([row]);
    props?.setDltDlgOpen(true);
  };

  const handleRoomShift = (event, row) => {
    event.stopPropagation();
    props?.setRmShiftDlgOpen(true);
    props?.setDltData([row]);
    props?.setGetInfo(true);
  };

  const handleRowActivate = (event, item) => {
    event.stopPropagation();
    activateBooking([
      {
        booking_id: item?.booking_id,
        room_no: item?.room_no,
        room_type_id: item?.room_type_id,
        serial_number: item?.serial_number,
        room_line_id: item?.room_line_id,
      },
    ]);
  };

  const formateActivateArray = (data) => {
    let actArr = [];
    let roomArr = [];

    data?.map((item, index) => {
      actArr.push(item?.booking_id);
      if (item?.room_details && item?.room_details != "") {
        let temp = JSON.parse(item?.room_details);
        temp?.map((item) => {
          roomArr.push(item?.room_no);
        });
      }
    });

    if (roomArr?.length > 0) {
      let newRoomData = new Set(roomArr);
      roomArr = [...newRoomData];
    }
  };

  const activateBooking = async (bookingLines) => {
    await trackPromise(
      activateBookingData(bookingLines)
        .then((res) => {
          getMonthHistory(props.monthDate[0]?.month, props.monthDate[0]?.year);
          setResDlgCnt("Restored Successfully !");
          setResDlgOpn(true);
          setTimeout(() => {
            setResDlgOpn(false);
          }, 2000);
        })
        .catch((err) => {
          props?.setTstContent(err?.response?.data?.error);
          props?.setTstOpn(true);
        })
    );
  };

  const handleSelectDelete = (row) => {
    props?.setDltData([...rowData, row]);
    setSelected([]);
    props?.setDltDlgOpen(true);
  };

  const handleEditRouting = (event, data) => {
    event.stopPropagation();

    if (
      data?.room_booking_status == "arrival" ||
      data?.room_booking_status == "arrival_draft" ||
      data?.is_draft == 1
    ) {
      navigate(
        `/edit-booking?booking_id=${data?.booking_id}&room_no=${data?.room_no}&room_status=${data?.room_booking_status}`
      );
      // navigate(`/edit-booking/${data.booking_id}`);
    } else {
      // navigate(`/edit-departure/${data.booking_id}`);
      navigate(
        `/edit-departure?booking_id=${data?.booking_id}&room_no=${data?.room_no}`
      );
    }
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = records.map((n) => n.index);

      setSelected(newSelecteds);

      let newRawData = recordsAfterSorting().filter((data) => {
        if (newSelecteds.some((item) => item === data?.index)) {
          return data;
        }
      });
      props?.setDeleteData(newRawData);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    event.stopPropagation();

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    let newRawData = records?.filter((data) => {
      if (newSelected.some((item) => item === data?.index)) {
        return data;
      }
    });

    props?.setDeleteData(newRawData);
  };

  const handleSearch = async (e) => {
    if (props?.index === 4 || props.index === 5) {
      await trackPromise(
        getAllDocuments({
          table_type: currentTbl,
          filterValue: props?.filterDates,
          searchFilter: true,
          searchFilterValue: searchTxt,
        }).then((res) => {
          let { data } = res?.data;
          if (data && data?.length > 0) {
            setRecords(data);
          } else {
            setRecords([]);
            props?.setTstContent("No Data Found");
            props?.setTstOpn(true);
          }
        })
      );
    } else {
      await trackPromise(
        getGuestInfoData({
          table_type: currentTbl,
          filterValue: props?.filterDates,
          searchFilter: true,
          searchFilterValue: searchTxt,
        }).then((res) => {
          let { data } = res?.data;

          let finalObj = [...data];

          var temp = [];
          let itemtemp;

          let newFilteredArray = [];

          if (data && data?.length > 0) {
            data?.map((main_data, index) => {
              finalObj[index].checked_in = main_data?.checkin_date
                ? main_data?.checkin_date.slice(0, 10)
                : null;
              finalObj[index].checkout_in = main_data?.checkin_date
                ? main_data?.checkin_date.slice(0, 10)
                : null;
              finalObj[index].sl_no = index + 1;
              finalObj[
                index
              ].download_address = `${main_data?.address},${main_data?.country_name},${main_data?.state_name},${main_data?.city_name}`;
            });

            props?.setTableData(finalObj);
            setRecords(finalObj);
          } else {
            props?.setTableData([]);
            setRecords([]);
            props?.setTstContent("No Data Found");
            props?.setTstOpn(true);
          }
        })
      );
    }
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
    recordsAfterSorting,
    TablHead,
  } = TableUtils(
    records,
    headCells,
    filterFn,
    selected,
    props?.filterDates,
    searchTxt
  );

  const [dragIndex, setDragIndex] = useState();

  const handleDragEnd = async (result, valIndex) => {
    setTimeout(() => {
      setDragIndex(valIndex);
      if (!result.destination) {
        return;
      }
      const updatedDateRecords = [...dateRecords];
      const { source, destination } = result;
      const updatedRecords = Array.from(updatedDateRecords[valIndex].records);
      const [draggedItem] = updatedRecords.splice(source.index, 1);
      updatedRecords.splice(destination.index, 0, draggedItem);

      updatedDateRecords[valIndex] = {
        ...updatedDateRecords[valIndex],
        records: updatedRecords,
      };
      setDateRecords(updatedDateRecords);
    });
  };

  const handleDateButtonClick = (selectedDate) => {
    const selectedRecords = dateRecords.filter(
      (val) => val.date === selectedDate
    );
    const room_id = [];
    selectedRecords.forEach((val) => {
      val.records.forEach((item, ind) => {
        room_id.push(item.room_line_id);
      });
    });
    insertserial(room_id, selectedDate);
  };

  const insertserial = async (room_id, selectedDate) => {
    try {
      const res = await trackPromise(
        InsertSerialId({
          roomLineId: room_id,
        })
      );
      const { code } = res.data;
      if (code == 200) {
        setDragIndex();
        getMonthHistory(props.monthDate[0]?.month, props.monthDate[0]?.year);
        setResDlgCnt("Reordered Successfully !");
        setTimeout(() => setResDlgOpn(true));
        setTimeout(() => setResDlgOpn(false), 3000);
      } else {
        setTstOpn(true);
        setTimeout(() => setTstOpn(false), 3000);
      }
    } catch (err) {
      setTstOpn(true);
      setTimeout(() => setTstOpn(false), 3000);
    }
  };

  const handleFavoriteToggle = async (lineId, isFavorite) => {
    try {
      trackPromise(
        updateIsFavorite({ roomLineId: lineId, isFavorite }).then((res) => {
          getMonthHistory(props.monthDate[0]?.month, props.monthDate[0]?.year);
          setResDlgCnt("Updated Favorites");
          setResDlgOpn(true);
          setTimeout(() => {
            setResDlgOpn(false);
          }, 1500);
        })
      );
    } catch (error) {
      // console.error("Error handling favorite toggle:", error);
    }
  };

  const multipleDelete = (data, event) => {
    props.setDltData(deleteData);
    props?.setDltDlgOpen(true);
    setTimeout(() => {
      setDeleteData([]);
    }, 300);

  };
  const multiSelect = (index, data, checked) => {
    if (checked) {
      setDeleteData([...deleteData, data]);
    } else {
      let multiDeleteData = deleteData.filter((d) => d.room_line_id !== data.room_line_id);
      setDeleteData(multiDeleteData);
    }
  };

  const isChecked = (id) => {
    return deleteData.some((d) => d.room_line_id === id);
  };

  const [blockModal, setBlockModal] = useState(false);
  const [unBlockModal, setUnBlockModal] = useState(false);
  const [customerToBlock, setCustomerToBlock] = useState({});
  const [blockingReason, setBlockingReason] = useState("")

  const handleBlockCustomer = async () => {
    try {
      trackPromise(
        customerBlocking({ customer_id: customerToBlock?.customer_id, blocking_comment: blockingReason, is_blocked: customerToBlock?.is_blocked })
          .then((res) => {
            console.log("res>>.", res);
            if (res.status == 200) {
              setBlockModal(false)
              setUnBlockModal(false)
              if (!customerToBlock?.is_blocked) {
                getAllDocument("blackList", page, rowsPerPage);
              } else {
                // window.location.reload()
                getGuestInfo("All", roomType, page, rowsPerPage)

              }
              setResDlgCnt(`${customerToBlock?.name} ${customerToBlock?.is_blocked ? "blocked" : "unblocked"} successfully`);
              setResDlgOpn(true);
              setCustomerToBlock({})
              setBlockingReason("")
              setTimeout(() => {
                setResDlgOpn(false);
              }, 1500);
            }
          })
      );
    } catch (error) {
      console.error("Error handling favorite toggle:", error);
    }
  }

  const handleBlockCustomerPopup = (customer_id, name, is_blocked) => {
    setCustomerToBlock({ customer_id, name, is_blocked })
    setBlockModal(true)
  }

  const handleUnBlockCustomerPopup = (customer_id, name, is_blocked) => {
    setCustomerToBlock({ customer_id, name, is_blocked })
    setUnBlockModal(true)
  }

  return (
    <>
      {headCells !== DeletedCells && headCells !== AdminCells && (
        <Paper
          elevation={3}
          key={currentTbl}
          style={{ height: "60px", borderRadius: "15px 15px 0px 0px" }}
        >
          {headCells !== DeletedCells && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                marginTop: "15px",
                paddingTop: "20px",
                // width: 400,
                paddingLeft: "15px",
              }}
            >
              <TextField
                sx={{
                  marginTop: "-6px",
                  marginRight: "2px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    height: "33px",
                  },
                  background: "#FFFFFF",
                  borderRadius: "5px",
                }}
                // fullWidth
                onChange={(e) => {
                  setSearchTxt(e.target.value);
                  // if(e.target.value == " " && e.target.value == ""){
                  //   let tableType;
                  //   setHeadCells(AllCells);
                  //   setCheckBoxFlag(true);
                  //   tableType = "All";
                  //   handleSearch(e)
                  // }
                  // handleSearch(e)
                }}
                placeholder="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Tooltip title="Search">
                          <SearchIcon
                            onClick={(event) => {
                              handleSearch(event);
                            }}
                            sx={{ cursor: "pointer", color: CLR_ORANGE }}
                          />
                        </Tooltip>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </Paper>
      )}

      {props.index != 6 && props.index != 7 ? (
        <Paper
          elevation={3}
          id={styles.tableDimension}
          sx={{
            borderRadius:
              headCells !== DeletedCells && headCells !== AdminCells
                ? "0px 0px 15px 15px!important"
                : "15px 15px 15px 15px!important",
          }}
        >
          <TblContainer>
            <TblHead onSelectAllClick={onSelectAllClick} flag={checkBoxFlag} />
            <TableBody>
              {recordsAfterPagingAndSorting().map((item, index) => (
                <TableRow
                  key={index}
                  role="checkbox"
                  aria-checked={isSelected(item?.index)}
                  tabIndex={-1}
                  selected={isSelected(item?.index)}
                >
                  {/* {(headCells === ArrivalCells || headCells === DeletedCells) && */}
                  {headCells === DeletedCells && userData?.role == 1 ? (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(item?.index)}
                        // inputProps={{
                        //   "aria-labelledby": labelId,
                        // }}
                        onClick={(event) => {
                          if (
                            headCells === AllCells ||
                            headCells === DeletedCells
                          )
                            // handleClick(event, item.booking_id);
                            handleClick(event, item?.index);
                        }}
                      />
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{ padding: "8px 10px", minWidth: "50px" }}
                    >
                      {
                        (tableType === 'draft' ?
                          (item?.checkin_date && moment(item.checkin_date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD"))
                          :
                          (!!item?.checkin_date && moment(item[tableType === 'departure' ? 'checkout_date' : 'checkin_date']).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD"))) ?
                          (tableType === 'draft' ?
                            `${item.arrival_serial?.split('/')[0]}/${hotelName}${item?.arrival_serial?.split('/')[1]}`
                            :
                            (tableType === 'arrival' || tableType === 'All') ? `${item.arrival_serial?.split('/')[0]}/${hotelName}${item.arrival_serial?.split('/')[1]}` : `${item.departure_serial?.split('/')[0]}/${hotelName}${item.departure_serial?.split('/')[1]}`
                          )
                          :
                          item.serial_number
                      }
                    </TableCell>
                  )}
                  {headCells?.map((data, ind) => (
                    <React.Fragment key={ind}>
                      {data?.id === "room_number" ? (
                        <TableCell
                          style={{ padding: "8px 40px", minWidth: "80px" }}
                        >
                          {item?.room_no}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "name" ? (
                        <TableCell
                          style={{ padding: "8px 20px", minWidth: "100px" }}
                        >
                          {item?.name}
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {data?.id === "mobile" ? (
                        <TableCell
                          style={{ padding: "8px 0px", minWidth: "150px" }}
                        >
                          {item?.mobile}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "address" ? (
                        <TableCell
                          style={{ padding: "8px 10px", minWidth: "150px" }}
                        >
                          {item?.address}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "coutry_name" ? (
                        <TableCell
                          style={{ padding: "8px 25px", minWidth: "80px" }}
                        >
                          {item?.country_name}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "state_name" ? (
                        <TableCell
                          style={{ padding: "8px 0px", minWidth: "140px" }}
                        >
                          {item?.state_name}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "city_name" ? (
                        <TableCell
                          style={{ padding: "8px 0px", minWidth: "130px" }}
                        >
                          {item?.city_name}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "no_of_persons" ? (
                        <TableCell
                          style={{ padding: "8px 50px", minWidth: "50px" }}
                        >
                          {item?.no_of_persons}
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {data?.id === "occupation" ? (
                        <TableCell
                          style={{ padding: "8px 20px", minWidth: "100px" }}
                        >
                          {item?.occupation}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "purpose_of_visit" ? (
                        <TableCell
                          style={{ padding: "8px 40px", minWidth: "80px" }}
                        >
                          {item?.purpose_of_visit}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "heading_to" ? (
                        <TableCell style={{ padding: "8px 15px" }}>
                          {item?.heading_to}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "arrival_signature_image" ? (
                        <TableCell style={{ padding: "8px 15px" }}>
                          <Box>
                            <img
                              src={item?.arrival_signature_image_url}
                              style={{ height: "50px", width: "50px" }}
                            />
                          </Box>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "departure_signature_image" ? (
                        <TableCell style={{ padding: "8px 15px" }}>
                          <>
                            <img
                              src={item?.departure_signature_image_url}
                              style={{ height: "70px", width: "70px" }}
                            />
                          </>
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {data?.id === "arrival_date" ? (
                        <TableCell
                          style={{ padding: "8px 17px", minWidth: "120px" }}
                        >
                          {new Date(item?.checkin_date)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : //  new Date(item?.checkin_date)
                            //     .toISOString()
                            //     .slice(0, 10)}
                            moment(item?.checkin_date).format("DD-MM-YYYY")}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "arrival_time" ? (
                        <TableCell
                          style={{ padding: "8px 17px", minWidth: "120px" }}
                        >
                          {new Date(item?.checkin_date)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : // new Date(item.checkin_date)
                            //     .toISOString()
                            //     .slice(0, 10)
                            moment(item?.checkin_date).format("hh:mm:ss A")}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "departure_date" ? (
                        <TableCell
                          style={{ padding: "8px 50px", minWidth: "80px" }}
                        >
                          {new Date(item?.checkout_date)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : //   : new Date(item?.checkout_date)
                            //       .toISOString()
                            //       .slice(0, 10)
                            moment(item?.checkout_date).format("DD-MM-YYYY")}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "departure_time" ? (
                        <TableCell
                          style={{ padding: "8px 40px", minWidth: "82px" }}
                        >
                          {new Date(item?.checkout_date)
                            .toISOString()
                            .slice(0, 10) === "1970-01-01"
                            ? "---"
                            : // : new Date(item?.checkout_date)
                            //     .toLocaleString()
                            //     .slice(11)}
                            // {/* {new Date(item.checkout_date)
                            // .toLocaleString().slice(11)
                            //   // .toISOString()
                            //   // .slice(0, 10) === "1970-01-01"
                            //   // ? "---"
                            //   // : new Date(item.checkout_date)
                            //   //     .toISOString()
                            //   //     .slice(0, 10)
                            //       } */}
                            moment(item?.checkout_date).format("hh:mm:ss A")}
                        </TableCell>
                      ) : (
                        ""
                      )}

                      {data?.id === "action" ? (
                        <TableCell
                          style={{ padding: "8px 10px", minWidth: "100px" }}
                        >
                          {!item?.checkout_date &&
                            item?.room_booking_status == "arrival" ? (
                            <Tooltip title="RoomShift">
                              <SwapHorizontalCircleRounded
                                onClick={(event) =>
                                  handleRoomShift(event, item)
                                }
                                sx={{
                                  marginRight: "5px",
                                  "&:hover": {
                                    color: CLR_ORANGE,
                                  },
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                          {!item?.checkout_date ? (
                            <Tooltip title="Edit">
                              <EditIcon
                                onClick={(event) => {
                                  handleEditRouting(event, item);
                                }}
                                sx={{
                                  "&:hover": {
                                    color: CLR_ORANGE,
                                  },
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          {userData?.role == 1 &&
                            item?.room_booking_status != "arrival" ? (
                            <Tooltip title="Delete">
                              <DeleteIcon
                                onClick={(event) =>
                                  handleRowDelete(event, item)
                                }
                                sx={{
                                  marginLeft: "5px",
                                  "&:hover": {
                                    color: CLR_ORANGE,
                                  },
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "activate" ? (
                        <TableCell>
                          <ToggleSwitch
                            handleChange={(event) => {
                              event.stopPropagation();
                              handleRowActivate(event, item);
                            }}
                          />
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "allTabAction" ? (
                        <TableCell style={{ padding: "8px" }}>
                          <Button
                            variant="contained"
                            disabled={item?.is_blocked}
                            sx={{
                              background: CLR_ORANGE,
                              borderRadius: "4px",
                              shadowColor: "1px 1px 6px #E98D12",
                              "&:hover": {
                                color: CLR_ORANGE,
                                background: CLR_LIGHT_WHITE,
                              },
                            }}
                            onClick={() => handleBlockCustomerPopup(item.customer_id, item.name, true)}
                          >
                            {item?.is_blocked ? "BLOCKED" : "BLOCK"}
                          </Button>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "blackListAction" ? (
                        <TableCell style={{ padding: "8px" }}>
                          <Button
                            variant="contained"
                            sx={{
                              background: CLR_ORANGE,
                              borderRadius: "4px",
                              shadowColor: "1px 1px 6px #E98D12",
                              "&:hover": {
                                color: CLR_ORANGE,
                                background: CLR_LIGHT_WHITE,
                              },
                            }}
                            onClick={() => handleUnBlockCustomerPopup(item.serial_number, item.name, false)}
                          >
                            UNBLOCK
                          </Button>
                        </TableCell>
                      ) : (
                        ""
                      )}
                      {data?.id === "document" ? (
                        <TableCell style={{ padding: "8px" }}>
                          <Stack direction={"row"} columnGap={1}>
                            <>
                              {item.id_proof_image_url.map((val, index) => (
                                <img
                                  key={index}
                                  src={val.url}
                                  style={{ height: "50px", width: "50px" }}
                                  onClick={() =>
                                    handleImageClick(
                                      item.id_proof_image_url,
                                      item.cust_image_url
                                    )
                                  }
                                />
                              ))}
                            </>
                            <img
                              src={item?.cust_image_url}
                              style={{ height: "50px", width: "50px" }}
                              onClick={() =>
                                handleImageClick(
                                  item.id_proof_image_url,
                                  item.cust_image_url
                                )
                              }
                            />
                          </Stack>
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
                  {/* <TableCell style={{ padding: "8px" }}>
                <EditIcon
                  onClick={(item) => {
                    navigate("/add-booking");
                  }}
                />
                <DeleteIcon onClick={(event) => handleRowDelete(event, item)} />
              </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>

          {/* <TblPagination /> */}
          {!!searchTxt || props?.filterDates?.length > 0 ? (
            <TblPagination />
          ) : (
            <TablePagination
              component="div"
              page={page - 1}
              rowsPerPageOptions={pages}
              rowsPerPage={rowsPerPage}
              count={count}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      ) : (
        ""
      )}

      {props.index == 7 ? (
        <Paper
          elevation={3}
          id={styles.tableDimension}
          sx={{
            borderRadius:
              headCells !== DeletedCells && headCells !== AdminCells
                ? "0px 0px 15px 15px!important"
                : "15px 15px 15px 15px!important",
          }}
        >
          <TblContainer>
            <TblHead />
            <TableBody>
              {dateRecords.map((val, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <TableRow>
                      <TableCell
                        style={{
                          padding: "8px 10px",
                          borderTopRightRadius: "34px",
                          borderBottomRightRadius: "34px",
                          fontSize: "14px",
                          boxShadow: "0px 3px 6px #00000029",
                          backgroundColor: "#E98D12",
                          color: "#FFFF",
                          textAlign: "center",
                          minWidth: "120px",
                        }}
                      >
                        {val.date}
                      </TableCell>
                    </TableRow>
                    {val.records.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{ paddingLeft: "20px", minWidth: "20px" }}
                        >
                          {
                            moment(item.checkout_date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD") ?
                              `${item.departure_serial.split('/')[0]}/${hotelName}${item.departure_serial.split('/')[1]}`
                              :
                              item.serial_number
                          }
                        </TableCell>
                        {headCells?.map((data, ind) => (
                          <React.Fragment key={ind}>
                            {data?.id === "room_number" ? (
                              <TableCell
                                style={{
                                  padding: "8px 40px",
                                  minWidth: "80px",
                                }}
                              >
                                {item?.room_no}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {data?.id === "name" ? (
                              <TableCell
                                style={{
                                  padding: "8px 20px",
                                  minWidth: "100px",
                                }}
                              >
                                {item?.name}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {data?.id === "mobile" ? (
                              <TableCell
                                style={{
                                  padding: "8px 0px",
                                  minWidth: "150px",
                                }}
                              >
                                {item?.mobile}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {data?.id === "address" ? (
                              <TableCell
                                style={{
                                  padding: "8px 10px",
                                  minWidth: "150px",
                                }}
                              >
                                {item?.address}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {data?.id === "no_of_persons" ? (
                              <TableCell
                                style={{
                                  padding: "8px 50px",
                                  minWidth: "50px",
                                }}
                              >
                                {item?.no_of_persons
                                  ? item.no_of_persons
                                  : "---"}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {data?.id === "no_of_days" ? (
                              <TableCell
                                style={{
                                  padding: "8px 40px",
                                  minWidth: "80px",
                                }}
                              >
                                {item?.num_of_days ? item.num_of_days : "---"}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {data?.id === "booking_source" ? (
                              <TableCell
                                style={{
                                  padding: "8px 50px",
                                  minWidth: "50px",
                                }}
                              >
                                {item?.booking_source}
                              </TableCell>
                            ) : (
                              ""
                            )}
                            {data?.id === "purpose_of_visit" ? (
                              <TableCell
                                style={{
                                  padding: "8px 40px",
                                  minWidth: "80px",
                                }}
                              >
                                {item?.purpose_of_visit}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {data?.id === "arrival_date" ? (
                              <TableCell
                                style={{
                                  padding: "8px 17px",
                                  minWidth: "120px",
                                }}
                              >
                                {new Date(item?.checkin_date)
                                  .toISOString()
                                  .slice(0, 10) === "1970-01-01"
                                  ? "---"
                                  : //  new Date(item?.checkin_date)
                                  //     .toISOString()
                                  //     .slice(0, 10)}
                                  moment(item?.checkin_date).format(
                                    "DD-MM-YYYY"
                                  )}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {data?.id === "departure_date" ? (
                              <TableCell
                                style={{
                                  padding: "8px 50px",
                                  minWidth: "80px",
                                }}
                              >
                                {new Date(item?.checkout_date)
                                  .toISOString()
                                  .slice(0, 10) === "1970-01-01"
                                  ? "---"
                                  : //   : new Date(item?.checkout_date)
                                  //       .toISOString()
                                  //       .slice(0, 10)
                                  moment(item?.checkout_date).format(
                                    "DD-MM-YYYY"
                                  )}
                              </TableCell>
                            ) : (
                              ""
                            )}

                            {data?.id === "activate" ? (
                              <TableCell>
                                <ToggleSwitch
                                  handleChange={(event) => {
                                    event.stopPropagation();
                                    handleRowActivate(event, item);
                                  }}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                          </React.Fragment>
                        ))}
                      </TableRow>
                    ))}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </TblContainer>
        </Paper>
      ) : (
        ""
      )}

      {props.index == 6 ? (
        <Paper
          elevation={3}
          id={styles.tableDimension}
          sx={{
            borderRadius:
              headCells !== DeletedCells && headCells !== AdminCells
                ? "0px 0px 15px 15px!important"
                : "15px 15px 15px 15px!important",
          }}
        >
          <TablHead />
          {dateRecords?.map((val, index) => {
            return (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    paddingTop: "40px",
                    // paddingX: "10px",
                    fontWeight: 600,
                    position: "sticky",
                    top: 0,
                    zIndex: 0.5,
                    backgroundColor: "#fff",
                    zIndex: 10,
                  }}
                  id={styles.dateHeaderBox}
                >
                  <Box
                    sx={{
                      justifyContent: "space-between",
                      paddingTop: "2%",
                      paddingBottom: "1%",
                      display: "flex",
                      columnGap: 3,
                      alignItems: "center",
                    }}
                    id={styles.dateHeader}
                  >
                    <Box id={styles.dateTab}>{val.date}</Box>
                    <Box width="30%" display='flex' gap='5px' justifyContent='flex-end'>
                      <Button style={{ width: '10rem' }}
                        disabled={dragIndex == index ? false : true}
                        id={
                          dragIndex == index
                            ? styles.dropButton
                            : styles.dropButton2
                        }
                        onClick={() => handleDateButtonClick(val.date)}
                      >
                        Save
                      </Button>
                      {
                        deleteData?.length ? <Button style={{ width: '10rem' }}
                          id={


                            styles.dropButton
                          }
                          onClick={() => multipleDelete()}
                        >
                          Delete
                        </Button> : ''
                      }

                    </Box>
                  </Box>
                </Box>
                <DragDropContext
                  onDragEnd={(result) => handleDragEnd(result, index)}
                >
                  <Droppable droppableId={`droppable-${index}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <TblContainer>
                          <TableBody>
                            {val.records.map((item, itemIndex) => (

                              <Draggable
                                key={itemIndex}
                                draggableId={`draggable-${itemIndex + 1}`}
                                index={itemIndex}
                              >
                                {(provided, snapshot) => (
                                  <TableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                    className={
                                      item.isFavorite
                                        ? styles.favorite
                                        : styles.notFavorite
                                    }
                                  >
                                    <TableCell
                                      style={{
                                        padding: "8px 10px",
                                        minWidth: "65px",
                                      }}
                                    >
                                      {/* <TableCell padding="checkbox"> */}
                                      <Checkbox
                                        color="primary"
                                        onChange={(event) => {
                                          multiSelect(index, item, event.target.checked);

                                        }}

                                        checked={isChecked(item.room_line_id)}


                                      />
                                      {/* </TableCell> */}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: "8px",
                                        minWidth: "10px",
                                      }}
                                    >
                                      <DragHandle />
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        padding: "8px",
                                        minWidth: "10px",
                                        zIndex: 1,
                                        position: "relative",
                                      }}
                                      onClick={() =>
                                        handleFavoriteToggle(
                                          item.room_line_id,
                                          item.isFavorite
                                        )
                                      }
                                    >
                                      <Tooltip title="Favorite">
                                        <IconButton
                                          sx={{
                                            color: item.isFavorite
                                              ? "red"
                                              : "grey",
                                          }}
                                        >
                                          {item.isFavorite ? (
                                            <Favorite />
                                          ) : (
                                            <FavoriteBorder />
                                          )}
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        padding: "8px 10px",
                                        minWidth: "65px",
                                      }}
                                    >
                                      {moment(val.date).format("YYYY-MM-DD") >= moment("2024-04-01").format("YYYY-MM-DD") ?

                                        `${item?.departure_serial?.split('/')[0]}/${hotelName}${item?.departure_serial?.split('/')[1]}`

                                        :

                                        Number(userData.hotelId) === 4
                                          ?
                                          `${moment(val.date).format("MMMYY").toUpperCase()}/${hotelName}${val.records.length--}`
                                          : `${moment(val.date).format("DD-MMM").toUpperCase()}-${(itemIndex + 1)}`
                                      }

                                    </TableCell>
                                    {headCells?.map((data, ind) => (
                                      <React.Fragment key={ind}>
                                        {data?.id === "room_number" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 40px",
                                              minWidth: "60px",
                                            }}
                                          >
                                            {item?.room_no}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "name" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 20px",
                                              minWidth: "130px",
                                            }}
                                          >
                                            {item?.name}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}

                                        {data?.id === "mobile" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 0px",
                                              minWidth: "160px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {item?.mobile}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "address" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 10px",
                                              minWidth: "220px",
                                            }}
                                          >
                                            {item?.address}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "no_of_days" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 0px",
                                              minWidth: "130px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {item?.num_of_days}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "booking_source" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 0px",
                                              minWidth: "150px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {item?.booking_source}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "departure_date" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 50px",
                                              minWidth: "100px",
                                            }}
                                          >
                                            {new Date(item?.checkout_date)
                                              .toISOString()
                                              .slice(0, 10) === "1970-01-01"
                                              ? "---"
                                              : moment(
                                                item?.checkout_date
                                              ).format("DD-MM-YYYY")}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                        {data?.id === "action" ? (
                                          <TableCell
                                            style={{
                                              padding: "8px 10px",
                                              minWidth: "40px",
                                            }}
                                          >
                                            {userData?.role == 1 && (
                                              <Tooltip title="Delete">
                                                <DeleteIcon
                                                  onClick={(event) =>
                                                    handleRowDelete(event, item)
                                                  }
                                                  sx={{
                                                    marginLeft: "5px",
                                                    "&:hover": {
                                                      color: CLR_ORANGE,
                                                    },
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                          </TableCell>
                                        ) : (
                                          ""
                                        )}
                                      </React.Fragment>
                                    ))}
                                  </TableRow>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </TableBody>
                        </TblContainer>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </React.Fragment>
            );
          })}
        </Paper>
      ) : (
        ""
      )}

      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label={resDlgCnt}
        // width="400px"
        height="150px"
        isIcon={true}
      />
      <CustomDiolog
        label={`Are you sure you want to unblock ${customerToBlock.name}`}
        open={unBlockModal}
        setOpen={setUnBlockModal}
        customFunction={handleBlockCustomer}
        confirmLabel={"Yes"}
        cancelLabel={"No"}
      />

      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={"Cannot Reorder"}
      />

      <Modal open={selectedImage} onClose={handleCloseModal}>
        <Box
          sx={{
            margin: "auto",
            height: "500px",
            width: "500px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Carousel
            sx={{
              width: "500px",
              borderRadius: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
            autoPlay={false}
            swipe={true}
            indicators={false}
          >
            {selectedImages?.map((val, imageIndex) => (
              <img
                height="500px"
                width="500px"
                key={imageIndex}
                src={val.url}
                style={{
                  borderRadius: "10px",
                  boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.5)",
                }}
              />
            ))}
          </Carousel>
        </Box>
      </Modal>

      <Modal open={blockModal} onClose={() => setBlockModal(false)}>
        <Box
          sx={{
            margin: "auto",
            width: "500px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "#FFFFFF",
            borderRadius: "10px",
            padding: "2%"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            <Typography sx={{
              color: "#000",
              margin: "8px",
              fontSize: "18px!important",
            }}>
              {"Please enter the reason for blocking "}
              <span style={{ fontWeight: 600 }}>{customerToBlock?.name}</span>
            </Typography>
            <TextField
              fullWidth
              size="small"
              placeholder="Reason for Blocking"
              name="blocking_reason"
              value={blockingReason}
              height="5px"
              onChange={(e) => {
                setBlockingReason(e.target.value);
              }}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={5}
            >
              <CustomCancelButton
                label="Cancel"
                width="130px"
                handleChange={() => {
                  setBlockModal(false);
                }}
              />
              <CustomSaveButton
                width="130px"
                label="Save"
                handleChange={handleBlockCustomer}
              />
            </Stack>
          </Box>
        </Box>
      </Modal >
    </>
  );
}

export default AllTable;
