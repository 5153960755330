import * as types from "../type";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
const getUsers = (users) => ({
  type: types.GET_DASHBOARD_DATA,
  payload: users,
});
export const loadDashBoardData = () => {
  return async function (dispatch) {
    await trackPromise(
      axios
        .get(`${process.env.REACT_APP_API_URL}/dashboard/getDashBoardStats`)
        .then((res) => {
          dispatch(getUsers(res?.data));
        })
        .catch((err) => { })
    );
  };
};
export const getDashBoardData = async () => {

  return axios
    .get(`${process.env.REACT_APP_API_URL}/dashboard/getDashBoardStats`);


};
