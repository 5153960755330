import { combineReducers } from "redux";
import guestReducer from "./guestInfo/guest.info.reducer";
import dashboardReducer from "./dashboard.reducer";
import profileReducer from "./profile.reducer";
import loginReducer from "./login.reducer";
import bookingReducer from "./booking.reducer";
import printDataReducer from "./printData.reducer";

const RootReducer = combineReducers({
  dashBoardReducer: dashboardReducer,
  profileReducer: profileReducer,
  loginReducer: loginReducer,
  bookingReducer: bookingReducer,
  printDataReducer: printDataReducer,
});
export default RootReducer;
