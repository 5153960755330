import { Button } from "@mui/material";
import React from "react";
import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../utils/colors";

function CustomSaveButton(props) {
  return (
    <Button
      variant="contained"
      disabled={props?.disable}
      sx={{
        paddingLeft: "10px",
        paddingRight: "10px",
        background: CLR_ORANGE,
        borderRadius: "4px",
        width: props?.width,
        shadowColor: "1px 1px 6px #E98D12",
        "&:hover": {
          color: CLR_ORANGE,
          background: CLR_LIGHT_WHITE,
        },
      }}
      onClick={props?.handleChange}
    // onClick={() => {
    //   webcomponent.capture();
    // }}
    >
      {props?.label}
    </Button>
  );
}

export default CustomSaveButton;
