import axios from "axios";
import staticTexts from "../utils/static-texts";
axios.interceptors.request.use(
  (req) => {
    const token = localStorage.getItem(staticTexts.ACCESS_TOKEN_TEXT);
    let data = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
    // const user_id = data.userId;

    if (req.url.includes("api.ocr.space")) {
      req.headers.apikey = "0a139a90c688957";
    }

    if (token) {
      req.headers.Authorization = token;
      req.headers.user_id = data.userId;
      req.headers.hotel_id = data.hotelId;
    } else {
      req.headers.Authorization = null;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
    }
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);
