import { OutlinedInput } from "@mui/material";
import React from "react";
import { CLR_ORANGE } from "../utils/colors";

function CustomTextField({
  borderRadius,
  height,
  name,
  border,
  type,
  // inputprops,
  register,
  handleChange,
  other,
  value,
  width,
  handleKeyDown,
  autoFocus,
  disabled,
  inputProps,
  opacity,
}) {
  return (
    <OutlinedInput
      type={type}
      name={name}
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      inputProps={inputProps}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-input": { height: height },

        "& .MuiInputBase-input": {},
        "& .MuiOutlinedInput-notchedOutline": {
          border: border,
          borderRadius: borderRadius,
        },
      }}
      style={{
        background: "#FFFFFF",
        opacity: opacity ? opacity : 1,
        borderRadius: "5px",
        border: "none",
        borderColor: CLR_ORANGE,
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      // {...other}
      // InputProps={inputprops}
      //  {...register(name)}
    />
  );
}

export default CustomTextField;
