import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import CustomCancelButton from "../CustomCancelButton";
import CustomSaveButton from "../CustomSaveButton";
import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../../utils/colors";
import CustomLabel from "../CustomLabel";
import { CancelRounded } from "@mui/icons-material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";

import ResultDiologueBox from "../ResultDiologueBox";
import {
  getArrivalSign,
  getBookingsDetailsData,
  RoomShiftData,
} from "../../services/bookingServices";
import { trackPromise } from "react-promise-tracker";
import CustomSelectValue from "../CustomSelectValue";
import CustomSelect from "../CustomSelect";
import { getAvailableRoomStatics } from "../../services/roomSevices";
import CustomSnackBar from "../CustomSnackBar";
import styles from "../../pages/Booking_form/bookingForm.module.css";

function RoomShiftDialog(props) {
  const [resDlgOpn, setResDlgOpn] = useState(false);
  const [roomMuiOpt, setRoomMuiOpt] = useState([]);
  const [roomCnt, setRoomCnt] = useState(1);
  const [arr, setArr] = useState([]);
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);

  const [removeIcnFlg, setRemoveIcnFlg] = useState(false);
  const [fillErr, setFillErr] = useState(false);

  const initialFvalues = {
    no_of_rooms: 0,
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        booking_status: "",
        booking_id: null,
        check_in_date_time: null,
        check_out_date_time: null,
        arrival_signature_image_url: "",
      },
    ],
    room_details_data: "",
  };

  let initialErrors = {
    no_of_rooms: "",
    rooms_details: [
      {
        type: "",
        values: [],
        value: "",
        no_of_persons: 0,
        booking_status: "",
        booking_id: null,
        check_in_date_time: null,
        check_out_date_time: null,
        arrival_signature_image_url: "",
      },
    ],
    room_details_data: "",
  };

  const [errors, setErrors] = useState({ ...initialErrors });
  const [values, setValues] = useState(initialFvalues);
  const [room, setRoom] = useState();

  useEffect(() => {
    if (props?.InfoOpen) {
      setRoom(true);
      getRoomDetailsStatics(0);
      props?.setGetInfo(!props?.InfoOpen);
    }
  });

  const getRoomDetailsStatics = async (val) => {
    await trackPromise(
      getAvailableRoomStatics(val)
        .then((res) => {
          let { data, error } = res?.data;

          let dataArr = [];
          let muiArr = [];

          data?.map((item) => {
            let obj = {};
            let muiObj = {};
            obj.type = item?.type;
            obj.values = JSON.parse(item?.values);
            muiObj.value = item?.type;
            dataArr.push(obj);
            muiArr.push(muiObj);
          });
          setArr(dataArr);
          setRoomMuiOpt(muiArr);
        })
        .catch((err) => { })
    );
  };

  const handleChangeRoomtypeValue = (e, index) => {
    if (e.target.value) {
      let data = { ...values };

      var roomTypeId = 0;

      data?.rooms_details[index].values?.map((item) => {
        if (item?.id == e.target.value) {
          roomTypeId = item?.id;
        }
      });
      data.rooms_details[index].id = roomTypeId;
      data.rooms_details[index].value = e.target.value;
      setValues(data);
    }
  };

  const validateForm = async (status) => {
    let DataArr = [];

    props?.data?.map(async (item, index) => {
      if (item?.booking_id) {
        const url = item?.arrival_signature_image_url;
        const regex = /\/([\w-]+)\?/;
        const arrival_signature = regex.exec(url)[1];

        let data = { ...values };
        data.rooms_details[0].check_in_date_time = item?.checkin_date;
        data.rooms_details[0].booking_id = item?.booking_id;
        data.rooms_details[0].booking_status = item?.room_booking_status;
        data.rooms_details[0].arrival_signature_image_url = arrival_signature;

        setValues({ ...data });

        DataArr.push({
          booking_id: item?.booking_id,
          old_room_type_id: item?.room_type_id,
          room_details: values?.rooms_details,
          serial_number: item?.serial_number,
          room_line_id: item?.room_line_id,
        });
      }
    });

    let stateValue = { ...values };

    let newerrors = { ...initialErrors };

    if (status === "arrival") {
      let room_error_flag = false;
      let no_of_person_flag = false;

      let availableRooms = [];

      for (var i = 0; i < stateValue?.rooms_details?.length; i++) {
        if (
          stateValue.rooms_details[i].type.length <= 0 ||
          !stateValue.rooms_details[i].hasOwnProperty("value") ||
          stateValue.rooms_details[i].value.length <= 0 ||
          !stateValue.rooms_details[i].check_in_date_time
        ) {
          room_error_flag = true;

          break;
        } else if (
          Number(stateValue?.rooms_details[i].no_of_persons) > 10 ||
          Number(stateValue?.rooms_details[i].no_of_persons) < 1
        ) {
          no_of_person_flag = true;
        } else {
          availableRooms.push(stateValue?.rooms_details[i].value);
        }
      }
      if (
        room_error_flag &&
        new Set(availableRooms).size !== availableRooms.length
      ) {
        newerrors.room_details_data =
          "Fill The Details and Room Number Should be Unique";
        setTstContent(newerrors.room_details_data);
      } else if (no_of_person_flag) {
        newerrors.room_details_data =
          "No of Persons Should be in between 1-10 ";
        setTstContent(newerrors.room_details_data);
      } else if (room_error_flag) {
        newerrors.room_details_data = "Fill The Details";
        setTstContent(newerrors.room_details_data);
      } else if (new Set(availableRooms).size !== availableRooms.length) {
        newerrors.room_details_data = "Room Number should be unique";
        setTstContent(newerrors.room_details_data);
      } else {
        newerrors.room_details_data = "";
        newerrors.rooms_details = "";
      }

      setErrors({ ...newerrors });

      for (let key in newerrors) {
        if (newerrors[key] != "") {
          // setTstContent("Please fill all the mandatory fields ");
          window.scrollTo(0, 0);
          setTstOpn(true);
          return;
        }
      }
      RoomShift(DataArr);
    }
  };

  // console.log("no of rooms", values.rooms_details.length);

  const RoomShift = async (bookingLines) => {
    if (bookingLines[0].old_room_type_id) {
      await trackPromise(
        RoomShiftData(bookingLines)
          .then((res) => {
            setTimeout(() => {
              setResDlgOpn(true);
            }, 1500);
            setTimeout(() => {
              setResDlgOpn(false);
            }, 3500);
            props?.setOpen(false);

            if (props?.setToggleVal !== undefined) {
              props?.setToggleVal(!props?.toggleVal);
            }
            setValues(initialFvalues);
            setRoomCnt(1);
            setRemoveIcnFlg(false);
          })
          .catch((err) => { })
      );
    }
  };

  const handleChangeRoomtype = (e, index) => {
    if (e.target.value) {
      let data = { ...values };
      data.rooms_details[index].type = e.target.value;

      setValues(data);

      let new_rooms = [];
      arr.map((obj) => {
        if (obj.type === e.target.value) {
          let val = obj.values;
          val?.map((data) => {
            new_rooms.push({
              value: data?.room__number,
              id: data?.room_type_id,
            });
          });
        }
      });
      data.rooms_details[index].values = new_rooms;
      setValues({ ...data });
    }
  };

  // console.log("///values...", values);
  // console.log("errors...", errors);

  const validateCurrentRoomStats = () => {
    let newvalues = { ...values };
    let currentRoomstats = newvalues?.rooms_details;
    let signFlag = true;
    currentRoomstats.map((item) => {
      if (
        item?.type == "" ||
        item?.values?.length < 1 ||
        item?.no_of_persons < 1
      ) {
        signFlag = false;
      }
    });
    return signFlag;
  };

  return (
    <>
      <Dialog
        disableEnforceFocus
        open={props?.open}
        sx={{
          "& .MuiDialogContent-root": {
            border: "none",
          },
          "& .MuiDialog-paper": {
            // maxWidth: "400px",
          },
          "& .MuiPaper-root": {
            background: CLR_LIGHT_WHITE,
            borderRadius: "20px",
            // minWidth:"400px"
          },
        }}
      >
        <DialogTitle sx={{ m: 0, padding: "10px 10px 0px 10px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <CustomLabel color="black" label={props?.label} />
            <Tooltip title="Close">
              <CancelRounded
                style={{ width: "20px", height: "24px", cursor: "pointer" }}
                onClick={(item) => {
                  setValues(initialFvalues);

                  setRoomCnt(1);
                  setRemoveIcnFlg(false);
                  props?.setOpen(false);
                }}
              />
            </Tooltip>
          </Box>
        </DialogTitle>
        <Box>
          <DialogContent>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <TableContainer sx={{ textAlign: "center" }}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#E98D12",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#E98D12",
                          textAlign: "center",
                        }}
                      >
                        Room Number
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#E98D12",
                          textAlign: "center",
                        }}
                      >
                        Room Type
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#E98D12",
                          textAlign: "center",
                        }}
                      >
                        Source
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#E98D12",
                          textAlign: "center",
                        }}
                      >
                        No of person
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {props?.data?.map((row, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.room_no}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.room_type_name}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.booking_source}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          {row?.no_of_persons}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Box>
            <>
              <Box>
                {[...Array(roomCnt)].map((elementInArray, index) => (
                  <Box
                    sx={{
                      color: "white",

                      marginBottom: "12px",
                    }}
                    key={index}
                  >
                    <CustomLabel
                      color="#E98D12"
                      fontSize="small"
                      label={`Room Details ${index + 1}`}
                    />
                    <Box
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <CustomSelectValue
                        placeholder={"Room Type"}
                        id="room-det"
                        border="1px solid #707070"
                        defaultValue=""
                        borderRadius="5px"
                        padding="5px 12px 4px 12px"
                        marginRight="5px"
                        value={
                          values?.rooms_details?.length > 0
                            ? values?.rooms_details[index]?.type
                            : ""
                        }
                        name="name"
                        muiOptions={roomMuiOpt ?? ""}
                        handleChange={(e) => {
                          handleChangeRoomtype(e, index);
                        }}
                      />
                      <CustomSelect
                        placeholder="Room Number"
                        style={{ position: "relative" }}
                        id="room-det"
                        border="1px solid #707070"
                        defaultValue=""
                        borderRadius="5px"
                        value={values?.rooms_details[index]?.value || ""}
                        name="room_number"
                        padding="5px 12px 4px 12px"
                        marginRight="5px"
                        muiOptions={values?.rooms_details[index]?.values ?? ""}
                        handleChange={(e) => {
                          handleChangeRoomtypeValue(e, index);
                        }}
                      />

                      {removeIcnFlg && (
                        <Box>
                          <RemoveCircleRoundedIcon
                            style={{
                              color: "#E98D12",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (roomCnt > 1) {
                                let newvalues = { ...values };

                                const indexOfObject =
                                  newvalues?.rooms_details?.findIndex(
                                    (object) => {
                                      if (
                                        object?.type ===
                                        values?.rooms_details[index].type &&
                                        object?.values?.length == 0
                                      ) {
                                        return true;
                                      } else if (
                                        object?.type ===
                                        values?.rooms_details[index].type &&
                                        object?.values[0].id ===
                                        values?.rooms_details[index].values[0]
                                          .id
                                      ) {
                                        return true;
                                      }
                                    }
                                  );

                                newvalues?.rooms_details.splice(index, 1);

                                setValues({ ...newvalues });
                                setRoomCnt(roomCnt - 1);
                                if (roomCnt - 1 == 1) {
                                  setRemoveIcnFlg(false);
                                } else {
                                  setRemoveIcnFlg(true);
                                }
                              } else {
                                setRemoveIcnFlg(false);
                              }
                            }}
                          />
                        </Box>
                      )}

                      <Box>
                        {room && (
                          <AddCircleRoundedIcon
                            style={{
                              color: "#E98D12",
                              marginTop: "6px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (roomCnt < 5 && validateCurrentRoomStats()) {
                                const url =
                                  props?.data[0].arrival_signature_image_url;
                                const regex = /\/([\w-]+)\?/;
                                const arrival_signature = regex.exec(url)[1];

                                let newvalues = { ...values };
                                newvalues?.rooms_details.push({
                                  type: "",
                                  values: [],
                                  no_of_persons: 0,
                                  booking_status:
                                    props?.data[0]?.room_booking_status,
                                  booking_id: props?.data[0]?.booking_id,
                                  check_in_date_time:
                                    props?.data[0]?.checkin_date,
                                  check_out_date_time: null,
                                  arrival_signature_image_url:
                                    arrival_signature,
                                });
                                setValues({ ...newvalues });
                                setRoomCnt(roomCnt + 1);
                                if (roomCnt + 1 > 1) {
                                  setRemoveIcnFlg(true);
                                } else {
                                  setRemoveIcnFlg(false);
                                }
                              } else {
                                setFillErr(true);
                              }
                            }}
                          />
                        )}
                      </Box>
                    </Box>

                    <Box width="46%">
                      <CustomLabel
                        color="#E98D12"
                        fontSize="small"
                        label="No of Persons*"
                      />
                      <TextField
                        name="no_of_persons"
                        type="text"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            height: "38px",
                            width: "100%",
                          },
                          background: "#FFFFFF",
                          borderRadius: "5px",
                        }}
                        onChange={(e) => {
                          let data = { ...values };
                          let val = e.target.value;
                          val = val.replace(/[^\w ]/g, "");
                          val = val.replace(/^\s+|\s+$/gm, "");
                          if (val?.length >= 2) {
                            val = val.substr(0, 2);
                          }
                          const re = /^[0-9\b]+$/;

                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            data.rooms_details[index].no_of_persons =
                              Number(val);
                          }
                          setValues({ ...data });
                        }}
                        placeholder="0"
                        variant="outlined"
                        value={values?.rooms_details[index]?.no_of_persons}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  let data = { ...values };
                                  let room_stat = data?.rooms_details;
                                  if (room_stat[index]?.no_of_persons < 99) {
                                    room_stat[index].no_of_persons++;
                                  }
                                  setValues({
                                    ...data,
                                    rooms_details: room_stat,
                                  });
                                  setFillErr(false);
                                }}
                              >
                                <AddIcon
                                  id={styles.orange}
                                  sx={{
                                    cursor: "pointer",
                                    color: CLR_ORANGE,
                                    fontSize: "16px",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  if (
                                    values?.rooms_details[index]
                                      ?.no_of_persons >= 1
                                  ) {
                                    let data = { ...values };
                                    let room_stat = data?.rooms_details;
                                    room_stat[index].no_of_persons--;
                                    setValues({
                                      ...data,
                                      rooms_details: room_stat,
                                    });
                                  }
                                }}
                              >
                                <RemoveIcon
                                  id={styles.orange}
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                    fontSize: "16px",
                                    color: CLR_ORANGE,
                                    // width: "70%",
                                  }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </>
          </DialogContent>
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
            sx={{
              paddingBottom: "25px",
              marginTop: "10px",
            }}
          >
            <CustomCancelButton
              label="Cancel"
              width="130px"
              handleChange={() => {
                setValues(initialFvalues);
                setRoomCnt(1);
                setRemoveIcnFlg(false);
                props?.setOpen(false);
              }}
            />
            <CustomSaveButton
              width="130px"
              label="Save"
              handleChange={() => {
                validateForm("arrival");
              }}
            />
          </Stack>
        </Box>
      </Dialog>
      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label="Room Shifted Successfully !"
        width="400px"
        height="150px"
      />
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />
    </>
  );
}
export default RoomShiftDialog;
