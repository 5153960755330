import * as types from "../type";
import axios from "axios";
import staticTexts from "../../utils/static-texts";
import { logoutUser } from "../../services/userService";

export const login = (data) => {
  return async function (dispatch) {
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/login`, data)
      .then((res) => {
        localStorage.setItem(
          staticTexts.USER_DATA,
          JSON.stringify(res?.data?.data)
        );
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: res?.data,
        });
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch({
          type: types.LOGIN_FAIL,
        });
        return Promise.reject();
      });
  };
};

export const logout = () => {
  return async function (dispatch) {
    logoutUser()
      .then((res) => {
        localStorage.clear();
        dispatch({
          type: types.LOGOUT_SUCCESS,
        });
        setTimeout(() => {
          window.location.assign("/login");
        }, 100);

        return Promise.resolve();
      })
      .catch((err) => {
        localStorage.removeItem(staticTexts.ACCESS_TOKEN_TEXT);
        dispatch({
          type: types.LOGIN_FAIL,
        });
        return Promise.reject();
      });
  };
};
