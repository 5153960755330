const AllCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "no_of_persons",
    label: "No of persons",
    disableSorting: true,
  },
  {
    id: "purpose_of_visit",
    label: "Purpose of visit",
    disableSorting: true,
  },
  {
    id: "occupation",
    label: "Occupation",
    disableSorting: true,
  },
  {
    id: "arrival_date",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "arrival_time",
    label: "Arrival time",
    disableSorting: true,
  },
  {
    id: "departure_date",
    label: "Departure date",
    disableSorting: false,
  },
  {
    id: "departure_time",
    label: "Departure time",
    disableSorting: false,
  },

  {
    id: "allTabAction",
    label: "Action",
    disableSorting: true,
  },
];

const ArrivalCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: true,
  },
  {
    id: "occupation",
    label: "Occupation",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "no_of_persons",
    label: "No of persons",
    disableSorting: true,
  },
  {
    id: "purpose_of_visit",
    label: "Purpose of visit",
    disableSorting: true,
  },

  {
    id: "arrival_date",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "arrival_time",
    label: "Arrival time",
    disableSorting: true,
  },
  {
    id: "arrival_signature_image",
    label: "Signature",
    disableSorting: true,
  },
  {
    id: "action",
    label: "Action",
    disableSorting: true,
  },
];
const DepartureCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: true,
  },
  {
    id: "occupation",
    label: "Occupation",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "no_of_persons",
    label: "No of persons",
    disableSorting: true,
  },
  {
    id: "purpose_of_visit",
    label: "Purpose of visit",
    disableSorting: true,
  },
  {
    id: "heading_to",
    label: "Heading to",
    disableSorting: true,
  },

  {
    id: "arrival_date",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "arrival_time",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "departure_date",
    label: "Departure date",
    disableSorting: false,
  },
  {
    id: "departure_time",
    label: "Departure time",
    disableSorting: false,
  },
  {
    id: "departure_signature_image",
    label: "Signature",
    disableSorting: true,
  },
  {
    id: "action",
    label: "Action",
    disableSorting: true,
  },
];
const DeletedCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "no_of_days",
    label: "No of days",
    disableSorting: true,
  },
  {
    id: "booking_source",
    label: "Booking Source",
    disableSorting: false,
  },
  {
    id: "no_of_persons",
    label: "No of persons",
    disableSorting: true,
  },
  {
    id: "purpose_of_visit",
    label: "Purpose of visit",
    disableSorting: true,
  },

  {
    id: "arrival_date",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "departure_date",
    label: "Departure date",
    disableSorting: false,
  },

  {
    id: "activate",
    label: "Restore",
    disableSorting: true,
  },
];

const DraftCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "no_of_persons",
    label: "No of persons",
    disableSorting: true,
  },
  {
    id: "purpose_of_visit",
    label: "Purpose of visit",
    disableSorting: true,
  },

  {
    id: "arrival_date",
    label: "Arrival date",
    disableSorting: true,
  },
  {
    id: "departure_date",
    label: "Departure date",
    disableSorting: false,
  },

  {
    id: "action",
    label: "Action",
    disableSorting: true,
  },
];

const DocumentCells = [
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: false,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "document",
    label: "Document",
    disableSorting: true,
  },
];

const BlackListCells = [
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: false,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "coutry_name",
    label: "Country",
    disableSorting: true,
  },
  {
    id: "state_name",
    label: "State",
    disableSorting: true,
  },
  {
    id: "city_name",
    label: "City",
    disableSorting: true,
  },
  {
    id: "document",
    label: "Document",
    disableSorting: true,
  },
  {
    id: "blackListAction",
    label: "Action",
    disableSorting: true,
  },
];

const AdminCells = [
  {
    id: "room_number",
    label: "Room number",
    disableSorting: true,
  },
  {
    id: "address",
    label: "Address",
    disableSorting: true,
  },
  {
    id: "no_of_days",
    label: "No of days",
    disableSorting: true,
  },
  {
    id: "mobile",
    label: "Mobile",
    disableSorting: false,
  },
  {
    id: "name",
    label: "Name",
    disableSorting: true,
  },
  {
    id: "booking_source",
    label: "Booking Source",
    disableSorting: false,
  },
  {
    id: "departure_date",
    label: "Departure date",
    disableSorting: false,
  },
  {
    id: "action",
    label: "Action",
    disableSorting: true,
  },
];

export {
  AllCells,
  ArrivalCells,
  DraftCells,
  DeletedCells,
  DepartureCells,
  DocumentCells,
  AdminCells,
  BlackListCells
};
