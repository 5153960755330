import * as types from "../type";
const initialState = {
  data: [],

  loading: true,
};
const printDataReducer = (state = initialState, action) => {
  switch (action?.type) {
    case types.SAVE_PRINT_DATA:
      return { ...state, data: action?.payload };
    case types.GET_PRINT_DATA:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default printDataReducer;
