import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomLabel from "../../components/CustomLabel";
import CustomTextField from "../../components/CustomTextField";
import styles from "./roomsettingsform.module.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import RoomSection from "../../components/RoomSection/RoomSection";
import Appbar from "../../components/AppBar/Appbar";
import { CLR_ORANGE } from "../../utils/colors";
import CustomSaveButton from "../../components/CustomSaveButton";
import CustomCancelButton from "../../components/CustomCancelButton";
import DeleteCatDiologueBox from "../../components/DeletCatDiologueBox";
import Alert from "@mui/material/Alert";
import DeleteRoomDiologueBox from "../../components/DeletRoomDiologueBox";
import { trackPromise } from "react-promise-tracker";
import { addEditRooms, getRoomStatics } from "../../services/roomSevices";
import CustomSnackBar from "../../components/CustomSnackBar";
import ResultDiologueBox from "../../components/ResultDiologueBox";
function RoomSettingsForm() {
  const navigate = useNavigate();
  const [types, setTypes] = useState([]);
  const [catName, setCatName] = useState(null);
  const [totalRoomCount, setTotalRoomCount] = useState(0);
  const [deleteCatIndex, setDeleteCatIndex] = useState(-1);
  const [catDlgOpn, setCatDlgOpn] = useState(false);
  const [delteDlgWrd, setDelteDlgWrd] = useState(false);
  const [tstOpn, setTstOpn] = useState(false);
  const [tstContent, setTstContent] = useState(null);
  const [roomDlgOpn, setRoomDlgOpn] = useState(false);
  const [formatData, setFormatData] = useState([]);

  const [resDlgOpn, setResDlgOpn] = useState(false);

  const [resultLabel, setResultLabel] = useState(null);
  const [delRoomObj, setDelRoomObj] = useState({
    id: "",
    index: "",
  });
  const [initialValues, setInitialValues] = useState(null);
  useEffect(() => {
    getRoomDetailsStatics();
  }, []);

  const getRoomDetailsStatics = async (val) => {
    await trackPromise(
      getRoomStatics()
        .then((res) => {
          let { data, error } = res?.data;
          let dataArr = [];

          data.map((item) => {
            let obj = {};
            obj.type = item?.type;
            obj.values = JSON.parse(item?.values);
            dataArr.push(obj);
          });
          setInitialValues(dataArr);

          setTypes(dataArr);
          findTotalRooms(dataArr);
          formatInitalValues(dataArr);
        })
        .catch((err) => {})
    );
  };

  const findTotalRooms = (val) => {
    let len = 0;
    val?.map((data) => {
      len += data?.values?.length;
    });
    setTotalRoomCount(len);
  };

  const formatInitalValues = (val) => {
    let arr = [];
    let obj;
    val?.map((data) => {
      data?.values?.map((item) => {
        obj = {
          room__number: item?.room__number,
          room_type_id: item?.room_type_id,
          type: data?.type,
          init_status: 1,
          action: "",
        };
        arr.push(obj);
      });
    });
    setFormatData(arr);
  };

  const addFinalData = (room__number, type) => {
    let newData = [...formatData];

    let flag = false;

    newData?.map((item) => {
      if (String(room__number) === item?.room__number && type == item?.type) {
        item.action = "U";
        flag = true;
      }
    });

    if (!flag) {
      newData.push({
        room__number: String(room__number),
        room_type_id: 0,
        type: type,
        init_status: 0,
        action: "I",
      });
    }

    setFormatData(newData);
  };
  const deleteFinalData = (room__number, type) => {
    let newData = [...formatData];

    let flag = false;

    newData?.map((item) => {
      if (
        String(room__number) === item?.room__number &&
        type == item?.type &&
        item?.init_status === 1
      ) {
        item.action = "D";
        flag = true;
      }
    });

    if (!flag) {
      newData?.forEach((item, index) => {
        if (String(room__number) === item?.room__number && item?.type == type) {
          newData.splice(index, 1);
        }
      });
    }
    setFormatData(newData);
  };
  const [catopen, setCatOpen] = useState(false);

  const handleClickCategory = () => {
    setCatOpen(true);
  };

  const validationSchema = yup.object().shape({
    total_rooms: yup.number().required("Required"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHandler = (data) => {
    data.height = 5.4;
    reset();
  };

  const handleClickEditable = (e, index) => {
    e.preventDefault();
    let newtypes = [...types];
    newtypes?.map((item, itemindex) => {
      if (itemindex === index) {
        newtypes[index]["values"].push({ room__number: "", room_type_id: 0 });
      }
    });
    setTypes(newtypes);
  };
  const handleToAddRoom = (e, index) => {
    let newtypes = [...types];
    if (validateDuplication(e.target.value)) {
      newtypes?.map((item, itemindex) => {
        if (itemindex === index) {
          newtypes[index]["values"].push({
            room__number: e.target.value,
            room_type_id: 0,
          });
          addFinalData(e.target.value, newtypes[itemindex].type);
        }
      });
      setTypes(newtypes);
      setTotalRoomCount(totalRoomCount + 1);
    } else {
      window.scroll(0, 0);
      setTstContent("Room Number already exists");
      setTstOpn(true);
    }
  };

  const focusDeleteCategory = () => {
    if (setDeleteCatIndex);
  };

  const handleChangeValue = (event, index, objIndex) => {
    let newtypes = [...types];

    newtypes?.map((item, itemindex) => {
      if (itemindex === index) {
        newtypes[index]["values"][objIndex].room__number = event.target.value;
      }
    });
    setTypes(newtypes);
  };

  const handleClickSave = async () => {
    let flag = false;
    types?.map((item) => {
      if (item?.values?.length === 0) {
        flag = true;
        return;
      }
    });
    if (flag) {
      setTstContent("Room Type should have atleast one room");
      setTstOpn(true);
      return;
    } else {
      let data = [...formatData];
      let apiInputData = data.filter((item) => item?.action != "");

      //api calling
      await trackPromise(
        addEditRooms(apiInputData)
          .then((res) => {
            if (!!res?.data?.queryResponse?.msg) {
              setResultLabel(res?.data?.queryResponse?.msg);
            } else {
              setResultLabel("Data Saved Successfully");
            }
            setResDlgOpn(true);
            setTimeout(() => {
              navigate(-1);
            }, 3000);
          })
          .catch((err) => {})
      );
    }
  };

  const handleClickAddCategory = () => {
    let data = [...types];
    let flag = true;

    data?.map((item) => {
      if (item?.type === catName) {
        flag = false;
        return;
      }
    });
    if (!flag) {
      setTstContent("Room Type already exists");
      setTstOpn(true);
      return;
    }
    if (data && flag) {
      data.push({
        type: catName,
        values: [],
      });
      setTypes(data);
    }
  };

  const validateDuplication = (val) => {
    let newtypes = [...types];
    let flag = true;
    newtypes?.map((item) => {
      if (item?.values?.length > 0) {
        item?.values?.map((value) => {
          if (value?.room__number == val) {
            flag = false;
            return flag;
          }
        });
      }
    });
    return flag;
  };

  const validateDeletingRooms = (val) => {
    let new_types = [...types];
    new_types[delRoomObj.index].values.forEach((item, index) => {
      if (item.room__number === delRoomObj.id) {
        new_types[delRoomObj.index].values.splice(index, 1);
      }
    });
    deleteFinalData(delRoomObj.id, new_types[delRoomObj.index].type);
    setTypes(new_types);
    findTotalRooms(new_types);
  };

  const validateDeletingCats = (val) => {
    let new_types = [...types];
    deleteCatFinalData(new_types[deleteCatIndex].type);
    new_types.splice(deleteCatIndex, 1);

    setTypes(new_types);
    findTotalRooms(new_types);
  };

  const deleteCatFinalData = (type) => {
    let newData = [...formatData];

    newData?.map((item) => {
      if (type === item?.type && item?.init_status === 1) {
        item.action = "D";
      }
    });

    newData?.forEach((item, index) => {
      if (type === item?.type && item?.init_status !== 1) {
        newData.splice(index, 1);
      }
    });

    setFormatData(newData);
  };

  return (
    <Box id={styles.mainbox} flex={4}>
      <Appbar logo="true" />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Box>
          <Stack sx={{ margin: "0px!important" }}>
            <Box
              id={styles.firstbox}
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                style={{ width: "14px", height: "24px", cursor: "pointer" }}
                src="/images/Icon_left_arrow.png"
              />
              <CustomLabel
                style={{ paddingLeft: "30px!important" }}
                label="Room Settings"
              />
            </Box>
          </Stack>
          <Grid
            container
            spacing={3}
            style={{ paddingLeft: "10px", paddingTop: "10px" }}
          >
            <Grid item lg={6} md={6} sm={12}>
              <Box
                style={{
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                    // fontSize: "25px",
                    paddingRight: "10px",
                    fontFamily: "normal normal medium 29px/43px Poppins",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Total number of rooms :
                </Typography>
                <Typography
                  sx={{
                    color: CLR_ORANGE,
                    textAlign: "left",
                    fontSize: "30px!important",
                  }}
                >
                  {totalRoomCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Grid container style={{ paddingLeft: "10px", paddingTop: "20px" }}>
              {types?.map((data, blockIndex) => {
                return (
                  <RoomSection
                    key={blockIndex}
                    blockIndex={blockIndex}
                    data={data}
                    setCatDlgOpn={setCatDlgOpn}
                    setRoomDlgOpn={setRoomDlgOpn}
                    setDeleteCatIndex={setDeleteCatIndex}
                    deleteCatIndex={deleteCatIndex}
                    handleClickEditable={handleClickEditable}
                    handleChangeValue={handleChangeValue}
                    handleToAddRoom={handleToAddRoom}
                    setDelteDlgWrd={setDelteDlgWrd}
                    setDelRoomObj={setDelRoomObj}
                  />
                );
              })}
            </Grid>
          </Box>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
            style={{ paddingTop: "20px" }}
          >
            {/* <CustomCancelButton
              label="CANCEL"
              width="160px"
              handleChange={() => {
                setCatOpen(false);
             
                setTypes(initialValues);
                findTotalRooms(initialValues);
              }}
            /> */}

            <CustomSaveButton
              width="160px"
              label="+ Room Type "
              handleChange={() => {
                handleClickCategory();
              }}
            />
            <CustomSaveButton
              width="160px"
              label="Save"
              handleChange={() => {
                handleClickSave();
              }}
            />
          </Stack>
        </Box>
      </form>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={catopen}
        sx={{
          "& .MuiPaper-root": {
            background: "white",
            borderRadius: "20px",
            paddinngLeft: "100px",
            width: "350px",
          },
          "& .MuiDialogContent-root": {
            border: "none",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setCatName(null);
              setCatOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Box>
            <CustomLabel
              color={"black"}
              label="Enter Room type"
              style={{ color: "black" }}
            />

            <CustomTextField
              autoFocus={true}
              name="category"
              value={catName}
              border="1px solid #707070"
              borderRadius="5px"
              height="0px"
              handleChange={(e) => {
                let val = e.target.value;
                // val = val.replace(/[^\w ]/g, "");
                // val = val.replace(/^\s+|\s+$/gm, "");
                // if (val?.length >= 15) {
                //   val = val.substr(0, 15);
                // }
                // const re = /^[A-Za-z\b]+$/;
                // if (val === "") {
                setCatName(val);
                // }
                // setCatName(val);
              }}
              handleKeyDown={(event) => {
                event.stopPropagation();

                if (event.key === "Enter") {
                  if (catName) {
                    handleClickAddCategory();

                    setTimeout(() => {
                      setCatOpen(false);
                      setCatName(null);
                    }, 50);
                  } else {
                    setTstContent("Please fill the detatils");
                    setTstOpn(true);
                  }
                }
              }}
            />
          </Box>
        </DialogContent>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={2}
          sx={{
            paddingBottom: "25px",
            marginTop: "10px",
          }}
        >
          <CustomSaveButton
            width="110px"
            label="Add"
            handleChange={() => {
              if (catName) {
                handleClickAddCategory();
                setCatName(null);
                setCatOpen(false);
              } else {
                setTstContent("Please fill the detatils");
                setTstOpn(true);
              }
            }}
          />

          <CustomCancelButton
            label="Cancel"
            width="110px"
            handleChange={() => {
              setCatName(null);
              setCatOpen(false);
            }}
          />
        </Stack>
      </Dialog>
      <DeleteCatDiologueBox
        label={delteDlgWrd}
        open={catDlgOpn}
        setCatDlgOpn={setCatDlgOpn}
        validateDeletingCats={validateDeletingCats}
      />
      <DeleteRoomDiologueBox
        label={delteDlgWrd}
        open={roomDlgOpn}
        validateDeletingRooms={validateDeletingRooms}
        setRoomDlgOpn={setRoomDlgOpn}
      />
      <CustomSnackBar
        open={tstOpn}
        setOpen={setTstOpn}
        tstContent={tstContent}
      />
      <ResultDiologueBox
        open={resDlgOpn}
        setOpen={setResDlgOpn}
        label={resultLabel}
        width="400px"
        height="150px"
      />
    </Box>
  );
}

export default RoomSettingsForm;
