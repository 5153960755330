import axios from "axios";

export const activateBookingData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/activateBooking`,

    body
  );
};
export const getGuestInfoData = async (body, type = null) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getGuestInfo?room_type=${type}`,

    body
  );
};

export const getBookingsDetailsData = async (bookingId, roomNo) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/bookings/getBookingsDetails?booking_id=${bookingId}&room_no=${roomNo}`,
    {}
  );
  // return await axios.get(
  //   `${process.env.REACT_APP_API_URL}/bookings/getBookingsDetails/${id}`,
  //   {}
  // );
};

export const getCustomerDetailsData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getCustomerDetails`,

    body
  );
};

export const addEditBookingData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/addEditBooking`,

    body
  );
};

export const deleteBookingData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/deleteBooking`,
    body
  );
};

export const getDepartureDetailsData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getDepartureDetails`,
    body
  );
};

export const addEditDepartureData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/addEditDeparture`,
    body
  );
};

export const RoomShiftData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/roomshift`,
    body
  );
};

export const getAllDocuments = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getAllDocuments`,

    body
  );
};

export const getYearsHistory = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/bookings/getYearsHistory`
  );
};

export const getMonthsHistory = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getMonthsHistory`,
    body
  );
};

export const getMonthRecords = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/getMonthRecords`,

    body
  );
};

export const InsertSerialId = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/setSerialId`,

    body
  );
};

export const updateIsFavorite = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/updateIsFavorite`,

    body
  );
};

export const customerBlocking = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/bookings/managecustomerBlocking`, body);
};

