import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CustomSaveButton from "../CustomSaveButton";
import CustomCancelButton from "../CustomCancelButton";

function CustomDiolog(props) {
  return (
    <Dialog
      open={props?.open}
      sx={{
        "& .MuiDialogContent-root": {
          border: "none",
        },
        "& .MuiDialog-paper": {
          minWidth: "350px",
          maxWidth: "600px",
          maxHeight: "400px",
        },
        "& .MuiPaper-root": {
          background: "#FFF",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent dividers>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "26px",
            paddingRight: "26px",
            paddingTop: "30px",
            paddingBottom: "30px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              color: "#000",
              margin: "8px",
              fontSize: "18px!important",
            }}
          >
            {props?.label}
          </Typography>
        </Box>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ paddingTop: "20px", paddingBottom: "30px" }}
      >
        <CustomCancelButton
          label={props?.cancelLabel}
          width="130px"
          handleChange={() => {
            props?.setOpen(false);
          }}
        />

        <CustomSaveButton
          width="130px"
          label={props?.confirmLabel}
          handleChange={props?.customFunction}
        />
      </Stack>
    </Dialog>
  );
}

export default CustomDiolog;
