import { Alert, Snackbar } from "@mui/material";
import React, { useEffect } from "react";

function CustomSnackBar(props) {
  const { open, tstContent, setOpen } = props;

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      key={"top" + "center"}
    >
      <Alert
        onClose={() => {
          setOpen(false);
        }}
        severity="warning"
        sx={{ width: "100%" }}
      >
        {tstContent}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackBar;
