import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useCallback, useRef, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";

import CustomLabel from "./CustomLabel";

import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../utils/colors";
import { borderRadius } from "@mui/system";
import CustomSaveButton from "./CustomSaveButton";
import CustomCancelButton from "./CustomCancelButton";

function ClearRoomDiologueBox(props) {
  return (
    <Dialog
      //   onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props?.open}
      sx={{
        "& .MuiPaper-root": {
          background: CLR_LIGHT_WHITE,
          borderRadius: "20px",
          width: "400px",
          height: "200px",
          // padding :  "0px 10px",
        },
        "& .MuiDialogContent-root": {
          // width: "48%",
          // height: "35%",
          // borderRadius : "30px",
          border: "none",
          // padding : "30px 50px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Tooltip title="Close">
          <img
            style={{
              width: "20px",
              height: "24px",
              cursor: "pointer",
              float: "right",
            }}
            src="/images/icon_close.png"
            onClick={(item) => {
              props?.setRoomDlgOpn(false);
            }}
          />
        </Tooltip>
      </DialogTitle>
      <DialogContent dividers>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{ color: "black", justifyContent: "center", fontSize: "18px" }}
          >
            {props?.label}
          </Typography>
        </Box>
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        style={{ paddingTop: "20px", paddingBottom: "30px" }}
      >
        <CustomCancelButton
          label="CANCEL"
          width="160px"
          handleChange={() => {
            props?.setOpen(false);
          }}
        />

        <CustomSaveButton
          width="160px"
          label="Clear"
          handleChange={() => {
            props?.clearForm();
            props?.setOpen(false);
          }}
        />
      </Stack>
    </Dialog>
  );
}

export default ClearRoomDiologueBox;
