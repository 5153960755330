import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useCallback, useRef, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";

import CustomLabel from "./CustomLabel";

import { CLR_LIGHT_WHITE, CLR_ORANGE } from "../utils/colors";
import { borderRadius } from "@mui/system";

function ResultDiologueBox(props) {
  return (
    <Dialog
      //   onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props?.open}
      sx={{
        "& .MuiPaper-root": {
          background: `${CLR_ORANGE}`,
          borderRadius: "20px",
          width: `${props?.width}`,
          height: `${props?.height}`,
        },
        "& .MuiDialogContent-root": {
          border: "none",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        },
      }}
    >
      <DialogContent dividers>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {props?.isIcon ? "" :
            <HomeIcon
              sx={{
                color: "white",
                justifyContent: "center",
                paddingRight: "20px",
              }}
            />
          }
          <Typography
            sx={{ color: "white", justifyContent: "center", fontSize: "20px" }}
          >
            {props?.label}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ResultDiologueBox;
