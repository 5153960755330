

export const GET_USERS='GET_USERS'



export const ADD_BOOKING='ADD_BOOKING'


// =================dashboard page======================
export const GET_DASHBOARD_DATA='GET_DASHBOARD_DATA'

// =================profile page======================
export const ADD_PROFILE_PHOTO='ADD_PROFILE_PIC'
export const GET_PROFILE_DATA='GET_PROFILE_DATA'



// ===============login page========================
export const LOGIN='LOGIN'
export const LOGIN_SUCCESS='LOGIN_SUCCESS'
export const LOGIN_FAIL='LOGIN_FAIL'
export const LOGOUT_SUCCESS='LOGOUT_SUCCESS'
export const LOGOUT_FAIL='LOGOUT_FAIL'


// ===============Booking page========================
export const ADD_EDIT_BOOKING='ADD_EDIT_BOOKING'


// ===============Print Data========================
export const SAVE_PRINT_DATA='SAVE_PRINT_DATA'
export const GET_PRINT_DATA='GET_PRINT_DATA'
