import * as types from "../type";
const initialState = {
  data: [],

  isSuccess: false,
};
const bookingReducer = (state = initialState, action) => {
  switch (action?.type) {
    case types.ADD_EDIT_BOOKING:
      return {
        ...state,
        data: action?.payload?.data,
      };

    default:
      return state;
  }
};
export default bookingReducer;
