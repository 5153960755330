import axios from "axios";
export const getCountryListData = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/utils/getCountryList`,
    {}
  );
};

export const getStateListData = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/utils/getStateList/${id}`,
    {}
  );
};
export const getCityListData = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/utils/getCityList/${id}`,
    {}
  );
};

export const addReportData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/utils/report`,
    body
  );
};
