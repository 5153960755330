import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CLR_ORANGE } from "../../utils/colors";
import styles from "./sidebar.module.css";

function SideBar() {
  const [pos, setPos] = useState(0);
  const handleClick = (index) => {
    setPos(index);
  };

  const dashBoardData = [{ label: "Dashboard", url: "/dashboard" }];
  return (
    <Box
      id={styles.mainbox}
      sx={{
        display: {
          xs: "none!important",
          // sm: "none!important",
          md: "block!important",
        },

        background: "rgba(0,0,0,0.42)",
      }}
      flex={1}
    >
      {/* <Toolbar /> */}

      <img
        id={styles.logo}
        // src="/images/img_sheraton_grande_new_logo.png"
      />

      <Divider />
      <List>
        {dashBoardData?.map((data, index) => (
          <ListItem
            key={index}
            component={NavLink}
            activeclassname={styles.active}
            exact="true"
            to={data?.url}
            disablePadding
            sx={{
              color: "white",
              textAlign: "center",
              ...(pos === index && {
                backgroundColor: CLR_ORANGE,
                borderRadius: "0px 30px 30px 0px",
              }),
            }}
            onClick={() => handleClick(index)}
          >
            <ListItemButton sx={{ justifyContent: "center" }}>
              <Box>
                <ListItemText
                  primary={data?.label}
                  sx={{
                    ".MuiListItemText-root ": {
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default SideBar;
