import { Button } from "@mui/material";
import React from "react";
import { CLR_DARK_GREY, CLR_LIGHT_WHITE } from "../utils/colors";

function CustomCancelButton(props) {
  return (
    <Button
      variant="contained"
      sx={{
        paddingLeft: "10px",
        paddingRight: "10px",
        background: CLR_DARK_GREY,
        borderRadius: "4px",
        width: props?.width,
        shadowColor: "1px 1px 6px #E98D12",
        "&:hover": {
          color: CLR_DARK_GREY,
          background: CLR_LIGHT_WHITE,
        },
      }}
      onClick={props?.handleChange}
    >
      {props?.label}
    </Button>
  );
}

export default CustomCancelButton;
