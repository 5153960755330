import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import staticTexts from "../utils/static-texts";

function PrivateRoute({ children }) {
  let token = false;
  let _acess_token = localStorage.getItem(staticTexts.ACCESS_TOKEN_TEXT);
  if (_acess_token) {
    token = true;
  } else {
    token = false;
  }
  if (!token) {
      return <Navigate to="/login"  />
  }
  return children;
}

export default PrivateRoute;
