import axios from "axios";
export const getUsersData = async (id) => {
  return await axios.get(`${process.env.REACT_APP_API_URL}/user/getUsers`, {});
};

export const registerUserDetailsData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user/registerUserDetails`,

    body
  );
};

export const getRolesData = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/user/getRolesData`,
    {}
  );
};

export const getHotelsData = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/user/getHotelsData`,
    {}
  );
};

export const updateUserDetailsData = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user/updateUserDetails`,

    body
  );
};
export const loginUser = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user/login`,

    body
  );
};

export const logoutUser = async () => {
  return await axios.post(`${process.env.REACT_APP_API_URL}/user/logout`);
};

export const updateProfileImg = async (body) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/user/updateprofile`,
    body
  );
};
