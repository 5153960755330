import "symbol-observable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "../src/redux/store";
import { Provider } from "react-redux";
import axios from "axios";

import * as axiosInterceptors from "../src/utils/interceptors";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <div>
      <App />
    </div>
  </Provider>
);

//to settting the pwa application
serviceWorkerRegistration.register();
