import * as types from "../type";
import staticTexts from "../../utils/static-texts";

const initialState = {
  data: [],

  isLoggedIn: false,
};
const user = JSON.parse(localStorage.getItem(staticTexts.USER_DATA));
const loginReducer = (state = initialState, action) => {
  switch (action?.type) {
    case types.LOGIN:
      return { ...state, data: action?.payload?.data };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        data: action?.payload?.data,
      };
    case types.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        data: null,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        data: null,
      };
    case types.LOGOUT_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        data: action?.payload?.data,
      };
    default:
      return state;
  }
};
export default loginReducer;
